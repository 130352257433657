// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useContext, useEffect, useRef, useState } from "react"
import { HomeFooter, HomeHeader, AppButton, MainFooter, ContactUs } from "../../components"
import { Element } from "react-scroll"
import {
  Grid,
} from "@mui/material"
import { ReactComponent as IPhone } from "../../assets/svg/iPhone.svg"
import MacBookPro from "../../assets/images/MacbookPro.png"
import { ReactComponent as AppStore } from "../../assets/svg/AppStore.svg"
import { ReactComponent as GooglePlay } from "../../assets/svg/GooglePlay.svg"
import { ReactComponent as Organization } from "../../assets/svg/Organization.svg"
import { ReactComponent as Upcoming } from "../../assets/svg/Upcoming.svg"
import { ReactComponent as Player } from "../../assets/svg/Player.svg"
import { ReactComponent as Parent } from "../../assets/svg/Parent.svg"
import { ReactComponent as Coach } from "../../assets/svg/Coach.svg"
import { useNavigate } from "react-router-dom"
import AppContext from "../../Context"
import { useSnackbar } from "notistack"
import { getFormattedDate } from "../../utils/dateUtils"
import { COLORS } from "../../constants"

export default function RVDetails({ }) {
  const { enqueueSnackbar } = useSnackbar()
  const guestRef = useRef(null)
  const navigate = useNavigate()
  const { featuredRVS, setFeaturedRVS, user } =
    useContext(AppContext)
  const [state, setState] = useState({
    active: 0,
    pickup: "",
    dropoff: "",
    openDropOff: false,
    start_location: "",
    location: "",
    sleeping_capacity: "",
    page: 0,
    limit: 50
  })

  useEffect(() => {
    if (user) {
      navigate('/dashboard')
    }
  }, [user])

  const handleUpdateFavorite = id => {
    let index = featuredRVS.data.findIndex(obj => obj.id === id)

    setFeaturedRVS(prevFeatures => ({
      ...prevFeatures,
      data: [
        ...prevFeatures.data.slice(0, index),
        {
          ...prevFeatures.data[index],
          is_favorite: !prevFeatures.data[index].is_favorite
        },
        ...prevFeatures.data.slice(index + 1)
      ]
    }))
  }

  const {
    active,
    pickup,
    sleeping_capacity,
    dropoff,
    openDropOff,
    start_location,
    location,
    page,
    limit
  } = state

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }


  const handleSearch = () => {
    const queryParams = {
      location: start_location,
      coordinates: encodeURIComponent(location),
      pickup: getFormattedDate(pickup),
      dropoff: getFormattedDate(dropoff),
      sleeping_capacity: sleeping_capacity,
      delivery: active && active === 3
    }

  }

  return (
    <div className="bg-white">
      <HomeHeader />
      <Element name="Element1" className="element">
        <section className="container">
          <div className="PlayerIcon">
            <Player />
          </div>
          <div className="ParentIcon">
            <Parent />
          </div>

          <div className="container d-flex  justify-content-center align-items-center">
            <Grid container justifyContent={"center"}>
              <Grid
                container
                justifyContent={"center"}
                style={{ marginTop: 50 }}
              >
                <div className="text-center width70">
                  <p
                    className={"JOINTHE"}
                  >
                    <span className="greyColor">JOIN THE</span> The Sports Portal
                  </p>
                </div>
              </Grid>
              <Grid container justifyContent={"center"} className={"mb-4"}>
                <div className="greyColor font-16">
                  Unleash Your Potential, Compete, and Connect
                </div>
              </Grid>
              <AppButton
                fontWeight={"normal"}
                title={'Create My Account'}
                onClick={() => navigate("/signup")}
                backgroundColor={COLORS.primary} color={COLORS.white}
                width={180} className={'pl-2 pr-2 mt-3'} />

            </Grid>
          </div>
          <div className="PlayerIcon">
            <Coach />
          </div>
          <Grid container alignItems={'center'} className="UpcomingSVG">
            <Grid item xs={8}>
              <Upcoming className="upcomingImg" />
            </Grid>
            <Grid item xs={4}>
              <Organization className="upcomingImg" />
            </Grid>
          </Grid>
        </section>
      </Element>
      <section className="bg-light-green mb-5">
        <Grid container alignItems={'center'} className="container">
          <Grid item xs={12} md={4}>
            <div className='save-time'>Communicate From One Place</div>
            <div className='light-grey-text mt-3'>Keep all your admins, coaches, parents, and athletes connected within the The Sports Portal app</div>
            <Grid container className="mt-4">
              <AppStore className="mr-2 cursor" />
              <GooglePlay className="cursor" />
            </Grid>
          </Grid>
          <Grid item xs={12} md={8}>
            <IPhone />
          </Grid>
        </Grid>
      </section>
      <section className="bg-white mb-5">
        <Grid container alignItems={'center'} className="container">
          <Grid item xs={12} md={8}>
            <img src={MacBookPro} />
          </Grid>
          <Grid item xs={12} md={4}>
            <div className='save-time'>Save Time Setting up your schedule</div>
            <div className='light-grey-text'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>
          </Grid>
        </Grid>
      </section>

      <ContactUs />
      <HomeFooter />
      <MainFooter />
    </div>
  )
}
