// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useState } from 'react'
import { AppButton, AppInput, HomeHeader } from '../../components'
import { Box, Grid, Modal, Typography, } from '@mui/material'
import { ReactComponent as EmailIcon } from "../../assets/svg/email.svg"
import { ReactComponent as CheckCircle } from "../../assets/svg/CheckCircle.svg"
import { useNavigate } from 'react-router-dom'
import { forgotpassword } from '../../api/auth'
import { COLORS } from '../../constants'
import { useSnackbar } from 'notistack'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  borderRadius: 6,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function ForgotPassword({ }) {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [state, setState] = useState({
    email: '',
    loadingSignup: false,
    visible: false,
  })

  const {
    email,
    loadingSignup,
    visible
  } = state

  const handleChange = (key, value) => {
    setState(pre => ({
      ...pre,
      [key]: value
    }))
  }

  const handleClose = () => {
    handleChange("visible", false)
  }
  const handleClose1 = () => {
    handleChange("visible", false)
    navigate(-1)
  }
 
  const openEmail = () => {
    window.open('mailto:')    
  }

  const handleSignup = async () => {
    try {
      handleChange('loadingSignup', true)
      const payload = {
        email
      }
      const res = await forgotpassword(payload)
      handleChange('loadingSignup', false)
      handleChange('visible', true)
    } catch (error) {
      handleChange('loadingSignup', false)
      const errorText = Object.values(error?.response?.data)
      if (error?.response?.data?.status === "fail") {
        enqueueSnackbar(`Email is invalid`, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          }
        })
      } else {
        alert(`Error: ${error}`)
      }
    }
  }

  return (
    <div>
      <HomeHeader />
      <section>
        <div className='container divCenter loginContainer'>
          <Grid container justifyContent={'center'}>
            <Grid item xs={10} sm={10} md={4} className='p-4'>
              <p className='text_black font-500 font-18 mb-2'>
                Reset Password
              </p>
              <p className='lightgreyColor mb-3'>
                Enter your email address and we will send you the link to reset your password
              </p>
              <AppInput
                label={'Email'}
                prefix={<div className="emailIcon">
                  <EmailIcon /></div>}
                value={email}
                name={'email'}
                onChange={handleChange}
                className='mb-3 mt-3'
                placeholder={'Enter email'}
              />
              <AppButton
                title={'Reset'}
                onClick={handleSignup}
                loading={loadingSignup}
                disabled={!email}
                backgroundColor={COLORS.primary}
                color={'#fff'}
              />
            </Grid>
          </Grid>
        </div>
        <Modal
          open={visible}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid container spacing={3}>
              <Grid item xs={2}>
                <CheckCircle className='mt-2' />
              </Grid>
              <Grid item xs={10}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Reset link sent
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Open your email and click the link to reset your password for this account.
                </Typography>
                <Grid container justifyContent={'flex-end'} className='mt-3'>
                  <AppButton title={'Close'} height={40} onClick={handleClose1} width={100} backgroundColor={"#595959"} className={'mr-2'} color={COLORS.white} />
                  {/* <AppButton title={'Open Email'} height={40} onClick={openEmail} width={100} backgroundColor={COLORS.primary} color={COLORS.white} /> */}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </section>
    </div>
  )
}
