// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useContext, useEffect, useRef, useState } from "react"
import { HomeHeader, AppButton, AppInput, Label, } from "../../components"
import {
    Grid,
} from "@mui/material"
import { ReactComponent as SearchGrey } from "../../assets/svg/searchGrey.svg"
import { useNavigate } from "react-router-dom"
import AppContext from "../../Context"
import { useSnackbar } from "notistack"
import AvatarIcon from "../../assets/images/avatar.png"
import { ReactComponent as MessageFill } from "../../assets/svg/MessageFill.svg"
import { MAP_API_KEY } from "../../constants"
import Autocomplete from "react-google-autocomplete"
import { getPlayerToFav } from "../../api/auth"
import { getPayload } from "../../utils/formattedPayload"

export default function Bookmark({ }) {
    const { enqueueSnackbar } = useSnackbar()
    const guestRef = useRef(null)
    const navigate = useNavigate()
    const { grades } =
        useContext(AppContext)
    const [state, setState] = useState({
        selectedClass: "",
        selectedLocation: "",
        searchText: "",
        userList: []
    })


    const {
        selectedLocation,
        selectedClass,
        userList,
        searchText
    } = state

    useEffect(() => {
        _getPlayerToFav()
    }, [])

    const _getPlayerToFav = async (searchText, selectedLocation, selectedClass) => {
        try {
            handleChange('searchText', searchText)
            const token = localStorage.getItem("token")
            const queryParams = {
                search: searchText,
                city: selectedLocation,
                player__grade: selectedClass
            }
            const payload = getPayload(queryParams)
            const res = await getPlayerToFav(payload, token)
            handleChange("userList", res?.data)
        } catch (error) {
            const errorText = Object.values(error?.response?.data)
            alert(`Error: ${errorText[0]}`)
        }
    }

    const handleChange = (key, value) => {
        setState(pre => ({ ...pre, [key]: value }))
    }
    const handleSearch = async (place) => {
        try {
            const res = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${place?.geometry.location?.lat()},${place?.geometry.location?.lng()}&key=${MAP_API_KEY}`
            )
            const res1 = await res.json()
            const address =
                res1?.results?.length > 0 &&
                res1?.results[0]?.formatted_address
            var address_components =
                res1?.results[0]?.address_components
            let dState = ''
            let country = ''
            let city = ''
            let postal_code = ''
            if (address_components !== undefined) {
                const addrComp = address_components
                for (let i = 0; i < addrComp.length; ++i) {
                    var typ = addrComp[i]?.types[0]
                    if (typ === 'administrative_area_level_1') {
                        dState = addrComp[i]?.long_name
                    } else if (typ === 'locality') {
                        city = addrComp[i]?.long_name
                    } else if (typ === 'country') {
                        country = addrComp[i]?.long_name
                    } else if (typ === 'postal_code') {
                        postal_code = addrComp[i]?.short_name
                    }
                }
            }
            handleChange('selectedLocation', city)
            _getPlayerToFav(searchText, city, selectedClass)
        } catch (error) {
            alert(error?.message)
        }
    }

    const handleUserClick = (user) => {
        const role = user?.role
        const route = role === 'Player' ? `/player-profile/${user?.id}` : role === 'Organization' ? `/organization-profile/${user?.id}` : `/parent-profile/${user?.id}`
        navigate(route)
    }

    const list1 = grades?.map((item, index) => (
        <option key={index} value={item?.id}>{item?.name}</option>
    ))
    const option2 = <option value={''}>Select Grades</option>
    const gradesPlayOptions = [option2, ...list1]

    return (
        <div className="">
            <HomeHeader dashboard />
            <section className="container pb-3 mb-0 bg-transparent divCenter">
                <Grid container spacing={3} className="width80">
                    <Grid item xs={12} md={7}>
                        <div className="mb-3 font-lexend font-36 font-bold">
                            Favorite Players
                        </div>
                        <div className="searchInputBookmarkBox mb-4">
                            <input className="searchInput" placeholder="Search..." onChange={(e) => {
                                handleChange('searchText', e.target.value)
                                _getPlayerToFav(e.target.value, selectedLocation, selectedClass)
                            }} value={searchText} />
                            <div className="saerchIconHeaderDiv">
                                <SearchGrey />
                            </div>
                        </div>
                        {
                            userList?.map((user, index) => (
                                <Grid key={index} className="mb-5 cursor" onClick={() => handleUserClick(user)} container alignItems={'center'} justifyContent={"space-between"}>
                                    <Grid container alignItems={"center"} item xs={8} >
                                        <img src={user?.role === 'Player' ? user?.player?.picture : user?.role === 'Parent' ? user?.parent?.picture : AvatarIcon} className="bookmarkProfile" />
                                        <div>{user?.name || user?.first_name + " " + user?.last_name || "No Name"}</div>
                                    </Grid>
                                    <AppButton backgroundColor={'#E6F6FF'} width={120} height={32} title={<div className="d-flex align-items-center" style={{ marginLeft: -5 }}><MessageFill width={20} /> <span className="ml-1">Message</span></div>} fontWeight={'500'} />
                                </Grid>
                            ))
                        }
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <div className="mb-2">
                            <p
                                className={"font-20"}
                            >
                                Filters
                            </p>
                        </div>
                        <>
                            <Label text={"Location"} fontNormal />
                            <Autocomplete
                                apiKey={MAP_API_KEY}
                                className="locationSearch"
                                title="asd"
                                options={{ types: ['locality'] }}
                                style={{ height: 40, backgroundColor: "#fff" }}
                                inputAutocompleteValue={selectedLocation}
                                onChange={(e) => handleChange('selectedLocation', e?.target?.value)}
                                onPlaceSelected={place => {
                                    handleSearch(place)
                                }}
                                placeholder=""
                            />
                        </>
                        <AppInput label={'Class'} select selectOptions={gradesPlayOptions} height={40} className={'mt-3'} onChange={(key, value) => {
                            handleChange(key, value)
                            _getPlayerToFav(searchText, selectedLocation, value)
                        }} value={selectedClass} name={'selectedClass'} />
                    </Grid>
                </Grid>
            </section>
        </div>
    )
}
