import React, { useContext, useEffect, useState } from "react"
import { AppButton } from ".."
import { Box, Grid, Modal } from "@mui/material"
import { COLORS } from "../../constants"
import { ReactComponent as Cross } from "../../assets/svg/Cross.svg"
import { sendFeedback } from "../../api/auth"
import { useSnackbar } from "notistack"
import { getError } from "../../utils/getError"
import AppContext from "../../Context"
import { useNavigate, useParams } from "react-router-dom"
import AvatarIcon from "../../assets/images/avatar.png"
import { ReactComponent as SearchGrey } from "../../assets/svg/searchGrey.svg"

function FollowUser({
  visible,
  handleClose,
  followVisibleType,
  _removeFollower,
  loadingRemove,
  currentUserFollowing,
  userProfileFollowing,
  userProfileFollower,
  _addFollow,
  loading
}) {
  const { enqueueSnackbar } = useSnackbar()
  const isFollower = followVisibleType === "follower" ? true : false
  const list = isFollower ? userProfileFollower : userProfileFollowing

  const { id } = useParams()
  const navigate = useNavigate()
  const { user } = useContext(AppContext)
  const token = localStorage.getItem("token")
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    borderRadius: 6,
    height: "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4
  }
  const [state, setState] = useState({
    message: "",
    email: "",
    loading: false,
    selectedItem: null,
    otherItem: null,
    filteredList: list || []
  })
  const {
    email,
    message,
    // loading,
    selectedItem,
    filteredList
  } = state

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  useEffect(() => {
    if (list) {
      handleChange("filteredList", list)
    }
  }, [list])

  const handleSubmit = async () => {
    try {
      handleChange("loading", true)
      const payload = {
        email,
        message
      }
      await sendFeedback(payload, token)
      handleChange("loading", false)
      handleChange("message", "")
      handleClose(true)
      enqueueSnackbar(`Feedback has been submitted`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const handleUserClick = user => {
    handleClose()
    const role = user?.role
    const route =
      role === "Player"
        ? `/player-profile/${user?.id}`
        : role === "Organization"
          ? `/organization-profile/${user?.id}`
          : `/parent-profile/${user?.id}`
    navigate(route)
  }

  const checkUserIsFollow = user => {
    return (
      Array.isArray(currentUserFollowing) &&
      currentUserFollowing?.some(e => e?.id === user?.id)
    )
  }

  const filtered = value => {
    if (value) {
      const filtered = list?.filter(e =>
        e?.name?.toLowerCase()?.includes(value?.toLowerCase())
      )
      handleChange("filteredList", filtered)
    } else {
      handleChange("filteredList", list)
    }
  }

  const currentUser = id == user?.id
  const currentUserRole = user
  const currentUserID = user?.id
  return (
    <Modal
      open={visible}
      onClose={() => handleClose(false)}
      sx={{ zIndex: 9999999 }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          className="mb-3"
        >
          <div className="font-20 font-bold greyColor">
            {isFollower ? "Followers" : "Following"}
          </div>
          <AppButton
            width={35}
            height={35}
            borderRadius={35}
            className={"min-width-remove"}
            backgroundColor={COLORS.white}
            borderColor={"#D9D9D9"}
            title={<Cross />}
            onClick={() => handleClose(false)}
          />
        </Grid>
        <div className="searchInputHeaderBox mb-3">
          <input
            className="searchInput"
            placeholder="Search..."
            onChange={e => filtered(e.target.value)}
          />
          <div className="saerchIconHeaderDiv">
            <SearchGrey />
          </div>
        </div>
        <Grid className="followerListDiv">
          {Array.isArray(filteredList) &&
            filteredList?.map((user, index) => (
              <Grid
                key={index}
                className="followerListGrid"
                container
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Grid
                  container
                  alignItems={"center"}
                  item
                  xs={8}
                  onClick={() => handleUserClick(user)}
                >
                  <img
                    src={
                      user?.role === "Coach"
                        ? user?.coach?.picture
                        : user?.role === "Organization"
                          ? user?.organization?.logo
                          : user?.role === "Player"
                            ? user?.player?.picture
                            : user?.role === "Parent"
                              ? user?.parent?.picture
                              : AvatarIcon
                    }
                    className="followerProfile"
                  />
                  <div>
                    {user?.name ||
                      user?.first_name + " " + user?.last_name ||
                      "No Name"}
                  </div>
                </Grid>
                {currentUserID !== user?.id &&
                currentUserRole?.role !== "Parent" ? (
                  <AppButton
                    disabled={
                      loading === user?.id || loadingRemove === user?.id
                    }
                    color={
                      !currentUser
                        ? !checkUserIsFollow(user)
                          ? COLORS.white
                          : "#000"
                        : "#000"
                    }
                    backgroundColor={
                      !currentUser
                        ? !checkUserIsFollow(user)
                          ? COLORS.primary
                          : "#fff"
                        : "#fff"
                    }
                    borderColor={"#D9D9D9"}
                    width={"auto"}
                    height={32}
                    title={
                      !currentUser
                        ? checkUserIsFollow(user)
                          ? "Unfollow"
                          : "Follow"
                        : isFollower
                          ? "Remove"
                          : "Unfollow"
                    }
                    onClick={() =>
                      currentUser && isFollower
                        ? _removeFollower(user?.id)
                        : isFollower
                          ? _addFollow(
                              user?.id,
                              checkUserIsFollow(user) ? "unfollow" : "follow"
                            )
                          : _addFollow(
                              user?.id,
                              checkUserIsFollow(user) ? "unfollow" : "follow"
                            )
                    }
                    fontWeight={"400"}
                  />
                ) : (
                  <AppButton
                    disabled={
                      loading === user?.id || loadingRemove === user?.id
                    }
                    color={"#000"}
                    backgroundColor={"#fff"}
                    borderColor={"#D9D9D9"}
                    width={"auto"}
                    height={32}
                    title={isFollower ? "Remove" : "Unfollow"}
                    onClick={() =>
                      isFollower
                        ? _removeFollower(user?.id)
                        : _addFollow(user?.id, "unfollow")
                    }
                    fontWeight={"400"}
                  />
                )}
              </Grid>
            ))}
        </Grid>
      </Box>
    </Modal>
  )
}

export default FollowUser
