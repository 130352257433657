import React from 'react'
import { Divider, Grid } from '@mui/material'
import { Link } from 'react-router-dom'

export default function MainFooter () {
  let date = new Date().getFullYear()
  return (
    <div>
      {/* <!--Main footer--> */}
      <footer className='common_footer'>
        <div className='container'>
          <div className='pb-3 pt-3 text-white'>
          Copyrights © {date} All Rights Reserved by The Sports Portal
          </div>
        </div>
      </footer>
    </div>
  )
}
