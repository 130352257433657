import React, { useContext, useEffect, useRef, useState, useCallback } from "react"
import AppInput from "../AppInput"
import { ReactComponent as SendIcon } from "../../assets/svg/Send.svg"
import AvatarIcon from "../../assets/images/avatar.png"
import Postimage from "../../assets/images/dashboard.jpg"
import { ReactComponent as LocationOnGrey } from "../../assets/svg/location_on_grey.svg"
import { ReactComponent as LikeIcon } from "../../assets/svg/Like.svg"
import { ReactComponent as MessageIcon } from "../../assets/svg/Message.svg"
import { ReactComponent as ShareIcon } from "../../assets/svg/share_icon.svg"
import Microlink from "@microlink/react"
import { Waypoint } from "react-waypoint"
import { getError } from "../../utils/getError"
import {
  DeleteUserPosts,
  addComments,
  getComments,
  getLike,
  getUsers,
  postLike
} from "../../api/auth"
import { CircularProgress } from "@mui/material"
import { COLORS } from "../../constants"
import { Link, useNavigate } from "react-router-dom"
import AppContext from "../../Context"
import MentionTextBox from "../Mention/Mention"
import Button from "@mui/material/Button"
import UserLikeModal from "../GeneralModal/UserLikeModal"
import UserShareModal from "../GeneralModal/UserShareModal"
import { getAllUsers } from "../../api/admin"
import { IconButton } from "@mui/material"
import { GridMoreVertIcon } from "@mui/x-data-grid"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { useSnackbar } from "notistack"
import DeletePostModal from "../GeneralModal/DeletePost"
import Grow from "@mui/material/Grow"
import Paper from "@mui/material/Paper"
import Popper from "@mui/material/Popper"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import MenuList from "@mui/material/MenuList"
import MentionText from "./MentionText"
import { InView } from 'react-intersection-observer'
import ReactPlayer from "react-player"

const VIDEO_PROVIDERS = {
  CLOUD_FLARE: 'cloudflare',
  LOCAL: 'local',
};

export default function Post({
  index,
  getData,
  post,
  state,
  handleChange,
  allPlayers,
  videoPlaying,
  allUsers,
  getUserLoader,
  allChannels,
  mentionUsers,
  textTOHTML,
  onVisible,
  videoCache
}) {
  const videoRef = useRef()
  const { user } = useContext(AppContext)
  const _handleLeave = () => {
    if (videoRef.current) {
      handleChange("videoPlaying", null);
    }
  }
  const [link, setLink] = useState("")
  const [postId, setPostId] = useState("")
  const [url, setURL] = useState("")
  const navigate = useNavigate()
  const [visible, setVisible] = useState(false)
  const [isShareModalVisible, setIsShareModalVisible] = useState(false)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const [isLoading, setIsLoading] = useState(true);
  const [deviceType, setDeviceType] = useState('desktop');
  const [manuallyPaused, setManuallyPaused] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const videoContainerRef = useRef(null);

  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const isTablet = /iPad|Android/i.test(navigator.userAgent) && !/Mobile/i.test(navigator.userAgent);
    setDeviceType(isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop');
  }, []);

  const getBufferConfig = useCallback(() => {
    switch(deviceType) {
      case 'mobile':
        return {
          maxBufferLength: 15,
          maxMaxBufferLength: 30,
        };
      case 'tablet':
        return {
          maxBufferLength: 20,
          maxMaxBufferLength: 40,
        };
      default: // desktop
        return {
          maxBufferLength: 30,
          maxMaxBufferLength: 60,
        };
    }
  }, [deviceType]);

  const handleOpneDeleteModal = postId => {
    setIsDeleteModalVisible(true)
    setPostId(postId)
    setAnchorEl(null)
  }
  const handleCloseDeleteModal = () => {
    setIsDeleteModalVisible(false)
  }
  const handleOpenShareModal = () => {
    setIsShareModalVisible(true)
  }
  const handleCloseShareModal = () => {
    setIsShareModalVisible(false)
  }
  const handleOpenModal = () => {
    setVisible(true)
    _getUserLikes(post?.id)
  }
  const handleClose = () => {
    setVisible(false)
  }
  const [data, setData] = useState("")
  const [taggedUser, setTaggedUser] = useState()
  const [localstate, setLocalState] = useState({
    likes: [],
    loader: false,
    comments: [],
    users: [
      {
        id: "",
        display: ""
      }
    ],
    page: 0,
    limit: 20,
    showComment: false,
    loadingComment: false
  })

  const {
    showComment,
    comments,
    users,
    likes,
    page,
    limit,
    loadingComment,
    loader
  } = localstate
  const [commentLoading, setCommentLoading] = useState(false)
  const handleLocalChange = (key, value) => {
    setLocalState(pre => ({ ...pre, [key]: value }))
  }

  const handleUserClick = user => {
    const role = user?.role
    const route =
      role === "Player"
        ? `/player-profile/${user?.id}`
        : role === "Organization"
          ? `/organization-profile/${user?.id}`
          : role === "Coach"
            ? `/coach-profile/${user?.id}`
            : `/parent-profile/${user?.id}`
    navigate(route)
  }
  const _postLike = async post => {
    try {
      handleChange("loader", true)
      const payload = { post }
      const token = localStorage.getItem("token")
      await postLike(payload, token)
      getData(true)
      handleChange("loader", false)
    } catch (error) {
      handleChange("loader", false)
      getError(error)
    }
  }
  const _addComments = async (post, index) => {
    try {
      setCommentLoading(true)
      const mentionsInText = data?.match(/\(([^)]+)\)/g) || []
      const regex = /\[@(.+?)\s\(.+?\)\]/g || []
      let tagged_users = mentionsInText?.map(mention =>
        parseInt(mention?.slice(1, -1), 10)
      )
      let comment = data && data?.replace(/@\[.*?\]\(\d+\)/g, match => {
        // Extract the username from the match
        const usernameMatch = match?.match(/@\[(.*?)\]/)
        if (usernameMatch && usernameMatch[1]) {
          // Remove spaces between the username
          const cleanedUsername = usernameMatch?.[1]?.replace(/\s+/g, "")
          return `@${cleanedUsername}`
        }
        return match // Return the original match if no username is found
      })
      const payload = { post, tagged_users, user: user, comment }
      const token = localStorage.getItem("token")
      await addComments(payload, token)
      setData("")
      getData(true)
      handleChange(`comment${index}`, "")
      setCommentLoading(false)
    } catch (error) {
      setCommentLoading(false)
      getError(error)
    }
  }
  const _getComments = async post => {
    try {
      handleLocalChange("loadingComment", true)
      const offset = limit * page
      const payload = `?post__id=${post}&limit=${limit}&offset=${offset}`
      const nextPage = page + 1
      const token = localStorage.getItem("token")
      handleChange("offset", offset)
      handleChange("page", nextPage)
      const res = await getComments(payload, token)
      handleLocalChange(`comments`, [...comments, ...res?.data?.results])
      handleLocalChange("loadingComment", false)
    } catch (error) {
      handleLocalChange("loadingComment", false)
      getError(error)
    }
  }

  const _getUserLikes = async id => {
    try {
      const token = localStorage.getItem("token")
      handleLocalChange("loader", true)
      const res = await getLike(id, token)
      handleLocalChange(`likes`, res?.data?.results)
      handleLocalChange("loader", false)
    } catch (error) {
      handleLocalChange("loader", false)
      getError(error)
    }
  }
  const commentList = comments?.length > 0 ? comments : post?.recent_comments
  const urlRegex = /(https?:\/\/[^\s]+)/g

  const text = post?.text

  const [anchorEl, setAnchorEl] = React.useState(null)
  const MenuOpen = Boolean(anchorEl)
  const { enqueueSnackbar } = useSnackbar()

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleListClose = event => {
    if (anchorRef.current && anchorRef.current?.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event?.preventDefault()
      setOpen(false)
    } else if (event.key === "Escape") {
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus()
    }
    prevOpen.current = open
  }, [open])

  const arrivalTime = textTOHTML?.["Arrival Time"] || "Not specified"
  const date = textTOHTML?.["Date"] || "Not specified"
  const duration = textTOHTML?.["Duration"] || "Not specified"
  const fee = textTOHTML?.["Fee"] || "Not specified"
  const paymentlink = textTOHTML?.["Payment Link"] || "Not specified"
  const location = textTOHTML?.["Location"] || "Not specified"
  const formattedPaymentLink =
    paymentlink &&
      !paymentlink.startsWith("http://") &&
      !paymentlink.startsWith("https://")
      ? `http://${paymentlink}`
      : paymentlink

  const width = window.innerWidth

  const aspectRatios = [
    { ratio: 4 / 5, height: ((width - 40) * 5) / 4 },
    { ratio: 1 / 1, height: width - 40 },
    { ratio: 1.91 / 1, height: (width - 40) / 1.91 }
  ]

  // Determine the maximum height based on aspect ratios
  const maxHeight = Math.max(...aspectRatios.map(ar => ar.height))
  // Apply a scaling factor to make the image smaller
  const scalingFactor = 0.8 // 80% of the original size
  // Image styles
  const imageStyles = {
    // maxWidth: `${(width - 40) * scalingFactor}px`,
    // maxHeight: `${maxHeight * scalingFactor}px`,
    objectFit: "cover",
    width: post?.aspect_ratio === "2" ? 300 : 336,
    height: post?.aspect_ratio === "2" ? 500 : post?.aspect_ratio === "1" ? 250 : 336,
    marginBottom: 10
  }
  const imageClass = aspectRatios.reduce((acc, ar) => {
    if (post?.images?.includes(ar.ratio)) {
      return ar.className
    }
    return acc
  }, "")

  const handleVideoVisible = useCallback((inView) => {
    if (inView) {
      onVisible?.();
      if (videoPlaying && videoPlaying !== post?.id) {
        handleChange("videoPlaying", null);
      }
      handleChange("videoPlaying", post?.id);
    } else {
      if (videoPlaying === post?.id) {
        handleChange("videoPlaying", null);
      }
    }
  }, [post?.id, videoPlaying, handleChange, onVisible]);

  const postStyles = {
    postContainer: {
      backgroundColor: '#fff',
      borderRadius: '8px',
      border: '1px solid #dbdbdb',
      marginBottom: '24px',
      overflow: 'hidden'
    },
    postHeader: {
      padding: '14px 16px',
      display: 'flex',
      alignItems: 'center',
      borderBottom: '1px solid #efefef'
    },
    userAvatar: {
      width: '32px',
      height: '32px',
      borderRadius: '50%',
      marginRight: '12px',
      objectFit: 'cover'
    },
    userName: {
      fontWeight: '600',
      fontSize: '14px',
      color: '#262626',
      textDecoration: 'none',
      marginBottom: '2px'
    },
    location: {
      fontSize: '12px',
      color: '#8e8e8e',
      display: 'flex',
      alignItems: 'center',
      gap: '4px'
    },
    mediaContainer: {
      position: 'relative',
      width: '100%',
      height: '420px',
      backgroundColor: '#000',
      marginVertical: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden'
    },
    localMediaContainer: {
      position: 'relative',
      width: '100%',
      height: '420px',
      backgroundColor: '#000',
      marginVertical: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden'
    },
    localVideo: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      willChange: "transform, opacity"
    },
    postImage: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'contain'
    },
    postVideo: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'contain'
    },
    actionsContainer: {
      padding: '8px 16px',
      borderTop: '1px solid #efefef'
    },
    actionButtons: {
      display: 'flex',
      alignItems: 'center',
      gap: '16px',
      marginBottom: '8px'
    },
    likesCount: {
      fontSize: '14px',
      fontWeight: '600',
      color: '#262626',
      marginBottom: '8px'
    },
    postDescription: {
      padding: '0 16px 16px 16px',
      fontSize: '14px',
      lineHeight: '1.5',
      color: '#262626'
    },
    commentsContainer: {
      padding: '0 16px 16px 16px',
      borderTop: '1px solid #efefef'
    },
    commentInput: {
      display: 'flex',
      alignItems: 'center',
      padding: '16px',
      borderTop: '1px solid #efefef'
    },
    videoLoading: {
      position: 'relative',
      width: '100%',
      backgroundColor: '#000',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    thumbnailContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: '#000',
      zIndex: 1
    },
    thumbnail: {
      width: '100%',
      height: '100%',
      objectFit: 'contain'
    },
    loadingOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(0,0,0,0.5)'
    }
  }

  const videoRefs = useRef({});

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.75 
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        const isIntersecting = entry.isIntersecting && entry.intersectionRatio >= 0.75;
        setIsVisible(isIntersecting);
        
        if (isIntersecting && !manuallyPaused) {
          if (videoPlaying && videoPlaying !== post?.id && videoRefs.current[videoPlaying]) {
            videoRefs.current[videoPlaying].pause();
            handleChange("videoPlaying", null);
          }

          handleChange("videoPlaying", post?.id);
          if (videoRefs.current[post?.id]) {
            videoRefs.current[post?.id].play().catch(() => {});
          }
        } else if (!isIntersecting && videoPlaying === post?.id) {
          if (videoRefs.current[post?.id]) {
            videoRefs.current[post?.id].pause();
            handleChange("videoPlaying", null);
          }
        }
      });
    }, options);

    if (videoContainerRef.current) {
      observer.observe(videoContainerRef.current);
    }

    return () => {
      if (videoContainerRef.current) {
        observer.unobserve(videoContainerRef.current);
      }
    };
  }, [post?.id, videoPlaying, manuallyPaused, handleChange]);

  const renderVideo = () => {
    if (!post?.video_details?.url && !post?.video_file) return null;

    return (
      <div ref={videoContainerRef}>
        <div
          style={
            post.video_details?.provider === VIDEO_PROVIDERS.CLOUD_FLARE
              ? postStyles.mediaContainer
              : postStyles.localMediaContainer
          }
        >
          {post.video_details?.provider === VIDEO_PROVIDERS.CLOUD_FLARE ? (
            <ReactPlayer
              key={`player-${post?.id}`}
              ref={(el) => (videoRefs.current[post?.id] = el)}
              url={post.video_details.url}
              width="100%"
              height="100%"
              style={{ width: '100%' }}
              controls
              playing={videoPlaying === post?.id}
              onPlay={() => {
                handleChange("videoPlaying", post?.id);
                setManuallyPaused(false);
              }}
              onPause={(e) => {
                handleChange("videoPlaying", null);
                if (document.activeElement === e.target) {
                  setManuallyPaused(true);
                }
              }}
              onEnded={() => {
                handleChange("videoPlaying", null);
                setManuallyPaused(false);
              }}
              muted
              playsinline
            />
          ) : (
            <video
              ref={(el) => (videoRefs.current[post?.id] = el)}
              style={postStyles.localVideo}
              controls
              playsInline
              muted
              preload="metadata"
              poster={post?.thumbnail || "default-thumbnail.jpg"}
              onLoadedMetadata={() => setIsLoading(false)}
              onEnded={() => {
                handleChange("videoPlaying", null);
                setManuallyPaused(false);
              }}
              onPlay={() => {
                handleChange("videoPlaying", post?.id);
                setManuallyPaused(false);
              }}
              onPause={(e) => {
                handleChange("videoPlaying", null);
                if (document.activeElement === e.target) {
                  setManuallyPaused(true);
                }
              }}
            >
              <source
                src={post?.video_file || post?.video_details?.url}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <Waypoint onLeave={_handleLeave}>
        <div style={postStyles.postContainer}>
          <div style={postStyles.postHeader}>
            <img
              src={post?.user?.profile_picture || post?.user?.profile?.picture || AvatarIcon}
              alt={post?.user?.name}
              style={postStyles.userAvatar}
              onClick={() => handleUserClick(post?.user)}
              className="c-pointer"
            />
            <div>
              <div 
                style={postStyles.userName}
                onClick={() => handleUserClick(post?.user)}
                className="c-pointer"
              >
                {post?.user?.name || post?.user?.first_name || post?.user?.last_name || post?.user?.username || "No Name"}
              </div>
              {post?.location && (
                <div style={postStyles.location}>
                  <LocationOnGrey style={{width: 12, height: 12}} />
                  <span>{post?.location}</span>
                </div>
              )}
            </div>
            {user?.id === post?.user?.id && (
              <>
                <IconButton
                  onClick={handleToggle}
                  ref={anchorRef}
                  style={{marginLeft: 'auto'}}
                >
                  <GridMoreVertIcon />
                </IconButton>

                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-end"
                  transition
                  disablePortal
                  style={{
                    zIndex: 1000,
                  }}
                  modifiers={[
                    {
                      name: 'offset',
                      options: {
                        offset: [0, 8],
                      },
                    },
                    {
                      name: 'preventOverflow',
                      options: {
                        boundary: 'viewport',
                        altAxis: true,
                      },
                    },
                  ]}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: 'top right',
                      }}
                    >
                      <Paper elevation={3}>
                        <ClickAwayListener onClickAway={handleListClose}>
                          <MenuList
                            autoFocusItem={open}
                            id="composition-menu"
                            aria-labelledby="composition-button"
                            onKeyDown={handleListKeyDown}
                            style={{
                              minWidth: '150px',
                              padding: '8px 0',
                            }}
                          >
                            <MenuItem 
                              onClick={() => handleOpneDeleteModal(post?.id)}
                              style={{
                                fontSize: '14px',
                                padding: '8px 16px',
                              }}
                            >
                              Delete Post
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </>
            )}
          </div>

          {post?.images && (
            <div style={postStyles.mediaContainer}>
              <img
                src={post?.images || Postimage}
                alt={post?.images}
                style={postStyles.postImage}
              />
            </div>
          )}
          
          {renderVideo()}

          <div style={postStyles.actionsContainer}>
            <div style={postStyles.actionButtons}>
              <div
                className="d-flex align-items-center cursor"
                onClick={() => _postLike(post?.id)}
              >
                <LikeIcon style={{width: 24, height: 24}} />
              </div>
              <div
                className="cursor"
                onClick={() => handleLocalChange("showComment", !showComment)}
              >
                <MessageIcon style={{width: 24, height: 24}} />
              </div>
              <div
                className="cursor"
                onClick={handleOpenShareModal}
              >
                <ShareIcon style={{width: 24, height: 24}} />
              </div>
            </div>

            <div style={postStyles.likesCount} onClick={handleOpenModal}>
              {post?.likes_count || 0} likes
            </div>

            <div style={postStyles.postDescription}>
              {post?.url_field ? (
                <div>
                  <div className="pb-2">{text}</div>
                  <Microlink url={post?.url_field} style={{width: '100%'}} />
                </div>
              ) : post?.text?.includes("\n ") ? (
                <div>
                  <h4>Upcoming Tryout!</h4>
                  <p>Date: {date}</p>
                  <p>Arrival Time: {arrivalTime}</p>
                  <p>Duration: {duration}</p>
                  <p>Fee: {fee}</p>
                  <p>
                    Payment Link:
                    <a href={formattedPaymentLink} target="_blank">
                      Pay Here
                    </a>
                  </p>
                  <p>Location: {location}</p>
                </div>
              ) : (
                <MentionText
                  handleUserClick={handleUserClick}
                  text={post?.text}
                  id={post?.id}
                  taggedUsers={post?.tagged_users}
                />
              )}
            </div>
          </div>

          {showComment && (
            <div style={postStyles.commentsContainer}>
              <div className="commentsText">Comments</div>
              {commentList?.map((comment, index) => (
                <div key={index} style={{marginBottom: '12px'}}>
                  <div
                    className="font-bold cursor"
                    onClick={() => handleUserClick(comment?.user)}
                    style={{fontSize: '14px'}}
                  >
                    {comment?.user?.name || comment?.user?.first_name + " " + comment?.user?.last_name || "Guest"}
                  </div>
                  <div style={{fontSize: '14px', color: '#262626'}}>
                    <MentionText
                      handleUserClick={handleUserClick}
                      text={comment?.comment}
                      id={comment?.post}
                      taggedUsers={comment?.tagged_users}
                    />
                  </div>
                </div>
              ))}

              <div style={postStyles.commentInput}>
                <MentionTextBox
                  setURL={setURL}
                  data={data}
                  setData={setData}
                  border={"none"}
                  allUsers={mentionUsers}
                  style={{flex: 1}}
                />
                {commentLoading ? (
                  <CircularProgress style={{ width: 24, height: 24, color: COLORS.primary }} />
                ) : (
                  <SendIcon
                    className="cursor"
                    style={{
                      opacity: data.trim() ? 1 : 0.6,
                      width: 24,
                      height: 24
                    }}
                    onClick={() => {
                      if (data.trim() !== "") {
                        handleLocalChange("comments", [])
                        handleLocalChange("page", 0)
                        _addComments(post?.id, 0)
                      }
                    }}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </Waypoint>
      <UserLikeModal
        visible={visible}
        handleClose={handleClose}
        likes={likes}
      />
      <UserShareModal
        visible={isShareModalVisible}
        handleClose={handleCloseShareModal}
        post={post}
        allUsers={allUsers}
        getUserLoader={getUserLoader}
        allChannels={allChannels}
      />
      <DeletePostModal
        visible={isDeleteModalVisible}
        handleClose={handleCloseDeleteModal}
        postId={postId}
        getData={getData}
      />
    </>
  )
}
