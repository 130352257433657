// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useContext, useEffect, useRef, useState } from "react"
import {
  HomeHeader,
  AppButton,
  AppInput,
  AddStats,
  Label
} from "../../components"
import { Checkbox, Grid, MenuItem, Select, Typography } from "@mui/material"
import AvatarIcon from "../../assets/images/avatar.png"
import { ReactComponent as Edit } from "../../assets/svg/Edit.svg"
import { ReactComponent as Camera } from "../../assets/svg/Camera.svg"
import AppContext from "../../Context"
import { useSnackbar } from "notistack"
import { COLORS, MAP_API_KEY } from "../../constants"
import { getError } from "../../utils/getError"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import {
  getCircuitAffiliation,
  getCircuitParticipation,
  getTargetPlayerLevel,
  getTargetTeamCompetition,
  getTeamTravel,
  getUserProfile,
  updateOrganizationProfile,
  updateUserProfile
} from "../../api/auth"
import AutoComplete from "react-google-autocomplete"
import EndSeason from "../../components/Season/EndSeason"
import MultipleSelect from "../../components/MultipleSelect"
import { getPriceRange } from "../../api/admin"
import { ReactComponent as Tiktok } from "../../assets/svg/social/Tiktok.svg"
import { ReactComponent as Facebook } from "../../assets/svg/social/Facebook.svg"
import { ReactComponent as Insta } from "../../assets/svg/social/Insta.svg"
import { ReactComponent as Twitter } from "../../assets/svg/social/Twitter.svg"
import { ReactComponent as Youtube } from "../../assets/svg/youtube.svg"
import { ReactComponent as Threads } from "../../assets/svg/threads.svg"
import { validateURL } from "../../utils/ValidateEmail"

export default function OrganizationProfileForm({ }) {
  const { id } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const token = localStorage.getItem("token")
  const { enqueueSnackbar } = useSnackbar()
  const { user, _getProfile, sports, _getSports } = useContext(AppContext)
  const hiddenFileInput = useRef(null)
  const [state, setState] = useState({
    organizationData: "",
    previousUsername: "",
    previousEmail: "",
    username: "",
    organization_name: "",
    email: "",
    program_director_name: "",
    phone_number: "",
    street_address: "",
    city: "",
    price_range: "",
    all_pricerange: "",
    zip_code: "",
    selectedState: "",
    bio: "",
    tag_line: "",
    aau_membership_number: "",
    editMode: true,
    profileImg: null,
    statsVisible: false,
    endSeasonVisible: false,
    loading: false,
    profileObj: null,
    team_travel: [],
    target_player_level: [],
    target_team_competition: [],
    teamTravel: [],
    targetPlayerLevel: [],
    targetTeamCompetition: [],
    circuit_affiliation: "",
    circuit_affiliation_list: [],
    circuit_participation: [],
    circuit_participation_list: [],
    circuit_rank: "",
    live_stream: "",
    tiktok_username: "",
    display_twitter: false,
    facebook_username: "",
    preferred_jersey_number: "",
    display_facebook: false,
    instagram_username: "",
    display_instagram: false,
    twitter_username: "",
    threads_username: "",
    youtube_username: "",
    display_tiktok: false,
    display_threads: false,
    display_youtube: false,
    isURLValid: false,
    main_sport: "",
  })

  const {
    organizationData,
    price_range,
    previousUsername,
    username,
    loading,
    organization_name,
    previousEmail,
    email,
    program_director_name,
    all_pricerange,
    phone_number,
    street_address,
    city,
    zip_code,
    selectedState,
    editMode,
    profileImg,
    statsVisible,
    endSeasonVisible,
    aau_membership_number,
    profileObj,
    latitude,
    longitude,
    price_low,
    price_high,
    bio,
    tag_line,
    team_travel,
    target_player_level,
    target_team_competition,
    teamTravel,
    targetPlayerLevel,
    targetTeamCompetition,
    circuit_affiliation,
    circuit_affiliation_list,
    circuit_participation,
    circuit_participation_list,
    circuit_rank,
    live_stream,
    tiktok_username,
    display_twitter,
    facebook_username,
    display_facebook,
    instagram_username,
    display_instagram,
    twitter_username,
    threads_username,
    youtube_username,
    display_tiktok,
    display_threads,
    display_youtube,
    isURLValid,
    main_sport
  } = state
  useEffect(() => {
    if (organizationData) {
      handleChange("previousUsername", organizationData?.username)
      handleChange("username", organizationData?.username)
      handleChange(
        "organization_name",
        organizationData?.organization?.organization_name
      )
      handleChange("bio", organizationData?.organization?.bio)
      handleChange("tag_line", organizationData?.organization?.tag_line)
      handleChange(
        "program_director_name",
        organizationData?.organization?.program_director_name
      )
      handleChange("phone_number", organizationData?.user?.phone_number)
      handleChange("price_low", organizationData?.organization?.price_low)
      handleChange("price_high", organizationData?.organization?.price_high)
      handleChange("price_range", organizationData?.organization?.price_range)
      handleChange("team_travel", organizationData?.organization?.team_travel)
      handleChange("main_sport", organizationData?.organization?.main_sport?.id)
      organizationData?.organization?.target_player_level?.[0] &&
        handleChange(
          "target_player_level",
          organizationData?.organization?.target_player_level
        )
      handleChange(
        "target_team_competition",
        organizationData?.organization?.target_team_competition
      )
      handleChange(
        "aau_membership_number",
        organizationData?.organization?.aau_membership_number
      )
      handleChange("city", organizationData?.city)
      handleChange("email", organizationData?.email)
      handleChange("previousEmail", organizationData?.email)
      handleChange("street_address", organizationData?.street_address)
      handleChange("zip_code", organizationData?.zip_code)
      handleChange("latitude", organizationData?.latitude)
      handleChange("longitude", organizationData?.longitude)
      handleChange("selectedState", organizationData?.state)
      handleChange("circuit_rank", organizationData?.organization?.circuit_rank?.toString?.())
      handleChange("live_stream", organizationData?.organization?.live_stream)
      handleChange("tiktok_username", organizationData?.organization?.tiktok_username)
      handleChange("threads_username", organizationData?.organization?.threads_username)
      handleChange("youtube_username", organizationData?.organization?.youtube_username)
      handleChange(
        "instagram_username",
        organizationData?.organization?.instagram_username
      )
      handleChange("twitter_username", organizationData?.organization?.twitter_username)
      handleChange("facebook_username", organizationData?.organization?.facebook_username)
      handleChange("display_twitter", organizationData?.organization?.display_twitter)
      handleChange("display_threads", organizationData?.organization?.display_threads)
      handleChange("display_youtube", organizationData?.organization?.display_youtube)
      handleChange("display_instagram", organizationData?.organization?.display_instagram)
      handleChange("display_facebook", organizationData?.organization?.display_facebook)
      handleChange("display_tiktok", organizationData?.organization?.display_tiktok)
      handleChange("circuit_affiliation", organizationData?.organization?.circuit_affiliation?.id)
      handleChange("circuit_participation", organizationData?.organization?.circuit_participation?.map((circuit_participation) => circuit_participation?.id))
    }
  }, [organizationData])

  useEffect(() => {
    _getProfile()
    getProfileData()
    getData()
    _getSports()
    if (
      location?.pathname ===
      `/organization-profile/${organizationData?.user?.id}/add`
    ) {
      handleChange("editMode", true)
    }
  }, [location, id])

  const getData = async () => {
    try {
      const res = await getCircuitAffiliation(token)
      const res1 = await getCircuitParticipation(token)
      handleChange("circuit_affiliation_list", res?.data)
      handleChange("circuit_participation_list", res1?.data)
    } catch (error) {
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const getProfileData = async () => {
    try {
      handleChange("loading", true)
      const res = await getUserProfile(id, token)
      handleChange("organizationData", res?.data)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }
  const onFileChange = event => {
    handleChange("profileImg", URL.createObjectURL(event.target.files[0]))
    handleChange("profileObj", event.target.files[0])
  }

  const handleClick = () => {
    hiddenFileInput.current.click()
  }
  const handleSubmit = async () => {
    try {
      if (tiktok_username && !validateURL(tiktok_username)) {
        alert("Enter a valid tiktok URL")
        return
      }
      if (twitter_username && !validateURL(twitter_username)) {
        alert("Enter a valid twitter URL")
        return
      }
      if (instagram_username && !validateURL(instagram_username)) {
        alert("Enter a valid instagram URL")
        return
      }
      if (youtube_username && !validateURL(youtube_username)) {
        alert("Enter a valid youtube URL")
        return
      }
      if (threads_username && !validateURL(threads_username)) {
        alert("Enter a valid threads URL")
        return
      }
      if (facebook_username && !validateURL(facebook_username)) {
        alert("Enter a valid facebook URL")
        return
      }
      handleChange("loading", true)
      const payload = new FormData()
      if (username !== previousUsername) {
        payload?.append("username", username)
      }
      organization_name &&
        payload?.append("organization.organization_name", organization_name)
      organization_name &&
        payload?.append("name", organization_name)
      program_director_name &&
        payload?.append(
          "organization.program_director_name",
          program_director_name
        )
      tag_line && payload?.append("organization.tag_line", tag_line)
      profileImg &&
        payload?.append("organization.user.profile_picture", profileImg)
      bio && payload?.append("organization.bio", bio)
      profileObj && payload?.append("organization.logo", profileObj)
      aau_membership_number &&
        payload?.append(
          "organization.aau_membership_number",
          aau_membership_number
        )
      price_range && payload?.append("organization.price_range", price_range)
      price_high && payload?.append("organization.price_high", price_high)
      price_low && payload?.append("organization.price_low", price_low)
      if (email !== previousEmail) {
        payload?.append("email", email)
      }
      phone_number && payload?.append("phone_number", phone_number)
      street_address && payload?.append("street_address", street_address)
      city && payload?.append("city", city)
      zip_code && payload?.append("zip_code", zip_code)
      selectedState && payload?.append("state", selectedState)
      // payload?.append("is_2fa", true)
      latitude && payload?.append("latitude", latitude)
      longitude && payload?.append("longitude", longitude)
      circuit_rank && payload?.append("organization.circuit_rank", circuit_rank)
      live_stream && payload?.append("organization.live_stream", live_stream)
      main_sport && payload?.append("organization.main_sport", main_sport)
      payload?.append("organization.tiktok_username", tiktok_username)
      instagram_username &&
        payload?.append("organization.instagram_username", instagram_username)
      twitter_username &&
        payload?.append("organization.twitter_username", twitter_username)
      threads_username &&
        payload?.append("organization.threads_username", threads_username)
      youtube_username &&
        payload?.append("organization.youtube_username", youtube_username)
      facebook_username &&
        payload?.append("organization.facebook_username", facebook_username)
      payload?.append("organization.display_twitter", display_twitter)
      payload?.append("organization.display_instagram", display_instagram)
      payload?.append("organization.display_facebook", display_facebook)
      payload?.append("organization.display_tiktok", display_tiktok)
      payload?.append("organization.display_threads", display_threads)
      payload?.append("organization.display_youtube", display_youtube)
      circuit_affiliation && payload?.append("organization.circuit_affiliation", circuit_affiliation)
      circuit_participation?.length > 0 && circuit_participation?.map((circuit_participation) => payload?.append("organization.circuit_participation", circuit_participation))
      // team_travel &&
      //   payload?.append(
      //     "organization.team_travel",
      //     team_travel || undefined
      //   )
      for (var i = 0; i < team_travel.length; i++) {
        payload.append(
          "organization.team_travel",
          team_travel[i]?.id || team_travel[i]
        )
      }
      for (var i = 0; i < target_player_level.length; i++) {
        payload.append(
          "organization.target_player_level",
          target_player_level[i]?.id || target_player_level[i]
        )
      }
      for (var i = 0; i < target_team_competition.length; i++) {
        payload.append(
          "organization.target_team_competition",
          target_team_competition[i]?.id || target_team_competition[i]
        )
      }

      // Boolean(target_team_competition) &&
      //   payload?.append(
      //     "organization.target_team_competition",
      //     target_team_competition?.id || target_team_competition || undefined
      //   )
      if (organization_name == null || organization_name == "") {
        alert("Organization Name is required")
        handleChange("loading", false)
        handleChange(
          "organization_name",
          organizationData?.organization?.organization_name
        )
      } else if (username == null || username == "") {
        alert("User Name is required")
        handleChange("loading", false)
        handleChange("username", organizationData?.username)
      } else if (
        Number(price_low) >= Number(price_high) &&
        price_low != null &&
        price_low != "" &&
        Number(price_high) <= Number(price_low) &&
        price_high != null &&
        price_high != ""
      ) {
        alert(
          "Price low cannot be greater than Price high, and Price high cannot be lower than Price low!"
        )
        handleChange("loading", false)
      } else {
        //await updateOrganizationProfile(organizationData?.id, payload, token)
        await updateUserProfile(user?.id, payload, token)
        _getProfile(user?.id)
        handleChange("loading", false)
        handleChange("editMode", false)
        navigate(`/organization-profile/${id}`)
        enqueueSnackbar(
          `Organization profile has been ${location?.pathname ===
            `/organization-profile/${organizationData?.user?.id}/add`
            ? "created"
            : "updated"
          }`,
          {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right"
            }
          }
        )
      }
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const handleSearch = async place => {
    try {
      const res = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${place?.geometry.location?.lat()},${place?.geometry.location?.lng()}&key=${MAP_API_KEY}`
      )
      const res1 = await res.json()
      const address =
        res1?.results?.length > 0 && res1?.results[0]?.formatted_address
      var address_components = res1?.results[0]?.address_components
      let dState = ""
      let country = ""
      let city = ""
      let postal_code = ""
      if (address_components !== undefined) {
        const addrComp = address_components
        for (let i = 0; i < addrComp.length; ++i) {
          var typ = addrComp[i]?.types[0]
          if (typ === "administrative_area_level_1") {
            dState = addrComp[i]?.long_name
          } else if (typ === "locality") {
            city = addrComp[i]?.long_name
          } else if (typ === "country") {
            country = addrComp[i]?.long_name
          } else if (typ === "postal_code") {
            postal_code = addrComp[i]?.short_name
          }
        }
      }
      handleChange("latitude", place?.geometry.location?.lat())
      handleChange("longitude", place?.geometry.location?.lng())
      handleChange("street_address", place?.formatted_address)
      handleChange("city", city)
      handleChange("country", country)
      handleChange("selectedState", dState)
      handleChange("zip_code", postal_code)
    } catch (error) {
      alert(error?.message)
    }
  }

  const _getTeamTravel = async () => {
    try {
      const token = localStorage.getItem("token")

      const res = await getTeamTravel(token)
      handleChange("teamTravel", res?.data?.results)
    } catch (error) {
      //console.log(error)
    }
  }

  const _getTargetTeamCompetition = async () => {
    try {
      const token = localStorage.getItem("token")
      const res = await getTargetTeamCompetition(token)
      handleChange("targetTeamCompetition", res?.data?.results)
    } catch (error) {
      //console.log(error)
    }
  }

  const _getTargetPlayerLevel = async () => {
    try {
      const token = localStorage.getItem("token")

      const res = await getTargetPlayerLevel(token)
      handleChange("targetPlayerLevel", res?.data?.results)
    } catch (error) {
    }
  }

  useEffect(() => {
    _getTeamTravel()
    _getTargetTeamCompetition()
    _getTargetPlayerLevel()
  }, [])

  const _isURLValid = (url) => {
    if (url) {
      const isValid = validateURL(url)
      if (!isValid) {
        handleChange("isURLValid", true)
      } else {
        handleChange("isURLValid", false)
      }
    }
  }


  const option1 = (
    <option value={""} disabled>
      Select Team Travel
    </option>
  )
  const option2 = (
    <option value={""} disabled>
      Select Target Player Level[s]
    </option>
  )
  const option3 = (
    <option value={""} disabled>
      Select Target Team Competition
    </option>
  )
  const option4 = (
    <MenuItem value={""} disabled>
      Select Circuit Affiliation
    </MenuItem>
  )

  const list = teamTravel?.map((item, index) => (
    <option key={`${index}-tt`} value={item?.id}>
      {item?.name}
    </option>
  ))
  const list1 = targetPlayerLevel?.map((item, index) => (
    <option key={`${index}-tpl`} value={item?.id}>
      {item?.name}
    </option>
  ))
  const list2 = targetTeamCompetition?.map((item, index) => (
    <option key={`${index}-ttc`} value={item?.id}>
      {item?.name}
    </option>
  ))
  const list3 = circuit_affiliation_list?.map((item, index) => (
    <MenuItem key={`${index}-ttc`} value={item?.id}>
      <>
        <img alt={item?.name} src={item?.image} className="circuit_affiliation_image" />
        {item?.name}
      </>
    </MenuItem>
  ))

  const teamTravelOptions = [option1, ...list]
  const targetPlayerLevelOptions = [option2, ...list1]
  const targetTeamCompetitionOptions = [option3, ...list2]
  const circuitAffiliationOptions = [option4, ...list3]

  const listSports = sports?.map((item, index) => (
    <option key={index} value={item?.id}>
      {item?.name}
    </option>
  ))
  const option5 = <option value={""}>Select Sport</option>
  const sportsOptions = [option5, ...listSports]


  return (
    <div className="">
      <HomeHeader dashboard />
      <section className="container pb-3 mb-0 bg-transparent">
        <Grid className="mb-3" container justifyContent={"space-between"}>
          <div className="pageHeading">Profile</div>
          {!editMode && (
            <AppButton
              width={35}
              height={35}
              borderRadius={35}
              onClick={() => handleChange("editMode", true)}
              backgroundColor={COLORS.primary}
              className={"min-width-remove"}
              title={<Edit />}
            />
          )}
        </Grid>
        <Grid
          className="mb-3 mt-4"
          container
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <div className="">
            <img
              src={
                profileImg ||
                organizationData?.organization?.user?.profile_picture ||
                AvatarIcon
              }
              className={"c-pointer profileIMG"}
            />
            <input
              ref={hiddenFileInput}
              type="file"
              accept="image/*"
              onChange={onFileChange}
              style={{ display: "none" }}
            />
            {editMode && (
              <AppButton
                title={<Camera />}
                onClick={handleClick}
                backgroundColor={"#FAFAFA"}
                borderColor={"#0000000F"}
                width={30}
                borderRadius={30}
                height={30}
                className={"min-width-remove camIcon"}
              />
            )}
          </div>
          <div>
            <AppButton
              width={100}
              height={35}
              onClick={() => handleChange("endSeasonVisible", true)}
              backgroundColor={"#ffffff"}
              fontWeight={300}
              color={"#222"}
              className={"min-width-remove"}
              title={"End Season"}
              borderColor={"#d9d9d9"}
            // className="mr-2"
            />
            {/* <AppButton
              width={100}
              height={35}
              onClick={() => handleChange("statsVisible", true)}
              backgroundColor={"#14B46A"}
              fontWeight={300}
              color={COLORS.white}
              className={"min-width-remove"}
              title={"Add Stats"}
            /> */}
          </div>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="body1" fontWeight="bold" sx={{ mt: 3 }}>
              Info
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInput
              disabled={!editMode}
              label={"Organization Name"}
              height={40}
              onChange={handleChange}
              value={organization_name}
              name={"organization_name"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInput
              disabled={!editMode}
              label={"Tagline"}
              height={40}
              onChange={handleChange}
              value={tag_line}
              name={"tag_line"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInput
              resize
              disabled={!editMode}
              label={"Bio"}
              height={40}
              onChange={handleChange}
              value={bio}
              name={"bio"}
              multiline
              marginTop={0.01}
              height={"auto"}
              minHeightMulti={"50px"}
            />
          </Grid>
          <Grid item xs={12}>
            <AppInput
              disabled={!editMode}
              label={"Sports"}
              select
              selectOptions={sportsOptions}
              height={40}
              onChange={handleChange}
              value={main_sport}
              name={"main_sport"}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12}>
            <Typography variant="body1" fontWeight="bold">
              Personnel
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <AppInput
              disabled={!editMode}
              label={"Program Director"}
              height={40}
              onChange={handleChange}
              value={program_director_name}
              name={"program_director_name"}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems={"flex-end"}>
          <Grid item xs={12}>
            <Typography variant="body1" fontWeight="bold" sx={{ mt: 3 }}>
              Tickets
            </Typography>
          </Grid>

          <Grid item xs={2.75}>
            <AppInput
              disabled={!editMode}
              type={"number"}
              prefix={<div className="mt-2">$</div>}
              label={"Ticket Price Range"}
              height={40}
              onChange={handleChange}
              value={price_low}
              name={"price_low"}
            />
          </Grid>
          <Grid item xs={0.5} className="dashCenter">
            -
          </Grid>
          <Grid item xs={2.75}>
            <AppInput
              disabled={!editMode}
              type={"number"}
              prefix={<div className="mt-2">$</div>}
              label={""}
              height={40}
              onChange={handleChange}
              value={price_high}
              name={"price_high"}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="body1" fontWeight="bold" sx={{ mt: 3 }}>
              Contact
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInput
              label={"User Name"}
              height={40}
              onChange={handleChange}
              value={username}
              name={"username"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInput
              disabled={true}
              label={"Email"}
              height={40}
              onChange={handleChange}
              value={email}
              name={"email"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInput
              disabled={!editMode}
              placeholder={"123-456-7890"}
              label={"Phone number"}
              type={"number"}
              height={40}
              onChange={handleChange}
              value={phone_number}
              name={"phone_number"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            {editMode ? (
              <>
                <Label text={"Street Address"} fontNormal />
                <AutoComplete
                  apiKey={MAP_API_KEY}
                  className="locationSearch"
                  options={{ types: ["geocode", "establishment"] }}
                  style={{ height: 40, backgroundColor: "#fff" }}
                  onChange={e =>
                    handleChange("street_address", e?.target?.value)
                  }
                  defaultValue={street_address}
                  onPlaceSelected={place => {
                    handleSearch(place)
                  }}
                  placeholder=""
                />
              </>
            ) : (
              <AppInput
                disabled={true}
                label={"Street address"}
                height={40}
                onChange={handleChange}
                value={street_address}
                name={"street_address"}
              />
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInput
              disabled={!editMode}
              label={"City"}
              height={40}
              onChange={handleChange}
              value={city}
              name={"city"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInput
              disabled={!editMode}
              label={"Zipcode"}
              height={40}
              onChange={handleChange}
              value={zip_code}
              name={"zip_code"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInput
              disabled={!editMode}
              label={"State"}
              height={40}
              onChange={handleChange}
              value={selectedState}
              name={"selectedState"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInput
              disabled={!editMode}
              label={"AAU Membership Number"}
              height={40}
              onChange={handleChange}
              value={aau_membership_number}
              name={"aau_membership_number"}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="body1" fontWeight="bold" sx={{ mt: 3 }}>
              Organization Info
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <MultipleSelect
              disabled={!editMode}
              label={"Team Travel"}
              height={40}
              data={teamTravel}
              onChange={e => handleChange("team_travel", e)}
              value={team_travel?.map(v => (v?.id ? v.id : v))}
              name={"team_travel"}
              placeholder="Select Team Travel[s]"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <MultipleSelect
              disabled={!editMode}
              label={"Target Player Level[s]"}
              data={targetPlayerLevel}
              height={40}
              onChange={e => handleChange("target_player_level", e)}
              value={target_player_level?.map(v => (v?.id ? v.id : v))}
              name={"target_player_level"}
              placeholder="Select Player Level[s]"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <MultipleSelect
              disabled={!editMode}
              label={"Target Team Competition[s]"}
              data={targetTeamCompetition}
              height={40}
              onChange={e => handleChange("target_team_competition", e)}
              value={target_team_competition?.map(v => (v?.id ? v.id : v))}
              name={"target_team_competition"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Label fontNormal text={"Circuit Affiliation"} />
            <Select
              defaultValue={circuit_affiliation}
              value={circuit_affiliation}
              style={{
                border: "none",
                backgroundColor: "#fff",
                borderRadius: 10,
                color: "#000",
                height: 40,
                width: "100%"
              }}
              placeholder={"placeholder"}
              onChange={value => handleChange("circuit_affiliation", value.target.value)}
            >
              {circuitAffiliationOptions}
            </Select>
            {/* <AppInput
              disabled={!editMode}
              label={"Circuit Affiliation"}
              select
              selectOptions={circuitAffiliationOptions}
              height={40}
              onChange={handleChange}
              value={circuit_affiliation}
              name={"circuit_affiliation"}
            /> */}
          </Grid>
          <Grid item xs={12} md={4}>
            <MultipleSelect
              disabled={!editMode}
              label={"Circuit participation"}
              data={circuit_participation_list}
              height={40}
              onChange={e => handleChange("circuit_participation", e)}
              value={circuit_participation?.map(v => (v?.id ? v.id : v))}
              name={"circuit_participation"}
              placeholder="Select Circuit participation"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInput
              disabled={!editMode}
              label={"Circuit Affiliation Rank (Current Highest Team)"}
              height={40}
              type={'number'}
              onChange={(k, v) => Number(v) >= 0 ? handleChange(k, v) : alert("you can't enter negative number")}
              value={circuit_rank}
              name={"circuit_rank"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInput
              disabled={!editMode}
              label={"Live stream"}
              height={40}
              onChange={handleChange}
              onBlur={() => _isURLValid(live_stream)}
              errorText={isURLValid && "url is not valid"}
              value={live_stream}
              name={"live_stream"}
            />
          </Grid>
        </Grid>
        <Label className={"mt-5"} text={"Social Media"} />
        <Grid container sx={{ mt: 0.2 }} spacing={3}>
          <Grid item xs={12} md={4}>
            <AppInput
              disabled={!editMode}
              prefix={<Tiktok style={{ marginTop: 12 }} />}
              label={"Tiktok profile URL"}
              height={40}
              errorText={tiktok_username && !validateURL(tiktok_username) && "url is not valid"}
              onChange={handleChange}
              value={tiktok_username}
              name={"tiktok_username"}
            />
            <div className="checkboxDiv mb-3">
              <Checkbox
                disabled={!editMode}
                defaultChecked={false}
                checked={display_tiktok}
                onClick={() => handleChange("display_tiktok", !display_tiktok)}
                className="checkbox1"
                style={{
                  marginTop: -3
                }}
                sx={{
                  color: "rgba(201, 208, 216, 1)",
                  "&.Mui-checked": {
                    color: COLORS.primary
                  }
                }}
              />
              <Typography variant="body2" className="greyColor checkboxLabel">
                Display on social media profile.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInput
              disabled={!editMode}
              prefix={<Facebook style={{ marginTop: 12 }} />}
              label={"Facebook profile URL"}
              height={40}
              errorText={facebook_username && !validateURL(facebook_username) && "url is not valid"}
              onChange={handleChange}
              value={facebook_username}
              name={"facebook_username"}
            />
            <div className="checkboxDiv mb-3">
              <Checkbox
                defaultChecked={false}
                disabled={!editMode}
                checked={display_facebook}
                onClick={() =>
                  handleChange("display_facebook", !display_facebook)
                }
                className="checkbox1"
                style={{
                  marginTop: -3
                }}
                sx={{
                  color: "rgba(201, 208, 216, 1)",
                  "&.Mui-checked": {
                    color: COLORS.primary
                  }
                }}
              />
              <Typography variant="body2" className="greyColor checkboxLabel">
                Display on social media profile.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInput
              disabled={!editMode}
              prefix={<Insta style={{ marginTop: 12 }} />}
              label={"Instagram profile URL"}
              errorText={instagram_username && !validateURL(instagram_username) && "url is not valid"}
              height={40}
              onChange={handleChange}
              value={instagram_username}
              name={"instagram_username"}
            />
            <div className="checkboxDiv mb-3">
              <Checkbox
                disabled={!editMode}
                checked={display_instagram}
                defaultChecked={false}
                onClick={() =>
                  handleChange("display_instagram", !display_instagram)
                }
                className="checkbox1"
                style={{
                  marginTop: -3
                }}
                sx={{
                  color: "rgba(201, 208, 216, 1)",
                  "&.Mui-checked": {
                    color: COLORS.primary
                  }
                }}
              />
              <Typography variant="body2" className="greyColor checkboxLabel">
                Display on social media profile.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInput
              disabled={!editMode}
              prefix={<Twitter style={{ marginTop: 12 }} />}
              label={"Twitter profile URL"}
              errorText={twitter_username && !validateURL(twitter_username) && "url is not valid"}
              height={40}
              onChange={handleChange}
              value={twitter_username}
              name={"twitter_username"}
            />
            <div className="checkboxDiv mb-3">
              <Checkbox
                disabled={!editMode}
                defaultChecked={false}
                checked={display_twitter}
                onClick={() =>
                  handleChange("display_twitter", !display_twitter)
                }
                className="checkbox1"
                style={{
                  marginTop: -3
                }}
                sx={{
                  color: "rgba(201, 208, 216, 1)",
                  "&.Mui-checked": {
                    color: COLORS.primary
                  }
                }}
              />
              <Typography variant="body2" className="greyColor checkboxLabel">
                Display on social media profile.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInput
              disabled={!editMode}
              prefix={<Youtube style={{ marginTop: 12 }} />}
              label={"Youtube profile URL"}
              errorText={youtube_username && !validateURL(youtube_username) && "url is not valid"}
              height={40}
              onChange={handleChange}
              value={youtube_username}
              name={"youtube_username"}
            />
            <div className="checkboxDiv mb-3">
              <Checkbox
                disabled={!editMode}
                defaultChecked={false}
                checked={display_youtube}
                onClick={() =>
                  handleChange("display_youtube", !display_youtube)
                }
                className="checkbox1"
                style={{
                  marginTop: -3
                }}
                sx={{
                  color: "rgba(201, 208, 216, 1)",
                  "&.Mui-checked": {
                    color: COLORS.primary
                  }
                }}
              />
              <Typography variant="body2" className="greyColor checkboxLabel">
                Display on social media profile.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInput
              disabled={!editMode}
              prefix={<Threads style={{ marginTop: 12 }} />}
              label={"Threads profile URL"}
              errorText={threads_username && !validateURL(threads_username) && "url is not valid"}
              height={40}
              onChange={handleChange}
              value={threads_username}
              name={"threads_username"}
            />
            <div className="checkboxDiv mb-3">
              <Checkbox
                disabled={!editMode}
                defaultChecked={false}
                checked={display_threads}
                onClick={() =>
                  handleChange("display_threads", !display_threads)
                }
                className="checkbox1"
                style={{
                  marginTop: -3
                }}
                sx={{
                  color: "rgba(201, 208, 216, 1)",
                  "&.Mui-checked": {
                    color: COLORS.primary
                  }
                }}
              />
              <Typography variant="body2" className="greyColor checkboxLabel">
                Display on social media profile.
              </Typography>
            </div>
          </Grid>
        </Grid>
        {editMode && (
          <Grid container sx={{ mt: 3 }}>
            <AppButton
              onClick={() => handleChange("editMode", false)}
              title={"Cancel"}
              className={"mr-4"}
              color={"#000000D9"}
              fontWeight={300}
              borderColor={"#D9D9D9"}
              width={100}
            />
            <AppButton
              title={"Save"}
              loading={loading}
              color={COLORS.white}
              fontWeight={300}
              backgroundColor={COLORS.primary}
              width={100}
              onClick={handleSubmit}
            />
          </Grid>
        )}
      </section>
      <AddStats
        visible={statsVisible}
        organization
        handleClose={() => handleChange("statsVisible", false)}
      />
      <EndSeason
        visible={endSeasonVisible}
        organization
        handleClose={() => handleChange("endSeasonVisible", false)}
        type="Organization"
      />
    </div>
  )
}
