// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useEffect, useState } from "react"
import { AppButton, AppInput, HomeHeader } from "../../components"
import { Grid } from "@mui/material"
import eyeIcon from "../../assets/svg/eye.svg"
import eyeOff from "../../assets/svg/eyeOff.svg"
import { ReactComponent as EmailIcon } from "../../assets/svg/email.svg"
import { ReactComponent as PasswordIcon } from "../../assets/svg/password.svg"
import { ReactComponent as CrossButton } from "../../assets/svg/CrossButton.svg"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import {
  acceptLink,
  declineLink,
  loginUser, updateProfile, validateOTP, verifyOTP,
} from "../../api/auth"
import AppContext from "../../Context"
import { useContext } from "react"
import { useSnackbar } from "notistack"
import { COLORS } from "../../constants"
import AuthCode from 'react-auth-code-input';
import { getError } from "../../utils/getError"

export default function Login({ }) {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { user, setUser, _getProfile } = useContext(AppContext)
  const path = window.location.pathname
  const [state, setState] = useState({
    email: "",
    password: "",
    code: "",
    loading: false,
    loadingOTP: false,
    showPassword: false,
    isOTP: false,
    default_auth: ''
  })

  useEffect(() => {
    if (user?.is_2fa) {
      navigate("/")
    }
    if (user && !user?.is_2fa) {
      handleChange("isOTP", true)
    }
  }, [user])

  const {
    email,
    password,
    loading,
    showPassword,
    isOTP,
    code,
    default_auth,
    loadingOTP
  } = state

  const handleChange = (key, value) => {
    setState(pre => ({
      ...pre,
      [key]: value
    }))
  }

  const handleLogin = async () => {
    try {

      handleChange("loading", true)
      const payload = {
        username: email,
        password
      }
      const res = await loginUser(payload)
      if (res?.data?.is_2fa || res?.data?.message) {
        handleChange("userData", res?.data?.user)
        handleChange("default_auth", res?.data?.default_auth)
        handleChange("isOTP", true)
      } else if (!res?.data?.user?.default_auth) {
        // navigate("/2fa-verfition")
        localStorage.setItem("token", res?.data?.token)
        _getProfile(res?.data?.user?.id)
        navigate("/dashboard")
      } else {
        const res1 = await updateProfile({ is_2fa: true }, res?.data?.user?.id, res?.data?.token)
        handleChange("userData", res1?.data?.user)
        handleChange("isOTP", true)
      }
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      const errorText = Object.values(error?.response?.data)
      if (errorText.length > 0) {
        alert(`Error: ${errorText[0]}`)
      } else {
        alert(`Error: ${error}`)
      }
    }
  }

  const handleOTP = async () => {
    try {

      handleChange("loadingOTP", true)
      const payload = {
        token: code,
        email
      }
      const res = await verifyOTP(payload)
      localStorage.setItem("token", res?.data?.token)
      _getProfile(res?.data?.user?.id)
      navigate("/dashboard")
      handleChange("loadingOTP", false)
      enqueueSnackbar(`Login Successful`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    } catch (error) {
      handleChange("loadingOTP", false)
      const errorText = Object.values(error?.response?.data)
      if (error?.response?.data?.status !== 'fail' && error?.response?.data?.message) {
        alert(`Error: ${error?.response?.data?.message}`)
      } else if (error?.response?.data?.status !== 'fail' && Array.isArray(errorText) && errorText.length > 0) {
        alert(`Error: ${errorText[0]}`)
      } else {
        alert(`Invalid OTP`)
      }
    }
  }

  const cancelLogin = () => {
    localStorage.removeItem("token")
    localStorage.removeItem("user")
    setUser(null)
    handleChange("isOTP", false)
  }


  return (
    <div>
      <HomeHeader loginPress={cancelLogin} />
      <section>
        <div className="container loginContainer">
          <Grid container justifyContent={"center"}>
            {
              isOTP
                ?
                <Grid item xs={10} sm={10} md={6} lg={4} className="">
                  <div className="text-left">
                    <CrossButton className="cursor mb-3" onClick={cancelLogin} />
                    <div className="font-24 font-bold greyColor">
                      Security Verification
                    </div>
                    <div className="light-grey-text font-bold mb-3">
                      {default_auth === "email" ? "Email" : default_auth === "phone" ? "SMS" : "Google Authenticator"}
                    </div>
                  </div>
                  <AuthCode allowedCharacters='numeric' containerClassName={'outerotpInput'} inputClassName={"otpInput"} onChange={(code) => handleChange("code", code)} />
                  <AppButton
                    title={"Confirm"}
                    onClick={handleOTP}
                    loading={loadingOTP}
                    disabled={code?.length !== 6}
                    backgroundColor={COLORS.primary}
                    color={"#fff"}
                  />
                </Grid>
                :

                <Grid item xs={10} sm={10} md={6} lg={4} className="">
                  <div className="text-center">
                    <div className="font-24 greyColor">
                      Welcome Back
                    </div>
                    <div className="greyColor">
                      New to The Sports Portal?
                      <span onClick={() => navigate('/signup')} className="text_primary font-bold cursor"> Sign Up</span>
                    </div>
                  </div>
                  <AppInput
                    label={"Enter"}
                    value={email}
                    name={"email"}
                    prefix={<div className="emailIcon">
                      <EmailIcon /></div>}
                    onChange={handleChange}
                    className="mb-3 mt-3"
                    placeholder={"Enter email"}
                  />
                  <AppInput
                    className="mb-4 mt-3"
                    label={"Password"}
                    value={password}
                    prefix={<div className="passwordIcon"><PasswordIcon /></div>}
                    type={showPassword ? "text" : "password"}
                    name={"password"}
                    onChange={handleChange}
                    postfix={
                      <img
                        src={!showPassword ? eyeIcon : eyeOff}
                        onClick={() =>
                          handleChange("showPassword", !showPassword)
                        }
                        width={20}
                        className={"c-pointer"}
                      />
                    }
                    placeholder={"********"}
                  />
                  <div
                    className="text-left mb-5 c-pointer"
                    onClick={() => navigate("/forgot-password")}
                  >
                    <p className="font-bold text_primary">Forgot password</p>
                  </div>
                  <AppButton
                    title={"Log In"}
                    onClick={handleLogin}
                    loading={loading}
                    disabled={!email || !password}
                    backgroundColor={COLORS.primary}
                    color={"#fff"}
                  />
                </Grid>
            }
          </Grid>
        </div>
      </section>
    </div>
  )
}
