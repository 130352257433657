// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useContext, useEffect } from "react"
import { HomeHeader } from "../../components"
import { Element } from "react-scroll"
import { Grid } from "@mui/material"
import AppContext from "../../Context"

export default function PrivacyPolicy({ }) {
  const { privacyPolicy, user } = useContext(AppContext)
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      })
    }, 100)
  }, [])
  const currentUser = user?.is_2fa

  return (
    <div>
      <HomeHeader />
      <Element name="Element1" className="element">
        <section className={currentUser ? '' : "mt-5"}>
          <div className="container d-flex  justify-content-center align-items-center">
            <Grid container justifyContent={"center"}>
              <Grid container>
                <div className="text-left">
                  <p className={"pageHeading"}>Privacy Policy</p>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>
      </Element>
      <section className="">
        <div className="container">
          {
            Array.isArray(privacyPolicy) && privacyPolicy?.map((privacy, index) => (
              <div key={index}>
                {privacy?.body}
              </div>
            ))
          }
        </div>
      </section>
    </div>
  )
}
