import { TextField } from "@mui/material"
import React from "react"
import Label from "./Label"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { TimePicker } from "@mui/x-date-pickers/TimePicker"

export default function AppInput({
  required,
  backgroundColor,
  color,
  borderRadius,
  placeholder,
  multiline,
  prefix,
  border,
  postfix,
  label,
  className,
  value,
  onChange,
  name,
  type,
  select,
  selectOptions,
  height,
  resize,
  onKeyPress,
  disabled,
  max,
  minHeightMulti,
  maxLength,
  errorText,
  multiPadding,
  textareaClassName,
  muiTime,
  mainWidth,
  schedule,
  marginTop,
  textAreaWidth,
  textAreaMarginTop,
  ...props
}) {
  return (
    <div style={{ width: mainWidth || "100%" }} className={className}>
      <Label fontNormal text={label} />
      <div
        style={{
          width: "100%",
          display: "flex",
          borderRadius: borderRadius || 8,
          backgroundColor: backgroundColor || "#fff",
          color: color || "#000",
          padding: muiTime
            ? "0px"
            : multiline
              ? multiPadding || "0 0px 0 15px"
              : "0 15px",
          height: multiline ? height || 150 : height || 55,
          marginTop: multiline ? marginTop || 15 : marginTop || 0,
          justifyContent: "space-between",
          border: muiTime ? "none" : border || "1px solid #D9D9D9"
        }}
      >
        <div className="d-flex" style={{ width: "100%" }}>
          {prefix}
          {muiTime ? (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label=""
                InputLabelProps={{ shrink: false }}
                value={value}
                onChange={value => onChange(name, value)}
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder="HH:MM"
                    classes={{
                      root: schedule ? "timeInputRoot1" : "timeInputRoot",
                      input: "timeInput"
                    }}
                    sx={{
                      width: "100%",
                      borderWidth: 0,
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      "& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#D9D9D9",
                          borderWidth: 1,

                          height: 40
                        },
                      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          height: 40,
                          borderWidth: 1,
                          borderColor: "#D9D9D9"
                        }
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          ) : multiline ? (
            <textarea
              type={type}
              disabled={disabled}
              placeholder={placeholder}
              onChange={value => onChange(name, value.target.value)}
              value={value}
              className={textareaClassName}
              style={{
                resize: resize ?? "inherit",
                marginTop: textAreaMarginTop || 10,
                minHeight: minHeightMulti ?? "auto",
                border: "none",
                marginBottom: multiline ? props?.marginBottom || 0 : 0,
                backgroundColor: backgroundColor || "#fff",
                color: color || "#000",
                width: textAreaWidth || "100%"
              }}
            />
          ) : (
            <>
              {select ? (
                <select
                  value={value}
                  disabled={disabled}
                  style={{
                    border: "none",
                    backgroundColor: backgroundColor || "#fff",
                    color: color || "#000",
                    height: height - 5 || 50,
                    width: "100%"
                  }}
                  placeholder={placeholder}
                  onChange={value => onChange(name, value.target.value)}
                  max={max}
                  maxLength={maxLength}
                >
                  {selectOptions}
                </select>
              ) : (
                <input
                  {...props}
                  type={type}
                  max={max}
                  maxLength={maxLength}
                  placeholder={placeholder}
                  onWheel={e => e.target.blur()}
                  onChange={value => onChange(name, value.target.value)}
                  value={value}
                  required={required}
                  disabled={disabled}
                  onKeyPress={onKeyPress}
                  style={{
                    border: "none",
                    backgroundColor: backgroundColor || "#fff",
                    color: color || "#000",
                    height: height - 5 || 50,
                    width: "100%"
                  }}
                />
              )}
            </>
          )}
        </div>
        {postfix}
      </div>
      {!!errorText && <div className="errorText">{errorText}</div>}
      {maxLength && (
        <p
          style={{ fontWeight: 500, fontSize: 12, textAlign: "right" }}
          className="font-14 greyColor mb-2"
        >
          {value?.length || 0}/{maxLength}
        </p>
      )}
    </div>
  )
}
