import { Box, Grid, Modal, Typography } from "@mui/material"
import React from "react"
import AppButton from "../AppButton"
import { COLORS } from "../../constants"

export default function GeneralModal({
  visible,
  icon,
  title,
  description,
  handleClose,
  handlePress,
  loading,
  ActionButtonText,
  backgroundColor
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    borderRadius: 6,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4
  }
  return (
    <Modal
      open={visible}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid container spacing={3}>
          <Grid item xs={2}>
            {icon && icon}
          </Grid>
          <Grid item xs={10}>
            {!!title && (
              <Typography
                id="modal-modal-description"
                className="text-black font-18 font-500"
              >
                {title}
              </Typography>
            )}
            {!!description && (
              <Typography
                id="modal-modal-description"
                className="text-black"
                sx={{ mt: !!title ? 1 : 0 }}
              >
                {description}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container justifyContent={"flex-end"} className="mt-3">
          <AppButton
            title={"Cancel"}
            height={40}
            onClick={handleClose}
            width={"auto"}
            backgroundColor={"#595959"}
            className={"mr-2"}
            color={"#fff"}
          />
          <AppButton
            title={ActionButtonText}
            height={40}
            loading={loading}
            onClick={handlePress}
            width={"auto"}
            fontWeight={"normal"}
            backgroundColor={backgroundColor || COLORS.primary}
            color={COLORS.white}
          />
        </Grid>
      </Box>
    </Modal>
  )
}
