import * as React from "react"
import { styled } from "@mui/material/styles"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { AddStats, AppButton, Loader, Tryout } from ".."
import { COLORS } from "../../constants"
import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import AppContext from "../../Context"
import { TablePagination } from "@mui/material"
import { getOrganizationTeam } from "../../api/auth"
import { useEffect } from "react"
import { useSnackbar } from "notistack"
import { getError } from "../../utils/getError"
import { ROSTER } from "../../constants/routes"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FAFAFA",
    color: "#000000D9"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  //   '&:nth-of-type(odd)': {
  //     backgroundColor: theme.palette.action.hover,
  //   },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0
  }
}))

function createData(
  name,
  coach_name,
  practice_location,
  capacity,
  win_percent,
  wins,
  loss,
  roaster
) {
  return {
    name,
    coach_name,
    practice_location,
    capacity,
    win_percent,
    wins,
    loss,
    roaster
  }
}

const rows = [
  createData(
    "Team A",
    "John Doe",
    "6th Street, Draton 4350",
    6.0,
    "80%",
    24,
    5,
    "Open"
  ),
  createData(
    "Team A",
    "John Doe",
    "6th Street, Draton 4350",
    9.0,
    "80%",
    37,
    5,
    "Closed"
  ),
  createData(
    "Team A",
    "John Doe",
    "6th Street, Draton 4350",
    16.0,
    "80%",
    24,
    5,
    "Open"
  ),
  createData(
    "Team A",
    "John Doe",
    "6th Street, Draton 4350",
    3.7,
    "80%",
    67,
    5,
    "Closed"
  ),
  createData(
    "Team A",
    "John Doe",
    "6th Street, Draton 4350",
    16.0,
    "80%",
    49,
    5,
    "Open"
  )
]

export default function OrganizationTeamTable({
  handleChangeMain,
  noActions,
  organizationData,
  rowsPage
}) {
  const { id } = useParams()
  const { user, isCoach } = React.useContext(AppContext)
  const { enqueueSnackbar } = useSnackbar()
  const token = localStorage.getItem("token")
  const navigate = useNavigate()

  const [state, setState] = useState({
    statsVisible: false,
    teamID: "",
    teams: [],
    loading: false,
    limit: 5,
    page: 0,
    tryoutVisible: false,
    offset: 0
  })

  const { tryoutVisible, statsVisible, teamID, teams, loading, limit, page } =
    state
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPage || 5)

  useEffect(() => {
    if (organizationData?.id) {
      getData(page)
    }
  }, [organizationData?.id])

  const handleChangePage = (e, page) => {
    getData(page)
  }

  // const [page, setPage] = React.useState(0)

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    // setPage(0)
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0

  const getData = async nextPage => {
    try {
      handleChange("teams", null)
      handleChange("loading", true)
      const offset = limit * nextPage
      const res = await getOrganizationTeam(
        `?organization__id=${organizationData?.id}&limit=${limit}&offset=${offset}`,
        token
      )
      handleChange("teams", res?.data)
      handleChange("offset", offset)
      handleChange("page", nextPage)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const handleClose = getTeam => {
    handleChange("statsVisible", false)
    handleChange("teamID", "")
    if (getTeam) {
      getData(0)
    }
  }
  return (
    <>
      <TableContainer>
        {loading ? (
          <div className="divCenter fullWidthWeb">
            <Loader />
          </div>
        ) : (
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Team Name</StyledTableCell>
                <StyledTableCell align="left" width={150}>Coach Name</StyledTableCell>
                <StyledTableCell align="left" width={150}>Assistant Coach</StyledTableCell>
                <StyledTableCell align="left">
                  Practice Location
                </StyledTableCell>
                <StyledTableCell align="left">Capacity</StyledTableCell>
                <StyledTableCell align="left">Win%</StyledTableCell>
                <StyledTableCell align="left">Wins</StyledTableCell>
                <StyledTableCell align="left">Loss</StyledTableCell>
                {/* {
                  (user?.id == id || isCoach) && */}
                <StyledTableCell align="left">Roster</StyledTableCell>
                {/* } */}
                {user?.id == id && !noActions && (
                  <StyledTableCell align="left">Action</StyledTableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {teams?.results?.map(team => (
                <StyledTableRow key={team?.team_name}>
                  <StyledTableCell component="th" scope="row">
                    {team?.team_name}
                  </StyledTableCell>
                  <StyledTableCell align="left" className={team?.coaches?.user?.id && "cursor hyperLink"} onClick={() => team?.coaches?.user?.id ? navigate(`/coach-profile/${team?.coaches?.user?.id}`) : console.log()}>
                    {team?.coaches?.user?.name || team?.coach_name}
                  </StyledTableCell>
                  <StyledTableCell align="left" className={team?.assistant_coaches?.user?.id && "cursor hyperLink"} onClick={() => team?.assistant_coaches?.user?.id ? navigate(`/coach-profile/${team?.assistant_coaches?.user?.id}`) : console.log()}>
                    {team?.assistant_coaches?.user?.name || team?.assistant_coach_name}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {team?.practice_location}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {team?.max_roster_size}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {team?.win_percent}
                  </StyledTableCell>
                  <StyledTableCell align="left">{team?.win}</StyledTableCell>
                  <StyledTableCell align="left">{team?.loss}</StyledTableCell>
                  {/* { */}
                  {/* // (user?.id == id || isCoach) && */}
                  <StyledTableCell align="left">
                    <AppButton
                      // disabled={user?.id != id && user?.id != team?.coaches?.user?.id}
                      // noOpacity
                      width={80}
                      height={30}
                      onClick={() => {
                        navigate(
                          ROSTER?.replace(":team", team?.id)?.replace(
                            ":organization",
                            team?.organization
                          )
                        )
                      }}
                      className={"min-width-remove"}
                      title={team?.roaster === "Open" ? "Open" : "Closed"}
                      color={COLORS.white}
                      backgroundColor={
                        team?.roaster === "Open" ? "#52C41A" : "#FF4D4F"
                      }
                      fontWeight={400}
                    />
                  </StyledTableCell>
                  {/* // } */}
                  {!noActions && (
                    <StyledTableCell align="left">
                      {user?.id == id && (
                        <div className="d-flex" style={{ marginLeft: -10 }}>
                          <AppButton
                            width={80}
                            height={30}
                            className={"min-width-remove"}
                            title={"Schedule"}
                            color={COLORS.primary}
                            backgroundColor={"transparent"}
                            fontWeight={400}
                          />
                          <AppButton
                            width={80}
                            height={30}
                            className={"min-width-remove"}
                            title={"Tryout"}
                            onClick={() => handleChange("tryoutVisible", true)}
                            color={COLORS.primary}
                            backgroundColor={"transparent"}
                            fontWeight={400}
                          />
                          {user?.id == id && (
                            <AppButton
                              width={80}
                              onClick={() => {
                                handleChange("statsVisible", true)
                                handleChange("teamID", team?.id)
                              }}
                              height={30}
                              className={"min-width-remove"}
                              title={"Add Stat"}
                              color={COLORS.primary}
                              backgroundColor={"transparent"}
                              fontWeight={400}
                            />
                          )}
                          {user?.id == id && (
                            <AppButton
                              width={80}
                              onClick={() => {
                                navigate(
                                  ROSTER?.replace(":team", team?.id)?.replace(
                                    ":organization",
                                    team?.organization
                                  )
                                )
                              }}
                              height={30}
                              className={"min-width-remove"}
                              title={"Roster"}
                              color={COLORS.primary}
                              backgroundColor={"transparent"}
                              fontWeight={400}
                            />
                          )}
                        </div>
                      )}
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        )}
        {teams?.count > 0 && (
          <TablePagination
            rowsPerPageOptions={[5]}
            component="div"
            count={teams?.count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </TableContainer>
      <AddStats
        visible={statsVisible}
        teamID={teamID}
        organization
        handleClose={handleClose}
      />
      <Tryout
        visible={tryoutVisible}
        teamID={teamID}
        getData={getData}
        organizationData={organizationData}
        handleClose={() => handleChange("tryoutVisible", false)}
      />
    </>
  )
}
