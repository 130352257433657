import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AddStats, AppButton, Loader, PracticeSchedule, Tryout } from '..';
import { COLORS } from '../../constants';
import { useNavigate } from 'react-router-dom';
import { TablePagination } from '@mui/material';
import AppContext from '../../Context';
import { ROSTER } from '../../constants/routes';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#FAFAFA',
        color: "#000000D9",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    //   '&:nth-of-type(odd)': {
    //     backgroundColor: theme.palette.action.hover,
    //   },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData("Team A", 'John Doe', 'California', 6.0, 24),
    createData("Team A", 'John Doe', 'California', 9.0, 37),
    createData("Team A", 'John Doe', 'California', 16.0, 24),
    createData("Team A", 'John Doe', 'California', 3.7, 67),
    createData("Team A", 'John Doe', 'California', 16.0, 49),
];

export default function TeamTable({ teams, loading, id, rowsPage, page, limit, handleChangePage }) {
    const navigate = useNavigate()
    const [rowsPerPage, setRowsPerPage] = React.useState(rowsPage || 5)
    const { user, isCoach } = React.useContext(AppContext)
    const handleEditTeam = (id) => {
        navigate(`/teams/edit/${id}`)
    }

    const [state, setState] = React.useState({
        statsVisible: false,
        tryoutVisible: false,
        practiceScheduleVisible: false,
        teamID: ''
    })

    const { tryoutVisible, statsVisible, practiceScheduleVisible, teamID } = state

    const handleChange = (key, value) => {
        setState(pre => ({ ...pre, [key]: value }))
    }

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10))
        // setPage(0)
    }

    const handleClose = getTeam => {
        handleChange("statsVisible", false)
        handleChange("teamID", "")
        if (getTeam) {
            handleChangePage(null, 0)
        }
    }

    return (
        <TableContainer>
            {
                loading ?
                    <div className='divCenter fullWidthWeb'>
                        <Loader />
                    </div>
                    :
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Team Name</StyledTableCell>
                                <StyledTableCell align="left" width={150}>Coach Name</StyledTableCell>
                                <StyledTableCell align="left" width={150}>Assistant Coach</StyledTableCell>
                                <StyledTableCell align="left">Team Location</StyledTableCell>
                                <StyledTableCell align="left">Capacity</StyledTableCell>
                                <StyledTableCell align="left">Action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {teams?.results?.map((team) => (
                                <StyledTableRow key={team?.team_name}>
                                    <StyledTableCell component="th" scope="row">
                                        {team?.team_name}
                                    </StyledTableCell>
                                    <StyledTableCell align="left" className={team?.coaches?.user?.id && "cursor hyperLink"} onClick={() => team?.coaches?.user?.id ? navigate(`/coach-profile/${team?.coaches?.user?.id}`) : console.log()}>
                                        {team?.coaches?.user?.name || team?.coach_name}
                                    </StyledTableCell>
                                    <StyledTableCell align="left" className={team?.assistant_coaches?.user?.id && "cursor hyperLink"} onClick={() => team?.assistant_coaches?.user?.id ? navigate(`/coach-profile/${team?.assistant_coaches?.user?.id}`) : console.log()}>
                                        {team?.assistant_coaches?.user?.name || team?.assistant_coach_name}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{team?.practice_location}</StyledTableCell>
                                    <StyledTableCell align="left">{team?.max_roster_size}</StyledTableCell>
                                    <StyledTableCell align="left">
                                        <div className='d-flex' style={{ marginLeft: -10 }}>
                                            <AppButton width={80} height={30} className={'min-width-remove'} title={'Game'} color={COLORS.primary} backgroundColor={'transparent'} fontWeight={400}
                                                onClick={() => {
                                                    navigate(`/game-schedule/${team?.id}`)
                                                }}
                                            />
                                            <AppButton width={80} height={30} className={'min-width-remove'} title={'Practice'} color={COLORS.primary} backgroundColor={'transparent'} fontWeight={400}
                                                onClick={() => {
                                                    handleChange("practiceScheduleVisible", true)
                                                    handleChange("teamID", team?.id)
                                                }}
                                            />
                                            <AppButton width={80} height={30} className={'min-width-remove'} title={'Try out'} color={COLORS.primary} backgroundColor={'transparent'} fontWeight={400}
                                                onClick={() => {
                                                    handleChange("tryoutVisible", true)
                                                    handleChange("teamID", team?.id)
                                                }}
                                            />
                                            {user?.id == id && (
                                                <AppButton
                                                    width={80}
                                                    onClick={() => {
                                                        handleChange("statsVisible", true)
                                                        handleChange("teamID", team?.id)
                                                    }}
                                                    height={30}
                                                    className={"min-width-remove"}
                                                    title={"Add Stat"}
                                                    color={COLORS.primary}
                                                    backgroundColor={"transparent"}
                                                    fontWeight={400}
                                                />
                                            )}
                                            {(user?.id == id || isCoach) && (
                                                <AppButton
                                                    width={80}
                                                    onClick={() => {
                                                        navigate(
                                                            ROSTER?.replace(":team", team?.id)?.replace(
                                                                ":organization",
                                                                team?.organization
                                                            )
                                                        )
                                                    }}
                                                    height={30}
                                                    className={"min-width-remove"}
                                                    title={"Roster"}
                                                    color={COLORS.primary}
                                                    backgroundColor={"transparent"}
                                                    fontWeight={400}
                                                />
                                            )}
                                            {
                                                !isCoach &&
                                                <AppButton width={50} height={30} className={'min-width-remove'} title={'Edit'} color={COLORS.primary} backgroundColor={'transparent'} fontWeight={400} onClick={() => handleEditTeam(team?.id)} />
                                            }
                                        </div>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
            }
            {
                teams?.count > 0 &&
                <TablePagination
                    rowsPerPageOptions={[5]}
                    component='div'
                    count={teams?.count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            }
            <Tryout
                visible={tryoutVisible}
                teamID={teamID}
                handleClose={() => {
                    handleChange("tryoutVisible", false)
                    handleChange("teamID", '')
                }}
            />
            <AddStats
                visible={statsVisible}
                teamID={teamID}
                organization
                handleClose={handleClose}
            />
            <PracticeSchedule
                visible={practiceScheduleVisible}
                teamID={teamID}
                handleClose={() => {
                    handleChange("practiceScheduleVisible", false)
                    handleChange("teamID", '')
                }}
            />
        </TableContainer>
    );
}