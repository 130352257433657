import { useContext } from "react"

import { useNavigate } from "react-router-dom"
import AppContext from "../Context"


export const UseLogout = () => {
    const navigate = useNavigate()
    const { resetState } = useContext(AppContext)
    
    const logout = () => {
        localStorage.removeItem("admintoken")
        localStorage.removeItem("token")
        localStorage.removeItem("user")
        resetState()
        navigate("/")
    }

    return logout
}

