// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useContext, useEffect, useRef, useState, useMemo } from "react"
import {
  HomeFooter,
  HomeHeader,
  AppButton,
  MainFooter,
  ContactUs,
  AppInput,
  AddStats,
  Label
} from "../../components"
import { Element } from "react-scroll"
import {
  Checkbox,
  Grid,
  Typography,
  TextField,
  Autocomplete as TextAutocomplete,
} from "@mui/material"
import AvatarIcon from "../../assets/images/avatar.png"
import { ReactComponent as Tiktok } from "../../assets/svg/social/Tiktok.svg"
import { ReactComponent as Facebook } from "../../assets/svg/social/Facebook.svg"
import { ReactComponent as Insta } from "../../assets/svg/social/Insta.svg"
import { ReactComponent as Twitter } from "../../assets/svg/social/Twitter.svg"
import { ReactComponent as Edit } from "../../assets/svg/Edit.svg"
import { ReactComponent as Camera } from "../../assets/svg/Camera.svg"
import { ReactComponent as Youtube } from "../../assets/svg/youtube.svg"
import { ReactComponent as Threads } from "../../assets/svg/threads.svg"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import AppContext from "../../Context"
import { useSnackbar } from "notistack"
import { getFormattedDate } from "../../utils/dateUtils"
import { COLORS, MAP_API_KEY } from "../../constants"
import AutoComplete from "react-google-autocomplete"
import {
  getTeams,
  searchUserProfile,
  updateUserProfile
} from "../../api/auth"
import { getError } from "../../utils/getError"
import EndSeason from "../../components/Season/EndSeason"
import { checkSportIsBasketball } from "../../utils/mixed"
import { validateURL } from "../../utils/ValidateEmail"

export default function PlayerProfileForm({ }) {
  const { enqueueSnackbar } = useSnackbar()
  const styles = theme => ({
    notchedOutline: {
      borderWidth: "1px",
      borderColor: "yellow !important"
    }
  })
  const guestRef = useRef(null)
  const navigate = useNavigate()
  const location = useLocation()
  const token = localStorage.getItem("token")
  const {
    grades,
    resetState,
    positionList,
    //_getPositions,
    _getProfile,
    userProfile,
    setUserProfile,
    motivations,
    _getMotivations,
    sports,
    schools,
    _getGrades,
    _getSchools,
    _getSports
  } = useContext(AppContext)
  const hiddenFileInput = useRef(null)
  const [filteredPositions, setFilteredPositions] = useState([[],[]]);

  const [state, setState] = useState({
    previousUsername: "",
    username: "",
    name: "",
    first_name: "",
    last_name: "",
    age: "",
    height_ft: "",
    height_in: "",
    weight: "",
    first_position_play: "",
    second_position_play: "",
    school: "",
    grade: "",
    date_of_graduation: "",
    phone_number: "",
    address: "",
    city: "",
    zip_code: "",
    selectedState: "",
    commited_to_team: "",
    committed_to_team_custom: "",
    previous_team: "",
    previous_team_custom: "",
    tiktok_username: "",
    display_twitter: false,
    facebook_username: "",
    preferred_jersey_number: "",
    display_facebook: false,
    instagram_username: "",
    display_instagram: false,
    twitter_username: "",
    threads_username: "",
    youtube_username: "",
    display_tiktok: false,
    display_threads: false,
    display_youtube: false,
    editMode: true,
    profileImg: null,
    statsVisible: false,
    endSeasonVisible: false,
    loading: false,
    teams: [],
    longitude: "",
    latitude: "",
    motivation: userProfile?.player?.motivation || "",
    sport: "",
    school_custom: "",
    limit: 20,
    offset: 0,
    userList: [],
    committed_to_organization_custom: "",
    committed_to_organization: null,
    previous_organization_custom: "",
    previous_organization: null,
    searchText: "",
    my_highlights: '',
    isURLValid: false,
    coachList: [],
    coach_user_id: '',
    my_trainer_custom: '',
    my_trainer: '',
    player_sports: [],
  })

  const {
    previousUsername,
    username,
    first_name,
    last_name,
    age,
    height_ft,
    height_in,
    weight,
    grade,
    first_position_play,
    second_position_play,
    date_of_graduation,
    preferred_jersey_number,
    phone_number,
    street_address,
    city,
    zip_code,
    selectedState,
    commited_to_team,
    committed_to_team_custom,
    previous_team,
    previous_team_custom,
    tiktok_username,
    display_twitter,
    facebook_username,
    display_facebook,
    instagram_username,
    display_instagram,
    twitter_username,
    threads_username,
    youtube_username,
    display_tiktok,
    display_threads,
    display_youtube,
    editMode,
    profileImg,
    statsVisible,
    endSeasonVisible,
    profileObj,
    loading,
    teams,
    longitude,
    latitude,
    sport,
    motivation,
    school,
    school_custom,
    limit,
    offset,
    userList,
    committed_to_organization_custom,
    committed_to_organization,
    previous_organization_custom,
    previous_organization,
    searchText,
    my_highlights,
    isURLValid,
    coachList,
    coach_user_id,
    my_trainer_custom,
    my_trainer,
    player_sports,
  } = state

  useEffect(() => {
    _getProfile()
    getData()
    _getMotivations()
    _getGrades()
    _getSports()
    if (location?.pathname === "/player-profile/add") {
      handleChange("editMode", true)
    }
  }, [location])
  useEffect(() => {

    if (!!sport) {
      // _getPositions(`?sport=${sport}`)
    }
  }, [sport])
  const getData = async () => {
    try {
      handleChange("loading", true)
      const res = await getTeams(token)
      handleChange("teams", res?.data)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const _isURLValid = (url) => {
    if (url) {
      const isValid = validateURL(url)
      if (!isValid) {
        handleChange("isURLValid", true)
      } else {
        handleChange("isURLValid", false)
      }
    }
  }

  useEffect(() => {
    if (userProfile) {
      handleChange("previousUsername", userProfile?.username)
      handleChange("username", userProfile?.username)
      handleChange("first_name", userProfile?.first_name)
      handleChange("last_name", userProfile?.last_name)
      handleChange("name", userProfile?.name)
      handleChange("height_ft", userProfile?.player?.height_ft?.toString())
      handleChange("height_in", userProfile?.player?.height_in?.toString())
      handleChange(
        "first_position_play",
        userProfile?.player?.first_position_play?.id
      )
      handleChange(
        "second_position_play",
        userProfile?.player?.second_position_play?.id
      )
      handleChange("age", userProfile?.player?.age?.toString())
      handleChange(
        "date_of_graduation",
        userProfile?.player?.date_of_graduation
      )
      userProfile?.player?.my_trainer?.id && handleChange(
        "my_trainer",
        userProfile?.player?.my_trainer?.id
      )
      handleChange(
        "my_trainer_custom",
        userProfile?.player?.my_trainer?.user?.name || userProfile?.player?.my_trainer?.name ||
        userProfile?.player?.my_trainer_custom || ''
      )
      handleChange(
        "commited_to_team",
        userProfile?.player?.commited_to_team?.id
      )
      handleChange("motivation", userProfile?.player?.motivation?.id || null)
      handleChange("sport", userProfile?.player?.sport?.id)
      // !!userProfile?.player?.sport?.id &&
      //  _getPositions(`?sport=${userProfile?.player?.sport?.id}`)
      handleChange(
        "committed_to_team_custom",
        userProfile?.player?.commited_to_team?.team_name ||
        userProfile?.player?.committed_to_team_custom
      )
      handleChange("school", userProfile?.player?.school?.id)
      handleChange(
        "school_custom",
        !!userProfile?.player?.school
          ? `${userProfile?.player?.school?.name}, ${userProfile?.player?.school?.city}, ${userProfile?.player?.school?.state}`
          : userProfile?.player?.school_custom
      )
      handleChange("previous_team", userProfile?.player?.previous_team)
      handleChange(
        "previous_team_custom",
        userProfile?.player?.previous_team_custom
      )

      userProfile?.player?.previous_organization?.id && handleChange("previous_organization", userProfile?.player?.previous_organization?.id)
      handleChange(
        "previous_organization_custom",
        userProfile?.player?.previous_organization?.organization_name || userProfile?.player?.previous_organization_custom
      )
      if (userProfile?.player?.organization_data?.id) {
        handleChange("committed_to_organization", {
          value: userProfile?.player?.organization_data?.id,
          title: userProfile?.player?.organization_data?.organization_name
        })
      }
      handleChange(
        "committed_to_organization_custom",
        !!userProfile?.player?.committed_to_organization_custom ? userProfile?.player?.committed_to_organization_custom : userProfile?.player?.organization_data?.organization_name || ''
      )
      // _getProfileDropdown(userProfile?.player?.organization_data?.organization_name || userProfile?.player?.committed_to_organization_custom)
      if (userProfile?.player?.organization_data?.id) {
        handleChange("userList", [
          {
            value: userProfile?.player?.organization_data?.id,
            title: userProfile?.player?.organization_data?.organization_name
          }
        ])
      }
      handleChange("tiktok_username", userProfile?.player?.tiktok_username)
      handleChange("threads_username", userProfile?.player?.threads_username)
      handleChange("youtube_username", userProfile?.player?.youtube_username)
      handleChange(
        "instagram_username",
        userProfile?.player?.instagram_username
      )
      handleChange("twitter_username", userProfile?.player?.twitter_username)
      handleChange("facebook_username", userProfile?.player?.facebook_username)
      handleChange("display_twitter", userProfile?.player?.display_twitter)
      handleChange("display_threads", userProfile?.player?.display_threads)
      handleChange("display_youtube", userProfile?.player?.display_youtube)
      handleChange("display_instagram", userProfile?.player?.display_instagram)
      handleChange("display_facebook", userProfile?.player?.display_facebook)
      handleChange("display_tiktok", userProfile?.player?.display_tiktok)
      handleChange("grade", userProfile?.player?.grade?.id || "")
      handleChange(
        "preferred_jersey_number",
        userProfile?.player?.preferred_jersey_number?.toString()
      )
      handleChange("weight", userProfile?.player?.weight?.toString())
      handleChange("my_highlights", userProfile?.player?.my_highlights)
      handleChange("phone_number", userProfile?.phone_number)
      handleChange("email", userProfile?.email)
      handleChange("city", userProfile?.city)
      handleChange("street_address", userProfile?.street_address)
      handleChange("latitude", userProfile?.latitude)
      handleChange("longitude", userProfile?.longitude)
      handleChange("zip_code", userProfile?.zip_code)
      handleChange("selectedState", userProfile?.state)
      handleChange('player_sports', userProfile.player_sports || []);

      // Handle player sports data
      if (userProfile?.player?.player_sports) {
        // Sort sports array to ensure primary sport is first
        const sortedSports = [...userProfile.player.player_sports].sort((a, b) => {
            if (a.is_primary) return -1;  // Move primary sport to front
            if (b.is_primary) return 1;   // Move primary sport to front
            return 0;  // Keep relative order if neither is primary
        });

        const formattedPlayerSports = sortedSports.map((sport, index) => ({
            id: sport.id,
            sport: sport.sport?.id,
            is_primary: index === 0, // First sport becomes primary
            first_position_play: sport.first_position_play?.id,
            second_position_play: sport.second_position_play?.id,
            committed_to_organization: sport.committed_to_organization?.id,
            committed_to_organization_custom: sport.committed_to_organization_custom || '',
            previous_organization: sport.previous_organization?.id,
            previous_organization_custom: sport.previous_organization_custom || '',
            preferred_jersey_number: sport.preferred_jersey_number,
            my_highlights: sport.my_highlights || '',
            my_trainer: sport.my_trainer?.id,
            my_trainer_custom: sport.my_trainer_custom || ''
        }));
        
        handleChange('player_sports', formattedPlayerSports);
        sortedSports.map((sport, index) => {
          filteredPositions[index] = filterPositionsBySport(sport.sport?.id)
        setFilteredPositions(filteredPositions);
          // handleSportChange(index, "sport", sport.sport?.id)
        })
      }

      // Find grade ID that matches the name from the profile
      if (userProfile?.player?.grade?.name) {
        const gradeId = grades?.find(
          grade => grade.name === userProfile.player.grade.name
        )?.id;
        if (gradeId) {
          handleChange("grade", gradeId);
        }
      }
    }
  }, [userProfile])

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const onFileChange = event => {
    handleChange("profileImg", URL.createObjectURL(event.target.files[0]))
    handleChange("profileObj", event.target.files[0])
  }

  const handleClick = () => {
    hiddenFileInput.current.click()
  }
  const handleSubmit = async () => {
    try {
      // Check for duplicate sports
      const sportIds = player_sports.map(sport => sport.sport);
      const hasDuplicateSports = sportIds.length !== new Set(sportIds).size;
      
      if (hasDuplicateSports) {
        enqueueSnackbar("Each sport can only be selected once. Please select different sports.", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          }
        });
        handleChange("loading", false);
        return;
      }

      // Check if Sport 2 exists but has no sport selected
      if (player_sports.length > 1 && !player_sports[1].sport) {
        enqueueSnackbar("Please select a sport for Sport 2", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          }
        });
        handleChange("loading", false);
        return;
      }

      handleChange("loading", true)
      const payload = {
        ...(username !== previousUsername && { username }),
        first_name,
        last_name,
        name: first_name + " " + last_name,
        player: {
          ...(height_ft && { height_ft }),
          ...(height_in && { height_in }),
          ...(my_highlights && { my_highlights }),
          ...(first_position_play && { first_position_play }),
          ...(weight && { weight }),
          ...(grade && { grade }),
          ...(second_position_play && { second_position_play }),
          ...(age && { age }),
          ...(date_of_graduation && { date_of_graduation }),
          ...(school && { school }),
          ...(!school && school_custom && { school_custom }),
          ...(my_trainer && { my_trainer }),
          ...(!my_trainer && my_trainer_custom && { my_trainer_custom }),
          ...(commited_to_team && { commited_to_team }),
          ...(!commited_to_team && committed_to_team_custom && { committed_to_team_custom }),
          ...(previous_team && { previous_team }),
          ...(previous_team_custom && { previous_team_custom }),
          ...(tiktok_username && { tiktok_username }),
          ...(instagram_username && { instagram_username }),
          ...(twitter_username && { twitter_username }),
          ...(threads_username && { threads_username }),
          ...(youtube_username && { youtube_username }),
          ...(committed_to_organization 
            ? { committed_to_organization }
            : { committed_to_organization_custom: committed_to_organization_custom || "" }
          ),
          ...(previous_organization
            ? { previous_organization }
            : { previous_organization_custom: previous_organization_custom || "" }
          ),
          ...(facebook_username && { facebook_username }),
          display_twitter,
          display_instagram,
          display_facebook,
          display_tiktok,
          display_threads,
          display_youtube,
          ...(motivation && { motivation }),
          ...(sport && { sport }),
          ...(preferred_jersey_number && { preferred_jersey_number }),
        },
        ...(phone_number && { phone_number }),
        ...(street_address && { street_address }),
        ...(latitude && { latitude }),
        ...(longitude && { longitude }),
        ...(city && { city }),
        ...(zip_code && { zip_code }),
        ...(selectedState && { state: selectedState }),
        ...(player_sports && {
          player_sports: player_sports.map(sport => {
            const cleanedSport = {};
            
            // Convert sport ID to number using parseInt or Number
            if (sport.sport) cleanedSport.sport = typeof sport.sport === 'string' ? parseInt(sport.sport) : sport.sport;
            if (sport.is_primary !== undefined) cleanedSport.is_primary = sport.is_primary;
            if (sport.first_position_play) cleanedSport.first_position_play = parseInt(sport.first_position_play);
            if (sport.second_position_play) cleanedSport.second_position_play = parseInt(sport.second_position_play);
            if (sport.committed_to_organization) cleanedSport.committed_to_organization = parseInt(sport.committed_to_organization);
            if (sport.committed_to_organization_custom && sport.committed_to_organization_custom.trim() !== '') {
              cleanedSport.committed_to_organization_custom = sport.committed_to_organization_custom;
            }
            if (sport.previous_organization) cleanedSport.previous_organization = parseInt(sport.previous_organization);
            if (sport.previous_organization_custom && sport.previous_organization_custom.trim() !== '') {
              cleanedSport.previous_organization_custom = sport.previous_organization_custom;
            }
            if (sport.preferred_jersey_number) cleanedSport.preferred_jersey_number = parseInt(sport.preferred_jersey_number);
            if (sport.my_highlights && sport.my_highlights.trim() !== '') cleanedSport.my_highlights = sport.my_highlights;
            if (sport.my_trainer) cleanedSport.my_trainer = parseInt(sport.my_trainer);
            if (sport.my_trainer_custom && sport.my_trainer_custom.trim() !== '') {
              cleanedSport.my_trainer_custom = sport.my_trainer_custom;
            }
            
            return cleanedSport;
          })
        }),
      };

      // If you still need to send files, you'll need to handle them separately
      // or use a different endpoint for file uploads

      if (username == null || username == "") {
        alert("User Name is required")
        handleChange("loading", false)
        handleChange("username", userProfile?.username)
      } else if (first_name == "") {
        alert("First Name is required")
        handleChange("loading", false)
        handleChange("first_name", userProfile?.player?.user?.first_name)
      } else if (last_name == "") {
        alert("Last Name is required")
        handleChange("loading", false)
        handleChange("last_name", userProfile?.player?.user?.last_name)
      } else {
        if (userProfile?.id) {
          const res = await updateUserProfile(userProfile?.id, payload, token)
          setUserProfile(res?.data)
          _getProfile(userProfile?.id)
          handleChange("loading", false)
          handleChange("editMode", false)
          navigate(
            location?.pathname === "/player-profile/edit"
              ? `/player-profile/${userProfile?.id}`
              : "/dashboard"
          )
          enqueueSnackbar(
            `Player profile has been ${location?.pathname === "/player-profile/add"
              ? "created"
              : "updated"
            }`,
            {
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right"
              }
            }
          )
        } else {
          logout()
        }
      }
    } catch (error) {
      handleChange("loading", false)
      // enqueueSnackbar(getError(error), {
      //   variant: "error",
      //   anchorOrigin: {
      //     vertical: "bottom",
      //     horizontal: "right"
      //   }
      // })
      enqueueSnackbar(
        error?.response?.data?.username[0]
          ? error?.response?.data?.username[0]
          : getError(error),
        {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          }
        }
      )
    }
  }

  const _getAllCoaches = async (payload) => {
    try {
      const qs = payload ?? ''
      const res = await searchUserProfile(qs, token)
      handleChange("coachList", [...(res?.data?.results ?? [])])
    } catch (error) {
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const logout = () => {
    localStorage.removeItem("token")
    localStorage.removeItem("user")
    resetState()
    navigate("/")
  }

  const handleSearch = async place => {
    try {
      const res = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${place?.geometry.location?.lat()},${place?.geometry.location?.lng()}&key=${MAP_API_KEY}`
      )
      const res1 = await res.json()
      const address =
        res1?.results?.length > 0 && res1?.results[0]?.formatted_address
      var address_components = res1?.results[0]?.address_components
      let dState = ""
      let country = ""
      let city = ""
      let postal_code = ""
      if (address_components !== undefined) {
        const addrComp = address_components
        for (let i = 0; i < addrComp?.length; ++i) {
          var typ = addrComp[i]?.types[0]
          if (typ === "administrative_area_level_1") {
            dState = addrComp[i]?.long_name
          } else if (typ === "locality") {
            city = addrComp[i]?.long_name
          } else if (typ === "country") {
            country = addrComp[i]?.long_name
          } else if (typ === "postal_code") {
            postal_code = addrComp[i]?.short_name
          }
        }
      }
      handleChange("latitude", place?.geometry.location?.lat())
      handleChange("longitude", place?.geometry.location?.lng())
      handleChange("street_address", address)
      handleChange("city", city)
      handleChange("country", country)
      handleChange("selectedState", dState)
      handleChange("zip_code", postal_code)
    } catch (error) {
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const stats = [
    {
      key: "Point Scored",
      value: userProfile?.player?.player_stats?.total_points
    },
    { key: "Assists", value: userProfile?.player?.player_stats?.total_assists },
    {
      key: "Rebounds",
      value: userProfile?.player?.player_stats?.total_rebounds
    },
    { key: "Steals", value: userProfile?.player?.player_stats?.total_steals },
    { key: "Blocks", value: userProfile?.player?.player_stats?.total_blocks },
    {
      key: "FGM",
      value: userProfile?.player?.player_stats?.total_field_goals_made
    },
    {
      key: "FGA",
      value: userProfile?.player?.player_stats?.total_field_goals_attempted
    },
    {
      key: "3P FGM",
      value:
        userProfile?.player?.player_stats?.total_three_point_field_goals_made
    },
    {
      key: "3P FGA",
      value:
        userProfile?.player?.player_stats
          ?.total_three_point_field_goals_attempted
    },
    {
      key: "FTM",
      value: userProfile?.player?.player_stats?.total_free_throws_made
    },
    {
      key: "FTA",
      value: userProfile?.player?.player_stats?.total_free_throws_attempted
    }
  ]
  const list = positionList?.map((item, index) => (
    <option key={index} value={item?.id}>
      {item?.name}
    </option>
  ))
  const list1 = grades?.map((item, index) => (
    <option key={index} value={item?.name}>
      {item?.name}
    </option>
  ))
  const listMotivation = motivations?.map((item, index) => (
    <option key={index} value={item?.id}>
      {item?.context}
    </option>
  ))
  const listSports = sports?.map((item, index) => (
    <option key={index} value={item?.id}>
      {item?.name}
    </option>
  ))
  const option1 = <option value={""}>Select Position</option>
  const option2 = <option value={""}>Select Grade</option>
  const option3 = <option value={""}>Select Motivation</option>
  const option4 = <option value={""}>Select Sport</option>

  const createPositionOptions = (positions) => [
    <option key="default" value="">Select Position</option>,
    ...positions.map((item, index) => (
      <option key={index} value={item?.id}>
        {item?.name}
      </option>
    ))
  ];

  const positionPlayOptions = filteredPositions.map(createPositionOptions);

  const gradesPlayOptions = [option2, ...list1]
  const motivationOptions = [option3, ...listMotivation]
  const sportsOptions = [
    <option key="default" value="">Select Sport</option>,
    ...sports.map(sport => (
      <option key={sport.id} value={sport.id}>
        {sport.name}
      </option>
    ))
  ];

  const _getProfileDropdown = async searchText => {
    try {
      handleChange("searchText", searchText)
      const token = localStorage.getItem("token")
      if (searchText) {
        const res = await searchUserProfile(
          `?search=${searchText}&role=Organization`,
          token
        )
        handleChange(
          "userList",
          res?.data?.results?.map(p => {
            return {
              title: p?.organization?.organization_name,
              value: p?.organization?.id,
              ...p
            }
          })
        )
      }
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const onInputChange = (event, value, reason) => {
    handleChange("committed_to_organization_custom", value)
    handleChange("committed_to_organization", null)
    if (value) {
      _getProfileDropdown(value)
    } else {
      //  setOptions([])
    }
  }
  const onInputChangePre = (event, value, reason) => {
    handleChange("previous_organization_custom", value)
    handleChange("previous_organization", null)
    if (value) {
      _getProfileDropdown(value)
    } else {
      //  setOptions([])
    }
  }

  // console.log('committed_to_organization', userList, committed_to_organization);

  const handleAddSport = () => {
    if (player_sports.length < 2) {
        const newSports = [...player_sports, {
            sport: null,
            is_primary: false,  // Always false for added sports
            first_position_play: null,
            second_position_play: null,
            committed_to_organization: null,
            committed_to_organization_custom: '',
            previous_organization: null,
            previous_organization_custom: '',
            preferred_jersey_number: null,
            my_highlights: '',
            my_trainer: null,
            my_trainer_custom: ''
        }];
        handleChange('player_sports', newSports);
    }
  };

  const handleRemoveSport = (index) => {
    const newSports = [...player_sports];
    newSports.splice(index, 1);
    handleChange('player_sports', newSports);
  };

  // Function to filter positions based on selected sport
  const filterPositionsBySport = (sportId) => {
    // Assuming positionList contains a sportId field to filter by

    return positionList.filter(position => String(position.sport) === sportId ||position.sport === sportId);
  };

  const handleSportChange = (index, field, value) => {
    const newSports = [...player_sports];
    
    if (field === 'sport') {
        // Check if this sport is already selected in another slot
        const isDuplicate = player_sports.some((sport, idx) => 
            idx !== index && sport.sport === value
        );

        if (isDuplicate) {
            enqueueSnackbar("This sport is already selected. Please choose a different sport.", {
                variant: "warning",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                }
            });
            return; // Don't update if it's a duplicate
        }

        // Filter positions based on the selected sport

        // const positions = filterPositionsBySport(value);
        filteredPositions[index] = filterPositionsBySport(value)
        setFilteredPositions(filteredPositions);

        // Look for existing data for this sport in userProfile
        const existingSportData = userProfile?.player?.player_sports?.find(
            sport => sport.sport?.id === parseInt(value)
        );

        if (existingSportData) {
            // If found, use existing data
            newSports[index] = {
                id: existingSportData.id,
                sport: existingSportData.sport?.id,
                is_primary: index === 0, // Maintain primary status based on position
                first_position_play: existingSportData.first_position_play?.id,
                second_position_play: existingSportData.second_position_play?.id,
                committed_to_organization: existingSportData.committed_to_organization?.id,
                committed_to_organization_custom: existingSportData.committed_to_organization_custom || '',
                previous_organization: existingSportData.previous_organization?.id,
                previous_organization_custom: existingSportData.previous_organization_custom || '',
                preferred_jersey_number: existingSportData.preferred_jersey_number,
                my_highlights: existingSportData.my_highlights || '',
                my_trainer: existingSportData.my_trainer?.id,
                my_trainer_custom: existingSportData.my_trainer_custom || ''
            };
        } else {
            // If not found, reset to defaults
            newSports[index] = {
                sport: value,
                is_primary: index === 0,
                first_position_play: null,
                second_position_play: null,
                committed_to_organization: null,
                committed_to_organization_custom: '',
                previous_organization: null,
                previous_organization_custom: '',
                preferred_jersey_number: null,
                my_highlights: '',
                my_trainer: null,
                my_trainer_custom: ''
            };
        }
    } else {
        // For other fields, just update the specific field
        newSports[index] = {
            ...newSports[index],
            [field]: value
        };
    }
    
    handleChange('player_sports', newSports);
  };

  return (
    <div className="">
      <HomeHeader dashboard />
      <section className="container pb-3 mb-0 bg-transparent">
        <Grid className="mb-3" container justifyContent={"space-between"}>
          <div className="pageHeading">Profile</div>
          {!editMode && (
            <AppButton
              width={35}
              height={35}
              borderRadius={35}
              onClick={() => handleChange("editMode", true)}
              backgroundColor={COLORS.primary}
              className={"min-width-remove"}
              title={<Edit />}
            />
          )}
        </Grid>
        <Grid
          className="mb-3 mt-4"
          container
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <div className="">
            <img
              src={profileImg || userProfile?.player?.picture || AvatarIcon}
              className={"c-pointer profileIMG"}
            />
            <input
              ref={hiddenFileInput}
              type="file"
              accept="image/*"
              onChange={onFileChange}
              style={{ display: "none" }}
            />
            {editMode && (
              <AppButton
                title={<Camera />}
                onClick={handleClick}
                backgroundColor={"#FAFAFA"}
                borderColor={"#0000000F"}
                width={30}
                borderRadius={30}
                height={30}
                className={"min-width-remove camIcon"}
              />
            )}
          </div>
          <div>
            <AppButton
              width={100}
              height={35}
              onClick={() => handleChange("endSeasonVisible", true)}
              backgroundColor={"#ffffff"}
              fontWeight={300}
              color={"#222"}
              className={"min-width-remove"}
              title={"End Season"}
              borderColor={"#d9d9d9"}
              className="mr-2"
            />
            <AppButton
              width={!checkSportIsBasketball(sports, sport) ? 150 : 130}
              height={40}
              onClick={() => handleChange("statsVisible", true)}
              backgroundColor={"#14B46A"}
              fontWeight={300}
              disabled={!checkSportIsBasketball(sports, sport)}
              color={COLORS.white}
              className={"min-width-remove"}
              title={
                !checkSportIsBasketball(sports, sport)
                  ? "Stats Coming Soon"
                  : "Add Game Stats"
              }
            />
          </div>
        </Grid>
        {!editMode && (
          <div className="statsProfileDiv">
            <div className="container">
              <Grid container justifyContent={"space-between"}>
                {stats?.map((item, index) => (
                  <Grid
                    key={index}
                    style={{
                      borderRight:
                        stats?.length - 1 === index
                          ? "none"
                          : "1px solid #0000000F"
                    }}
                    md={index === 0 ? 1.5 : 1}
                    xs={3}
                    className="statsValueProfileDiv"
                  >
                    <div className="wp-label">{item?.key}</div>
                    <div className="wp-value">{item?.value}</div>
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        )}
        <Label className={"mt-5"} text={"I'm motivated by"} />
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <AppInput
              disabled={!editMode}
              label={"I'm motivated by"}
              select
              selectOptions={[
                { value: '', label: 'Select Motivation' },
                ...(motivations?.map(item => ({
                  value: item.id,
                  label: item.context
                })) || [])
              ].map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
              height={40}
              onChange={handleChange}
              value={motivation}
              name={"motivation"}
            />
          </Grid>
        </Grid>
        <Label className={"mt-5"} text={"Personal Info"} />
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <AppInput
              disabled={!editMode}
              label={"User Name"}
              height={40}
              onChange={handleChange}
              value={username}
              name={"username"}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <AppInput
              disabled={!editMode}
              label={"First Name"}
              height={40}
              onChange={handleChange}
              value={first_name}
              name={"first_name"}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <AppInput
              disabled={!editMode}
              label={"Last Name"}
              height={40}
              onChange={handleChange}
              value={last_name}
              name={"last_name"}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <AppInput
              disabled={!editMode}
              label={"Age"}
              height={40}
              onChange={handleChange}
              value={age}
              name={"age"}
            />
          </Grid>
        </Grid>
        <Label className={"mt-5"} text={"Player Info"} />
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <AppInput
              disabled={!editMode}
              label={"Height (Ft)"}
              placeholder={"Ft"}
              height={40}
              onChange={handleChange}
              value={height_ft}
              name={"height_ft"}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <AppInput
              disabled={!editMode}
              label={"(In)"}
              placeholder={"In"}
              height={40}
              onChange={handleChange}
              value={height_in}
              name={"height_in"}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AppInput
              disabled={!editMode}
              label={"Weight (lbs)"}
              height={40}
              onChange={handleChange}
              value={weight}
              name={"weight"}
            />
          </Grid>
        </Grid>
        <Label className={"mt-5"} text={"Sports"} />
        {player_sports?.map((playerSport, index) => {
          return (
            <Grid container spacing={3} key={index} className="mb-5">
              <Grid item xs={12}>
                <Typography variant="h6">{`Sport ${index + 1}`}</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <AppInput
                  disabled={!editMode}
                  label="Sport"
                  select
                  selectOptions={sportsOptions}
                  height={40}
                  onChange={(name, value, e) => {
                    e?.preventDefault?.(); // Prevent form submission using passed event
                    handleSportChange(index, 'sport', value);
                  }}
                  value={playerSport.sport || ''}
                  name={`sport_${index}`}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <AppInput
                  disabled={!editMode}
                  label="First position play"
                  select
                  selectOptions={positionPlayOptions[index]}
                  height={40}
                  onChange={(name, value) => handleSportChange(index, 'first_position_play', value)}
                  value={playerSport.first_position_play || ''}
                  name={`first_position_play_${index}`}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <AppInput
                  disabled={!editMode}
                  label="Second position play"
                  select
                  selectOptions={positionPlayOptions[index]}
                  height={40}
                  onChange={(name, value) => handleSportChange(index, 'second_position_play', value)}
                  value={playerSport.second_position_play || ''}
                  name={`second_position_play_${index}`}
                />
              </Grid>
              
              <Grid item xs={12} md={4}>
                <Label text="Committed to (Organization)" fontNormal />
                <TextAutocomplete
                  freeSolo
                  fullWidth
                  id={`committed-org-${index}`}
                  options={userList?.map(option => `${option?.title}`)}
                  onInputChange={(event, value) => {
                    if (value) {
                      _getProfileDropdown(value)
                    }
                    handleSportChange(index, 'committed_to_organization_custom', value)
                    handleSportChange(index, 'committed_to_organization', null)
                  }}
                  inputValue={playerSport.committed_to_organization_custom || ''}
                  value={playerSport.committed_to_organization_custom || ''}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      style={{ borderRadius: 10 }}
                      onChange={(e) => {
                        handleSportChange(index, 'committed_to_organization_custom', e.target.value)
                      }}
                    />
                  )}
                  onChange={(e, newValue) => {
                    handleSportChange(index, 'committed_to_organization', 
                      userList[e?.target?.dataset?.optionIndex]?.organization?.id)
                    handleSportChange(index, 'committed_to_organization_custom', newValue)
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Label text="Previous Organization" fontNormal />
                <TextAutocomplete
                  freeSolo
                  fullWidth
                  id={`previous-org-${index}`}
                  options={userList?.map(option => `${option?.title}`)}
                  onInputChange={(event, value) => {
                    if (value) {
                      _getProfileDropdown(value)
                    }
                    handleSportChange(index, 'previous_organization_custom', value)
                    handleSportChange(index, 'previous_organization', null)
                  }}
                  inputValue={playerSport.previous_organization_custom || ''}
                  value={playerSport.previous_organization_custom || ''}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      style={{ borderRadius: 10 }}
                      onChange={(e) => {
                        handleSportChange(index, 'previous_organization_custom', e.target.value)
                      }}
                    />
                  )}
                  onChange={(e, newValue) => {
                    handleSportChange(index, 'previous_organization',
                      userList[e?.target?.dataset?.optionIndex]?.organization?.id)
                    handleSportChange(index, 'previous_organization_custom', newValue)
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <AppInput
                  disabled={!editMode}
                  label="Preferred Jersey Number"
                  height={40}
                  onChange={(name, value) => handleSportChange(index, 'preferred_jersey_number', value)}
                  value={playerSport.preferred_jersey_number || ''}
                  name={`preferred_jersey_number_${index}`}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <AppInput
                  disabled={!editMode}
                  label="My Highlights"
                  height={40}
                  onChange={(name, value) => handleSportChange(index, 'my_highlights', value)}
                  value={playerSport.my_highlights || ''}
                  name={`my_highlights_${index}`}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Label text="My Trainer / Development Coach" fontNormal />
                <TextAutocomplete
                  freeSolo
                  fullWidth
                  id={`trainer-${index}`}
                  options={coachList?.map(
                    option => `${option?.name || option?.organization?.organization_name}`
                  )}
                  onInputChange={(event, value) => {
                    if (value?.length > 2) {
                      const payload = `?role=Coach,Organization&search=${value}`
                      _getAllCoaches(payload)
                    }
                    handleSportChange(index, 'my_trainer_custom', value)
                    handleSportChange(index, 'my_trainer', null)
                  }}
                  inputValue={playerSport.my_trainer_custom || ''}
                  value={playerSport.my_trainer_custom || ''}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      style={{ borderRadius: 10 }}
                      onChange={(e) => {
                        handleSportChange(index, 'my_trainer_custom', e.target.value)
                      }}
                    />
                  )}
                  onChange={(e, newValue) => {
                    handleSportChange(index, 'my_trainer',
                      coachList[e?.target?.dataset?.optionIndex]?.id)
                    handleSportChange(index, 'my_trainer_custom', newValue)
                  }}
                />
              </Grid>

              {editMode && index > 0 && (
                <Grid item xs={12} container justifyContent="flex-end">
                  <AppButton
                    title="Remove Sport"
                    onClick={() => handleRemoveSport(index)}
                    backgroundColor="#ff4444"
                    color="#fff"
                  />
                </Grid>
              )}
            </Grid>
          );
        })}
        {editMode && player_sports?.length < 2 && (
          <AppButton
            title="Add Sport"
            onClick={handleAddSport}
            backgroundColor={COLORS.primary}
            color="#fff"
          />
        )}
        <Label className={"mt-5"} text={"Education"} />
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Label text={"School"} fontNormal />
            <TextAutocomplete
              freeSolo
              id="free-solo-2-demo"
              disableClearable
              value={school_custom}
              onChange={(event, newValue) => {
                handleChange("school_custom", newValue)
                handleChange(
                  "school",
                  schools[event?.target?.dataset?.optionIndex]?.id
                )
              }}
              inputValue={school_custom}
              onInputChange={(event, newInputValue) => {
                if (newInputValue?.length > 2) {
                  const payload = `?name=${newInputValue}&l=${limit}&o=${offset}`
                  _getSchools(payload)
                }
                handleChange("school_custom", newInputValue)
                handleChange("school", "")
              }}
              options={schools?.map(
                option => `${option?.name}, ${option?.city}, ${option?.state}`
              )}
              // getOptionLabel={(option) => option.team_name}
              label={false}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder="Search School"
                  size="small"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    "& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#D9D9D9",
                      borderWidth: 1
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#D9D9D9"
                    }
                  }}
                  label=""
                  InputLabelProps={{ shrink: false }}
                  InputProps={{
                    ...params.InputProps,
                    type: "search"
                  }}
                />
              )}
            />
          </Grid>
          {/* <Grid item xs={12} md={4}>
            <AppInput
              disabled={!editMode}
              label={"School"}
              height={40}
              onChange={handleChange}
              value={school}
              name={"school"}
            />
          </Grid> */}
          <Grid item xs={12} md={4}>
            <AppInput
              disabled={!editMode}
              label={"Year of Graduation"}
              type={"date"}
              height={40}
              onChange={handleChange}
              value={date_of_graduation}
              name={"Year of Graduation"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInput
              disabled={!editMode}
              label={"Grade"}
              select
              selectOptions={grades?.map((item, index) => (
                <option key={index} value={item?.id}>
                  {item?.name}
                </option>
              ))}
              height={40}
              onChange={handleChange}
              value={grade || ""}
              name={"grade"}
              min={1}
            />
          </Grid>
        </Grid>
        <Label className={"mt-5"} text={"Contact"} />
        <Grid container spacing={3}>
          <Grid item xs={12} md={2.4}>
            {editMode ? (
              <>
                <Label text={"Street Address"} fontNormal />
                <AutoComplete
                  apiKey={MAP_API_KEY}
                  className="locationSearch"
                  options={{ types: ["geocode", "establishment"] }}
                  style={{ height: 40, backgroundColor: "#fff" }}
                  onChange={e =>
                    handleChange("street_address", e?.target?.value)
                  }
                  defaultValue={street_address}
                  onPlaceSelected={place => {
                    handleSearch(place)
                  }}
                  placeholder=""
                />
              </>
            ) : (
              <AppInput
                disabled={true}
                label={"Street address"}
                height={40}
                onChange={handleChange}
                value={street_address}
                name={"street_address"}
              />
            )}
          </Grid>
          <Grid item xs={12} md={2.4}>
            <AppInput
              disabled={!editMode}
              label={"City"}
              height={40}
              onChange={handleChange}
              value={city}
              name={"city"}
            />
          </Grid>
          <Grid item xs={12} md={2.4}>
            <AppInput
              disabled={!editMode}
              label={"Zipcode"}
              height={40}
              onChange={handleChange}
              value={zip_code}
              name={"zip_code"}
            />
          </Grid>
          <Grid item xs={12} md={2.4}>
            <AppInput
              disabled={!editMode}
              label={"State"}
              height={40}
              onChange={handleChange}
              value={selectedState}
              name={"selectedState"}
            />
          </Grid>
          <Grid item xs={12} md={2.4}>
            <AppInput
              disabled={!editMode}
              label={"Phone number"}
              type={"number"}
              placeholder={"123-456-7890"}
              height={40}
              onChange={handleChange}
              value={phone_number}
              name={"phone_number"}
            />
          </Grid>
        </Grid>
        <Label className={"mt-5"} text={"Social Media"} />
        <Grid container sx={{ mt: 0.2 }} spacing={3}>
          <Grid item xs={12} md={4}>
            <AppInput
              disabled={!editMode}
              prefix={<Tiktok style={{ marginTop: 12 }} />}
              label={"Tiktok profile URL"}
              height={40}
              onChange={handleChange}
              value={tiktok_username}
              name={"tiktok_username"}
            />
            <div className="checkboxDiv mb-3">
              <Checkbox
                disabled={!editMode}
                defaultChecked={false}
                checked={display_tiktok}
                onClick={() => handleChange("display_tiktok", !display_tiktok)}
                className="checkbox1"
                style={{
                  marginTop: -3
                }}
                sx={{
                  color: "rgba(201, 208, 216, 1)",
                  "&.Mui-checked": {
                    color: COLORS.primary
                  }
                }}
              />
              <Typography variant="body2" className="greyColor checkboxLabel">
                Display on social media profile.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInput
              disabled={!editMode}
              prefix={<Facebook style={{ marginTop: 12 }} />}
              label={"Facebook profile URL"}
              height={40}
              onChange={handleChange}
              value={facebook_username}
              name={"facebook_username"}
            />
            <div className="checkboxDiv mb-3">
              <Checkbox
                defaultChecked={false}
                disabled={!editMode}
                checked={display_facebook}
                onClick={() =>
                  handleChange("display_facebook", !display_facebook)
                }
                className="checkbox1"
                style={{
                  marginTop: -3
                }}
                sx={{
                  color: "rgba(201, 208, 216, 1)",
                  "&.Mui-checked": {
                    color: COLORS.primary
                  }
                }}
              />
              <Typography variant="body2" className="greyColor checkboxLabel">
                Display on social media profile.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInput
              disabled={!editMode}
              prefix={<Insta style={{ marginTop: 12 }} />}
              label={"Instagram User name"}
              height={40}
              onChange={handleChange}
              value={instagram_username}
              name={"instagram_username"}
            />
            <div className="checkboxDiv mb-3">
              <Checkbox
                disabled={!editMode}
                checked={display_instagram}
                defaultChecked={false}
                onClick={() =>
                  handleChange("display_instagram", !display_instagram)
                }
                className="checkbox1"
                style={{
                  marginTop: -3
                }}
                sx={{
                  color: "rgba(201, 208, 216, 1)",
                  "&.Mui-checked": {
                    color: COLORS.primary
                  }
                }}
              />
              <Typography variant="body2" className="greyColor checkboxLabel">
                Display on social media profile.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInput
              disabled={!editMode}
              prefix={<Twitter style={{ marginTop: 12 }} />}
              label={"Twitter profile URL"}
              height={40}
              onChange={handleChange}
              value={twitter_username}
              name={"twitter_username"}
            />
            <div className="checkboxDiv mb-3">
              <Checkbox
                disabled={!editMode}
                defaultChecked={false}
                checked={display_twitter}
                onClick={() =>
                  handleChange("display_twitter", !display_twitter)
                }
                className="checkbox1"
                style={{
                  marginTop: -3
                }}
                sx={{
                  color: "rgba(201, 208, 216, 1)",
                  "&.Mui-checked": {
                    color: COLORS.primary
                  }
                }}
              />
              <Typography variant="body2" className="greyColor checkboxLabel">
                Display on social media profile.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInput
              disabled={!editMode}
              prefix={<Youtube style={{ marginTop: 12 }} />}
              label={"Youtube profile URL"}
              height={40}
              onChange={handleChange}
              value={youtube_username}
              name={"youtube_username"}
            />
            <div className="checkboxDiv mb-3">
              <Checkbox
                disabled={!editMode}
                defaultChecked={false}
                checked={display_youtube}
                onClick={() =>
                  handleChange("display_youtube", !display_youtube)
                }
                className="checkbox1"
                style={{
                  marginTop: -3
                }}
                sx={{
                  color: "rgba(201, 208, 216, 1)",
                  "&.Mui-checked": {
                    color: COLORS.primary
                  }
                }}
              />
              <Typography variant="body2" className="greyColor checkboxLabel">
                Display on social media profile.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInput
              disabled={!editMode}
              prefix={<Threads style={{ marginTop: 12 }} />}
              label={"Threads profile URL"}
              height={40}
              onChange={handleChange}
              value={threads_username}
              name={"threads_username"}
            />
            <div className="checkboxDiv mb-3">
              <Checkbox
                disabled={!editMode}
                defaultChecked={false}
                checked={display_threads}
                onClick={() =>
                  handleChange("display_threads", !display_threads)
                }
                className="checkbox1"
                style={{
                  marginTop: -3
                }}
                sx={{
                  color: "rgba(201, 208, 216, 1)",
                  "&.Mui-checked": {
                    color: COLORS.primary
                  }
                }}
              />
              <Typography variant="body2" className="greyColor checkboxLabel">
                Display on social media profile.
              </Typography>
            </div>
          </Grid>
        </Grid>
        {editMode && (
          <Grid container sx={{ mt: 3 }}>
            <AppButton
              onClick={() => {
                navigate(-1)
                handleChange("editMode", false)
              }}
              title={"Cancel"}
              className={"mr-4"}
              color={"#000000D9"}
              fontWeight={300}
              borderColor={"#D9D9D9"}
              width={100}
            />
            <AppButton
              title={"Save"}
              color={COLORS.white}
              fontWeight={300}
              backgroundColor={COLORS.primary}
              width={100}
              onClick={handleSubmit}
              loading={loading}
            />
          </Grid>
        )}
      </section>
      <AddStats
        visible={statsVisible}
        handleClose={() => handleChange("statsVisible", false)}
      />

      <EndSeason
        visible={endSeasonVisible}
        organization
        handleClose={() => handleChange("endSeasonVisible", false)}
        type="Player"
        id={userProfile?.player?.id}
      />
    </div>
  )
}
