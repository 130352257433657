import React, { useEffect } from 'react';
import {QuantityInput, ZeroToOneInput} from '../AppInput/NumberInput';
import {Grid} from '@mui/material';
import {AppInput} from "../index";

const GolfStatsInputs = ({
    handleChange, 
    par_for_the_hole, 
    score_for_the_hole, 
    score_relative_to_par,
    fairway_hit,
    greens_in_regulation,
    putts_taken,
    scrambling_success,
    sand_save_success,
    up_and_down,
    holeNumber
}) => {
    useEffect(() => {
        if (par_for_the_hole && score_for_the_hole) {
            const newScoreRelativeToPar = score_for_the_hole - par_for_the_hole;

            // Only update if the value actually changed
            if (newScoreRelativeToPar !== score_relative_to_par) {

                handleChange({
                    target: {
                        name: 'score_relative_to_par',
                        value: newScoreRelativeToPar
                    }
                });
            }
        }
    }, [par_for_the_hole, score_for_the_hole, score_relative_to_par, handleChange]);
    return (
        <>
            <Grid item xs={12} md={4}>
                <QuantityInput
                    label={"Par for the Hole"}
                    onChange={handleChange}
                    value={par_for_the_hole}
                    name={"par_for_the_hole"}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <QuantityInput
                    label={"Score for the Hole"}
                    onChange={handleChange}
                    value={score_for_the_hole}
                    name={"score_for_the_hole"}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <AppInput
                label={"Score Relative to Par"}
                disabled
                height={40}
                onChange={handleChange}
                value={score_for_the_hole - par_for_the_hole}
                  name={"score_relative_to_par"}
              />
            </Grid>
            <Grid item xs={12} md={4}>
                <ZeroToOneInput
                    label={"Fairway Hit (FH)"}
                    onChange={handleChange}
                    value={fairway_hit}
                    name={"fairway_hit"}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <ZeroToOneInput
                    label={"Greens in Regulation (GIR)"}
                    onChange={handleChange}
                    value={greens_in_regulation}
                    name={"greens_in_regulation"}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <QuantityInput
                    label={"Putts Taken (PT)"}
                    onChange={handleChange}
                    value={putts_taken}
                    name={"putts_taken"}
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <ZeroToOneInput
                    label={"Scrambling Success (SC)"}
                    onChange={handleChange}
                    value={scrambling_success}
                    name={"scrambling_success"}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <ZeroToOneInput
                    label={"Sand Save Success (SS)"}
                    onChange={handleChange}
                    value={sand_save_success}
                    name={"sand_save_success"}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <ZeroToOneInput
                    label={"Up and Down (UD)"}
                    onChange={handleChange}
                    value={up_and_down}
                    name={"up_and_down"}
                />
            </Grid>
        </>
    );
};

export default GolfStatsInputs;


