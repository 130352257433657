import React, { useContext, useEffect, useRef, useState } from "react"
import {
  HomeHeader,
  AppButton,
  Loader,
  ReportUser,
  FollowUser,
  QRCode,
  Post,
  AddStats,
  VolleyBallStats,
  BaseBallStats
} from "../../components"
import {
  Avatar,
  Box,
  Divider,
  Grid,
  Popper,
  useMediaQuery,
  useTheme,
  FormControl,
  Select,
  InputLabel
} from "@mui/material"
import AvatarIcon from "../../assets/images/avatar.png"
import { ReactComponent as Tiktok } from "../../assets/svg/social/Tiktok.svg"
import { ReactComponent as Facebook } from "../../assets/svg/social/Facebook.svg"
import { ReactComponent as Insta } from "../../assets/svg/social/Insta.svg"
import { ReactComponent as Twitter } from "../../assets/svg/social/Twitter.svg"
import { ReactComponent as MessageFill } from "../../assets/svg/MessageFill.svg"
import { ReactComponent as StarFill } from "../../assets/svg/StarFill.svg"
import { ReactComponent as EditBlack } from "../../assets/svg/EditBlack.svg"
import { ReactComponent as FavWhite } from "../../assets/svg/FavWhite.svg"
import { ReactComponent as UnFavWhite } from "../../assets/svg/favorite.svg"
import { ReactComponent as ReportIcon } from "../../assets/svg/reportIcon.svg"
import { ReactComponent as QRcodeIcon } from "../../assets/svg/qrcode.svg"
import { ReactComponent as Youtube } from "../../assets/svg/youtube.svg"
import { ReactComponent as Threads } from "../../assets/svg/threads.svg"
import { useNavigate, useParams } from "react-router-dom"
import AppContext from "../../Context"
import { useSnackbar } from "notistack"
import { COLORS } from "../../constants"
import moment from "moment"
import {
  addFollow,
  addPlayerToFav,
  createChannel,
  getAllPlayerSeason,
  getAllPosts,
  getChannels,
  getListParent,
  getPlayerSeasons,
  getPlayerStats,
  getUserProfile,
  getUserProfileFollower,
  getUserProfileFollowing,
  removeFollow,
  removeFollower,
  removePlayerToFav,
  getListStatsBySportSeason
} from "../../api/auth"
import { getError } from "../../utils/getError"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import AddIcon from "@mui/icons-material/Add"
import InfoIcon from "@mui/icons-material/Info"
import MenuItem from "@mui/material/MenuItem"
import { checkSportIsBasketball, checkURL, outfielders, statsSummaryBaseball, statsSummaryBasketball, statsSummaryFootball, statsSummaryGolf, statsSummaryGymnastics, statsSummaryPickleball, statsSummarySoccer, statsSummaryVolleyball, volleyballStatsHead } from "../../utils/mixed"
import { getUsers } from "../../api/admin"
import BlockUser from "../../components/GeneralModal/BlockUser"
import FootBallStats from "../../components/Stats/FootBallStats"
import SoccerStats from "../../components/Stats/SoccerStats"
import PickleballStats from "../../components/Stats/PickleballStats"
import GolfStats from "../../components/Stats/GolfStats"
import GymnasticsStats from "../../components/Stats/GymnasticsStats"

const DEFAULT_SPORT_DATA = {
  id: null,
  sport: { id: null, name: "" },
  first_position_play: { name: "" },
  preferred_jersey_number: "",
  my_trainer: null
};

const styles = {
  profileSection: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  wLabel: {
    marginBottom: '8px'
  },
  wValue: {
    minHeight: '24px',
    fontWeight: 600
  }
};

export default function PlayerProfile({ }) {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const { id } = useParams()
  const { user, _getProfile, userProfile, sports, _getSports } = useContext(AppContext)
  const [anchorEl, setAnchorEl] = useState(null)
  const [season, setSeason] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSeasonSelect = season => {
    setSeason(season)
    setAnchorEl(null)
  }
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      })
    }, 100)
  }, [])

  const [state, setState] = useState({
    userProfileData: null,
    loadingData: false,
    loadingFav: false,
    loadingFollow: "",
    reportVisible: false,
    blockVisible: false,
    followVisible: false,
    showQRCode: false,
    loadingChat: false,
    loadingRemove: "",
    followVisibleType: "",
    allChannels: [],
    allUsers: [],
    userProfileFollower: [],
    userProfileFollowing: [],
    currentUserFollower: [],
    currentUserFollowing: [],
    posts: [],
    playerData: {},
    playerStats: [],
    playerSeasons: [],
    allPlayerSeason: [],
    page: 0,
    limit: 50,
    showComment: "",
    videoPlaying: "",
    statsVisible: false,
    playerId: "",
    allParents: [],
    statsSummary: null,
    selectedSport: DEFAULT_SPORT_DATA
  })

  const {
    userProfileData,
    loadingData,
    allChannels,
    allUsers,
    showQRCode,
    loadingFav,
    playerData,
    loadingFollow,
    reportVisible,
    blockVisible,
    followVisible,
    followVisibleType,
    userProfileFollower,
    userProfileFollowing,
    currentUserFollower,
    currentUserFollowing,
    loadingRemove,
    posts,
    page,
    limit,
    showComment,
    videoPlaying,
    playerStats,
    playerSeasons,
    allPlayerSeason,
    loadingChat,
    statsVisible,
    playerId,
    allParents,
    statsSummary,
    selectedSport
  } = state
  const token = localStorage.getItem("token")
  const [getUserLoader, setGetUserLoader] = useState(false)
  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }
  const handleCloseStats = getTeam => {
    handleChange("statsVisible", false)
    handleChange("teamID", "")
    if (getTeam) {
      // getData(0)
    }
  }

  const gotoLink = link => {
    let url = link
    if (!url.match(/^https?:\/\//i)) {
      url = "http://" + url
    }
    window.open(url, "_blank")
  }
  useEffect(() => {
    if (id) {
      _getSports()
      _getListParents()
      _getProfileData()
      _getChannels()
      _getAllSharedUsers()
    } else {
      _getProfile()
    }
  }, [id])

  const _getAllSharedUsers = async () => {
    setGetUserLoader(true)
    try {
      const res = await getUsers("?limit=20&offset=20/", token)
      if (res) {
        handleChange("allUsers", res?.data?.results)
        setGetUserLoader(false)
      }
    } catch (error) {
      setGetUserLoader(false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const _createChannel = async () => {
    try {
      handleChange("loadingChat", true)
      const payload = {
        users: [Number(id), user?.id]
      }
      const res = await createChannel(payload)
      handleChange("loadingChat", false)
      navigate(`/messages/${res?.data?.id}`)
    } catch (error) {
      handleChange("loadingChat", false)
      const errorText = Object.values(error?.response?.data)
      enqueueSnackbar(`Error: ${errorText[0]}`, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const getData = (resetPage, paged) => {
    const payload = `?user__id=${id}&limit=${limit}&offset=${resetPage ? 0 : paged
      }`
    _getAllPosts(payload, resetPage)
  }

  const _getAllPosts = async (payload, resetPage) => {
    try {
      // handleChange("posts", [])
      handleChange("loading", true)
      const queryParams = payload ? payload : ""
      const res = await getAllPosts(queryParams, token)

      handleChange("posts", res?.data?.results)
      // handleChange("filteredList", res?.data?.results)
      handleChange("count", res?.data?.count)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      getError(error)
    }
  }

  const _addPlayerToFav = async () => {
    try {
      handleChange("loadingFav", true)
      const token = localStorage.getItem("token")
      if (userData?.is_favorite) {
        await removePlayerToFav(`?user_id=${id}`, token)
      } else {
        await addPlayerToFav(`?user_id=${id}`, token)
      }
      _getProfileData(true)
      handleChange("loadingFav", false)
    } catch (error) {
      handleChange("loadingFav", false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const _removeFollower = async f_id => {
    try {
      handleChange("loadingRemove", f_id)
      const token = localStorage.getItem("token")
      if (user?.role === "Parent") {
        await removeFollower(
          `?user_id=${f_id}&player_id=${userData?.player?.id}`,
          token
        )
      } else {
        await removeFollower(`?user_id=${f_id}`, token)
      }
      _getProfileData(true)
      handleChange("loadingRemove", "")
    } catch (error) {
      handleChange("loadingRemove", "")
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const _addFollow = async (u_id, type) => {
    try {
      const f_id = u_id || id
      handleChange("loadingFollow", f_id)
      const token = localStorage.getItem("token")
      if (type === "unfollow") {
        if (user?.role === "Parent") {
          await removeFollow(
            `?user_id=${f_id}&player_id=${userData?.player?.id}`,
            token
          )
        } else {
          await removeFollow(`?user_id=${f_id}`, token)
        }
      } else {
        await addFollow(`?user_id=${f_id}`, token)
      }
      _getProfileData(true, u_id)
      if (!u_id) {
        handleChange("loadingFollow", "")
      }
    } catch (error) {
      handleChange("loadingFollow", "")
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }
  const _getChannels = async () => {
    try {
      handleChange("loading", true)
      const res = await getChannels()
      handleChange("allChannels", res?.data?.channels)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }
  const _getListParents = async () => {
    try {
      handleChange("loading", true)
      const token = localStorage.getItem("token")
      const payload = `?user_id=${id}`
      const res = await getListParent(payload, token)
      handleChange("allParents", res?.data)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }


  const _getListStatsBySportSeason = async (sport_id, seasonID = season, player_id = playerId) => {
    try {
      handleChange("loading", true);
      const token = localStorage.getItem("token");

      if (!sport_id || !player_id) {
        console.warn('Missing required parameters in _getListStatsBySportSeason:', { sport_id, player_id });
        handleChange("loading", false);
        handleChange("statsSummary", null);
        return;
      }

      const seasonNumber = String(seasonID || "").replace("Season ", "") || "";
      const sportId = Number(selectedSport?.sport?.id || sport_id);
      
      if (!sportId) {
        console.warn('Invalid sport ID in _getListStatsBySportSeason');
        handleChange("loading", false);
        handleChange("statsSummary", null);
        return;
      }

      const payload = `?sport_id=${sportId}&season=${seasonNumber}&player_id=${player_id}`;
      const res = await getListStatsBySportSeason(payload, token);
      
      if (res?.data) {
        handleChange("statsSummary", res.data);
      }
      handleChange("loading", false);
    } catch (error) {
      console.error('Error in _getListStatsBySportSeason:', error);
      handleChange("loading", false);
      
      // Clean stats
      if (error.response?.status === 403) {
        handleChange("statsSummary", null);
      }
      
      const errorText = error?.response?.data 
        ? typeof error.response.data === "string" 
          ? error.response.data 
          : Object.values(error.response.data).join(', ')
        : 'An error occurred while fetching stats';
      alert(`Error: ${errorText}`);
    }
  };

  const _getProfileData = async (noLoading, u_id) => {
    try {
      if (!noLoading && !u_id) {
        handleChange("loadingData", true);
      }
      
      const token = localStorage.getItem("token");
      if (!token || !id) {
        throw new Error('Missing required authentication or ID');
      }

      const res = await getUserProfile(id, token);

      if (!res?.data) {
        throw new Error('No data received from profile');
      }

      handleChange("userProfileData", res.data);
      handleChange("playerId", res.data?.player?.id);

      // Initialize with the first sport
      if (res.data?.player?.player_sports?.length > 0) {
        const initialSport = res.data.player.player_sports[0];
        handleChange("selectedSport", initialSport);
        
        const initialSeason = "Season 1";
        handleSeasonSelect(initialSeason);
        if (initialSport?.sport?.id && res.data?.player?.id) {
          await _getListStatsBySportSeason(initialSport.sport.id, "1", res.data.player.id);
        }
      }

      const payload = `?user_id=${id}`
      const currentUserFollower = await getUserProfileFollower("", token)
      const currentUserFollowing = await getUserProfileFollowing("", token)
      const resFollower = await getUserProfileFollower(payload, token)
      const resFollowing = await getUserProfileFollowing(payload, token)
      
      getData(true)
      handleChange("userProfileFollower", resFollower?.data)
      handleChange("userProfileFollowing", resFollowing?.data)
      handleChange("currentUserFollower", currentUserFollower?.data)
      handleChange("currentUserFollowing", currentUserFollowing?.data)
      handleChange("loadingData", false)
      
      if (u_id) {
        handleChange("loadingFollow", false)
      }
    } catch (error) {
      console.error('Error in _getProfileData:', error);
      handleChange("loadingData", false);
      const errorMessage = error?.response?.data 
        ? Object.values(error.response.data).join(', ')
        : error.message || 'An error occurred while loading profile data';
      alert(`Error: ${errorMessage}`);
    }
  }

  const handleCloseReport = () => {
    handleChange("reportVisible", false)
  }

  const handleCloseBlock = () => {
    handleChange("blockVisible", false)
  }

  const handleCloseFollow = () => {
    handleChange("followVisible", false)
    handleChange("followVisibleType", "")
  }
  const handleCloseQRCode = () => {
    handleChange("showQRCode", false)
  }

  const userData = id ? userProfileData : userProfile

  const handleOpenFollow = type => {
    // if (user?.id == id) {
    handleChange("followVisible", true)
    handleChange("followVisibleType", type)
    // }
  }

  const navigateUser = (user) => {
    if (user?.role === "Coach") {
      navigate(`/coach-profile/${selectedSport?.my_trainer?.id}`)
      return
    }
    if (user?.role === "Organization") {
      navigate(`/organization-profile/${selectedSport?.my_trainer?.id}`)
      return
    }
    // if (user?.role === "Parent") {
    //   navigate(`/organization-profile/${userData?.player?.my_trainer?.id}`)
    //   return
    // }
  }

  const pickedSeason = `${season}`

  const headers = () => {
    switch (selectedSport?.sport?.name) {
      case "Pickleball":
        return statsSummaryPickleball
      case "Gymnastics":
        return statsSummaryGymnastics
      case "Golf":
         return statsSummaryGolf
      case "Baseball":
        return statsSummaryBaseball
      case "Soccer":
        return statsSummarySoccer
      case "Basketball":
        return statsSummaryBasketball
      case "Volleyball":
        return statsSummaryVolleyball
      case "Football":
        return statsSummaryFootball
      default:
        return statsSummaryBasketball
    }
  }

  const getSportGridSize = () => {
    switch (selectedSport?.sport?.name) {
      case "Golf":
         return 5
      default:
        return 3
    }
  }

  const jarseyNumber = String(selectedSport?.preferred_jersey_number).length

  const oragnazationNumber = String(
    selectedSport?.organization_data?.organization_name
  ).length

  const isOwnProfile = id == user?.id

  useEffect(() => {
    if (userData?.player?.player_sports?.length > 0 && !selectedSport) {
      const initialSport = userData.player.player_sports[0];
      handleChange("selectedSport", initialSport);
      if (initialSport?.sport?.id && season && playerId) {
        _getListStatsBySportSeason(initialSport.sport.id, season, playerId);
      }
    }
  }, [userData, selectedSport]);

  const handleSportChange = (event) => {
    const newSport = userData?.player?.player_sports.find(
      sport => sport.id === event.target.value
    );
    handleChange("selectedSport", newSport);
  };

  useEffect(() => {
    if (selectedSport?.sport?.id && season && playerId) {
      _getListStatsBySportSeason(selectedSport.sport.id, season.replace("Season ", ""), playerId);
    }
  }, [selectedSport?.sport?.id]);

  return (
    <>
      <div className="PlayerProfile">
        <HomeHeader PlayerProfile dashboard />
        {loadingData ? (
          <Loader isWhite />
        ) : (
          <>
            <section className="container pb-3 mb-0 bg-transparent">
              <Grid container spacing={3}>
                <Grid item xs={12} md={7} order={{ xs: 2, md: 1 }}>
                  {user?.role === "Parent" &&
                    userData?.player?.is_confirmed_parent && (
                      <div>
                        <div
                          className="bg-transparent text-white font-14 d-flex width60 p-2"
                          style={{
                            gap: 10,
                            border: "1px solid #ffff",
                            borderRadius: "12px"
                          }}
                        >
                          <InfoIcon
                            className="text-white"
                            style={{ fontSize: 18 }}
                          />
                          <p style={{ lineHeight: 2 }}>
                            This player is linked to your account and your are
                            viewing this account as{" "}
                            <span className="font-bold text-center">
                              {user?.name}
                            </span>
                          </p>
                        </div>
                      </div>
                    )}
                  <div className="">
                    <p
                      className={
                        isSmallScreen
                          ? "nameProfile text-center"
                          : "nameProfile"
                      }
                    >
                      {userData?.first_name || userData?.last_name ? (
                        <>
                          {userData?.first_name} <br />
                          {userData?.last_name}
                        </>
                      ) : (
                        "No Name"
                      )}
                    </p>
                  </div>
                  <div
                    className={
                      isSmallScreen ? "w-label text-center" : "w-label"
                    }
                  >
                    {userData?.username
                      ? `@${userData?.username}`
                      : "@nousername"}
                  </div>
                  <div
                    className={
                      isSmallScreen ? "w-label text-center" : "w-label"
                    }
                  >
                    {userData?.player?.motivation?.context && (
                      <div>
                        <div className="w-label">
                          I'm motivated by
                        </div>
                        <div className="w-value">
                          {userData?.player?.motivation?.context}
                        </div>
                      </div>
                    )}
                  </div>
                  {user?.id != id && (
                    <AppButton
                      width={100}
                      backgroundColor={
                        userData?.is_favorite ? "#fff" : "transparent"
                      }
                      color={
                        userData?.is_favorite ? "#000" : COLORS.white
                      }
                      borderColor={COLORS.white}
                      className={"mt-3"}
                      height={35}
                      title={
                        <div
                          className="d-flex align-items-center"
                          style={{ marginLeft: -5 }}
                        >
                          {userData?.is_favorite ? (
                            <UnFavWhite style={{ marginTop: -3 }} />
                          ) : (
                            <FavWhite style={{ marginTop: -3 }} />
                          )}{" "}
                          <span
                            className={
                              userData?.is_favorite
                                ? "ml-1 text-black"
                                : "ml-1"
                            }
                          >
                            {userData?.is_favorite
                              ? "Unfavorite"
                              : "Favorite"}
                          </span>
                        </div>
                      }
                      fontWeight={"400"}
                      onClick={_addPlayerToFav}
                      loading={loadingFav}
                    />
                  )}
                  <Grid
                    container
                    display={"flex"}
                    flexWrap={"wrap"}
                    alignItems={"center"}
                    justifyContent={isSmallScreen ? "center" : "space-between"}
                    sx={{ mt: 5 }}
                  >
                    <div className="d-flex">
                      <div
                        className="mr-5 cursor"
                        onClick={() => handleOpenFollow("follower")}
                      >
                        <div className="w-label">Followers</div>
                        <div className="w-value">
                          {userData?.followers_count}
                        </div>
                      </div>
                      <div
                        className="cursor"
                        onClick={() => handleOpenFollow("following")}
                      >
                        <div className={"w-label text-center"}>Following</div>
                        <div className="w-value">
                          {userData?.following_count}
                        </div>
                      </div>
                    </div>
                    {user?.id == id && (
                      <Grid visibility={isSmallScreen ? "hidden" : ""}>
                        <AppButton
                          width={35}
                          height={35}
                          onClick={() => handleChange("showQRCode", true)}
                          borderRadius={35}
                          className={"min-width-remove"}
                          title={
                            <QRcodeIcon style={{ marginTop: -3 }} width={20} />
                          }
                        />
                      </Grid>
                    )}
                    {user?.id == id && (
                      <Grid visibility={isSmallScreen ? "" : "hidden"}>
                        <AppButton
                          width={35}
                          height={35}
                          onClick={() => handleChange("showQRCode", true)}
                          borderRadius={35}
                          className={"min-width-remove"}
                          title={
                            <QRcodeIcon style={{ marginTop: -3 }} width={20} />
                          }
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid container className="mt-2">
                    {user?.id != id && (
                      <Grid container item xs={12} md={6}>
                        {
                          user?.role == "Parent" &&
                            userData?.player?.is_confirmed_parent ? (
                            <AppButton
                              color={"#000"}
                              width={100}
                              height={35}
                              className={"mr-2"}
                              fontWeight={"400"}
                              title={
                                <div
                                  className="d-flex align-items-center"
                                  style={{ marginLeft: -5 }}
                                >
                                  <AddIcon style={{ marginTop: -3 }} />{" "}
                                  <span className="ml-1 font-13">
                                    Add Stats
                                  </span>
                                </div>
                              }
                              onClick={() => {
                                handleChange("statsVisible", true)
                                handleChange("playerData", {
                                  id: userData?.player?.id,
                                  name: userData?.player?.user?.name
                                })
                              }}
                            />
                          ) : (
                            <AppButton
                              color={"#000"}
                              width={100}
                              height={35}
                              className={"mr-2"}
                              fontWeight={"400"}
                              title={
                                <div
                                  className="d-flex align-items-center"
                                  style={{ marginLeft: -5 }}
                                >
                                  <StarFill
                                    style={{ marginTop: -3 }}
                                    width={20}
                                  />{" "}
                                  <span className="ml-1 font-13">
                                    {userData?.is_following
                                      ? "Unfollow"
                                      : "Follow"}
                                  </span>
                                </div>
                              }
                              onClick={() =>
                                _addFollow(
                                  userData?.id,
                                  userData?.is_following ? "unfollow" : "follow"
                                )
                              }
                              loading={!followVisible && loadingFollow}
                            />
                          )
                        }
                        <AppButton
                          width={100}
                          className={"mr-2"}
                          height={35}
                          loading={loadingChat}
                          onClick={() => {
                            // if (user?.role === "Parent") {
                            //   navigate("/messages")
                            // } else {
                            _createChannel()
                            // }
                          }}
                          title={
                            <div
                              className="d-flex align-items-center"
                              style={{ marginLeft: -5 }}
                            >
                              <MessageFill
                                style={{ marginTop: -3 }}
                                width={20}
                              />{" "}
                              <span className="ml-1 font-13">Message</span>
                            </div>
                          }
                          fontWeight={"400"}
                        />
                        {selectedSport?.my_highlights && (
                          <AppButton
                            width={100}
                            height={35}
                            onClick={() =>
                              window.open(
                                selectedSport?.my_highlights,
                                "_blank"
                              )
                            }
                            title={
                              <div
                                className="d-flex align-items-center"
                                style={{ marginLeft: -5 }}
                              >
                                <span className="ml-1 font-13">
                                  My Highlights
                                </span>
                              </div>
                            }
                            fontWeight={"400"}
                          />
                        )}
                      </Grid>
                    )}
                    <Grid
                      container
                      item
                      xs={12}
                      md={6}
                      justifyContent={
                        user?.id == id ? "flex-start" : "flex-end"
                      }
                    >
                      {userData?.player?.display_tiktok && (
                        <AppButton
                          width={35}
                          height={35}
                          onClick={() =>
                            gotoLink(userData?.player?.tiktok_username)
                          }
                          borderRadius={15}
                          className={"min-width-remove"}
                          title={
                            <Tiktok style={{ marginTop: -3 }} width={20} />
                          }
                        />
                      )}
                      {userData?.player?.display_facebook && (
                        <AppButton
                          width={35}
                          height={35}
                          onClick={() =>
                            gotoLink(userData?.player?.facebook_username)
                          }
                          className={"min-width-remove ml-2 mr-2"}
                          borderRadius={15}
                          title={
                            <Facebook style={{ marginTop: -3 }} width={20} />
                          }
                        />
                      )}
                      {userData?.player?.display_twitter && (
                        <AppButton
                          width={35}
                          height={35}
                          onClick={() =>
                            gotoLink(userData?.player?.twitter_username)
                          }
                          className={"min-width-remove mr-2"}
                          borderRadius={15}
                          title={
                            <Twitter style={{ marginTop: -3 }} width={20} />
                          }
                        />
                      )}
                      {userData?.player?.display_instagram && (
                        <AppButton
                          width={35}
                          height={35}
                          onClick={() =>
                            gotoLink(userData?.player?.instagram_username)
                          }
                          borderRadius={15}
                          className={"min-width-remove mr-2"}
                          title={<Insta style={{ marginTop: -3 }} width={20} />}
                        />
                      )}
                      {userData?.player?.display_youtube && (
                        <AppButton
                          width={35}
                          height={35}
                          onClick={() =>
                            gotoLink(userData?.player?.youtube_username)
                          }
                          borderRadius={15}
                          className={"min-width-remove mr-3"}
                          title={
                            <Youtube style={{ marginTop: -3 }} width={20} />
                          }
                        />
                      )}
                      {userData?.player?.display_threads && (
                        <AppButton
                          width={35}
                          height={35}
                          onClick={() =>
                            gotoLink(userData?.player?.threads_username)
                          }
                          borderRadius={15}
                          className={"min-width-remove"}
                          title={
                            <Threads style={{ marginTop: -3 }} width={20} />
                          }
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    spacing={12}
                    display="flex"
                    alignItems="center"
                    justifyContent={isSmallScreen ? "center" : "flex-start"}
                    sx={{ mt: 3 }}
                  >
                    <AppButton
                      width={80}
                      height={35}
                      onClick={() => navigate("/player-profile/edit")}
                      title={
                        <div style={{ marginLeft: -5 }}>
                          <EditBlack style={{ marginTop: -3 }} width={20} />{" "}
                          <span className="ml-1">Edit</span>
                        </div>
                      }
                      fontWeight={"400"}
                      className="mr-2"
                    />
                    <AppButton
                      width={150}
                      onClick={() => {
                        handleChange("statsVisible", true)
                      }}
                      height={35}
                      title={
                        <div>
                          <span>Add Stats</span>
                        </div>
                      }
                      fontWeight={"400"}
                    />
                    <FormControl 
                      sx={{ 
                        minWidth: 200, 
                        ml: 2,
                        '& .MuiOutlinedInput-root': {
                          height: '35px',
                          backgroundColor: 'white',
                          borderRadius: '4px',
                          '& fieldset': {
                            borderColor: '#D9D9D9',
                          },
                          '&:hover fieldset': {
                            borderColor: '#D9D9D9',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#1976d2',
                          },
                        },
                        '& .MuiInputLabel-root': {
                          color: 'rgba(0, 0, 0, 0.87)',
                          backgroundColor: 'white',
                          padding: '0 8px',
                          marginTop: '-8px',
                          left: '-12px',
                          '&.Mui-focused': {
                            color: '#1976d2',
                          },
                        },
                        '& .MuiSelect-select': {
                          color: 'black',
                          fontSize: '14px',
                          padding: '6px 14px',
                        },
                        '& .MuiMenuItem-root': {
                          fontSize: '14px',
                        }
                      }}
                    >
                      {selectedSport?.id && (
                        <Select
                          value={selectedSport?.id || ''}
                          onChange={handleSportChange}
                        >
                          {userData?.player?.player_sports?.map((sport) => (
                            <MenuItem key={sport.id} value={sport.id}>
                              {sport.sport.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </FormControl>
                  </Grid>
                  <div
                    className="playerData"
                    style={{
                      borderBottom: checkSportIsBasketball(
                        sports,
                        selectedSport?.sport?.id
                      )
                        ? "none"
                        : "",
                      borderRadius: checkSportIsBasketball(
                        sports,
                        selectedSport?.sport?.id
                      )
                        ? 0
                        : 5
                    }}
                  >
                    <Grid container spacing={2} sx={{ mb: 3 }}>
                      <Grid item xs={6} md={3}>
                        <div className="w-label">Height</div>
                        <div className="w-value">
                          {userData?.player?.height_ft}’{" "}
                          {userData?.player?.height_in}”
                        </div>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <div className="w-label">Weight</div>
                        <div className="w-value">
                          {userData?.player?.weight}lb
                        </div>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <div className="w-label">Graduation Year</div>
                        <div className="w-value">
                          {userData?.player?.date_of_graduation ? (
                            moment(userData?.player?.date_of_graduation).format(
                              "YYYY"
                            )
                          ) : (
                            <p className="text-left ml-3">N / A</p>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <div className="w-label">Grade</div>
                        <div className="w-value">
                          {userData?.player?.grade?.name ? (
                            userData?.player?.grade?.name
                          ) : (
                            <p className="text-left">N / A</p>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mt: 3 }}>
                      <Grid item xs={6} md={3}>
                        <div style={styles.profileSection}>
                          <div style={styles.wLabel}>Current School</div>
                          <div style={styles.wValue}>{userData?.player?.school?.name || "N / A"}</div>
                        </div>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <div style={styles.profileSection}>
                          <div style={styles.wLabel}>My Trainer / Development Coach</div>
                          <div style={styles.wValue}>{selectedSport?.my_trainer?.user?.name || "N / A"}</div>
                        </div>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <div style={styles.profileSection}>
                          <div style={styles.wLabel}>Linked Community</div>
                          <div style={styles.wValue}>
                            {allParents?.length > 0 ? 
                              allParents.map((parent, ind) => (
                                <span key={ind} className="cursor" onClick={() => navigate(`/parent-profile/${parent?.id}`)}>
                                  {parent?.name}{ind < allParents?.length - 1 ? ", " : ""}
                                </span>
                              )) : "N / A"
                            }
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <div style={styles.profileSection}>
                          <div style={styles.wLabel}>I'm motivated by</div>
                          <div style={styles.wValue}>
                            {userData?.player?.motivation?.context || userData?.player?.motivation || "N / A"}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid
                  container
                  direction={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  position={"relative"}
                  item
                  xs={12}
                  md={5}
                  order={{ xs: 1, md: 2 }}
                >
                  <img
                    src={userData?.player?.picture || AvatarIcon}
                    className={"playerDP"}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      top: 320,
                      left: 340
                      // width: "100%"
                    }}
                    className="Ball-Responsive"
                  >
                    {/* Jersey number div */}
                    <Box
                      sx={{
                        position: "absolute",
                        top: 120,
                        left: 25,
                        height: 75,
                        width: 75,

                        zIndex: 1,
                        backgroundColor: "transparent",
                        textAlign: "center",
                        borderRadius: "50%", // Make it circular
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                      // className="jerceyNumber"
                      className={`${jarseyNumber <= 3 ? "jerceyNumber" : "resJerceyNumber"}`}
                    >
                      {selectedSport?.preferred_jersey_number || 0}
                    </Box>

                    {/* Player's avatar */}
                    <Avatar
                      src={selectedSport?.sport?.image}
                      sx={{ width: 120, borderRadius: 2, height: 120 }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </section>
            {statsSummary && (
              <div className="statsDiv">
                <div className="container">
                  <Grid container>
                    {console.log("Rendering stats:", { statsSummary, headers: headers() })} {/* Debug log */}
                    <Grid
                      style={{
                        borderLeft: "1px solid #fff",
                        cursor: "pointer"
                      }}
                      md={1}
                      xs={3}
                      className="statsValueDiv"
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                    >
                      <div className="w-label">
                        Seasons <KeyboardArrowDownIcon />
                      </div>
                      <div className="w-value">
                        {pickedSeason?.replace("Season ", "")}
                      </div>
                    </Grid>
                    {statsSummary && headers()?.map((item, index) => {
                      if (item?.isCommon || 
                        (item?.type === "Fielder" && outfielders.includes(selectedSport?.first_position_play?.name)) || 
                        item?.type === selectedSport?.first_position_play?.name
                      ) {
                        return (
                          <Grid
                            key={`stat-${index}`}
                            md={1}
                            xs={3}
                            className="statsValueDiv"
                          >
                            <div className="w-label">{item?.key}</div>
                            <div className="w-value">
                              {statsSummary[item?.value] !== undefined 
                                ? item?.isPercentage 
                                  ? `${statsSummary[item?.value]}%`
                                  : statsSummary[item?.value]
                                : ""}
                            </div>
                          </Grid>
                        );
                      }
                      return null;
                    })}
                  </Grid>
                </div>
              </div>
            )}
            <section className="container bg-transparent pb-5">
              <Grid container justifyContent={"space-between"} gap={2}>
                <Grid container item xs={12} sm={8}>
                  {(selectedSport?.sport?.id !== 9 && selectedSport?.sport?.name !== 'Golf') ? (
                    <div className={`mr-${getSportGridSize()}`}>                      <>
                        <div className="w-label">Preferred Jersey Number</div>
                        <div className="w-value">
                          {selectedSport?.preferred_jersey_number}
                        </div>
                      </>
                  </div>
                      ) : null}
                    <div className={`mr-${getSportGridSize()}`}>
                      <div className="profile-section">
                      <div className="w-label">Current School</div>
                      <div className="w-value fw-bold">{userData?.player?.school?.name || "N / A"}</div>
                    </div>
                  </div>
                  <div className={`mr-${getSportGridSize()}`}>                    <div className="profile-section">
                      <div className="w-label">My Trainer / Development Coach</div>
                      <div className="w-value fw-bold">{selectedSport?.my_trainer?.user?.name || "N / A"}</div>
                    </div>
                  </div>
                  <div className={`mr-${getSportGridSize()}`}>
                                        <div className="profile-section">
                      <div className="w-label">Linked Community</div>
                      <div className="w-value fw-bold">
                        {allParents?.length > 0 ? 
                          allParents.map((parent, ind) => (
                            <span key={ind} className="cursor" onClick={() => navigate(`/parent-profile/${parent?.id}`)}>
                              {parent?.name}{ind < allParents?.length - 1 ? ", " : ""}
                            </span>
                          )) : "N / A"
                        }
                      </div>
                    </div>
                  </div>
              <div className={`mr-${getSportGridSize()}`}>
                    <div className="profile-section">
                      <div className="w-label">I'm motivated by</div>
                      <div className="w-value fw-bold">
                        {userData?.player?.motivation?.context || userData?.player?.motivation || "N / A"}
                      </div>
                    </div>
                  </div>
                </Grid>
                {user?.id != id && (
                  <Grid container item xs={3} md={3} justifyContent={"flex-end"}>
                    <AppButton
                      onClick={() => handleChange("reportVisible", true)}
                      title={
                        <div>
                          <ReportIcon
                            width={20}
                            height={15}
                            style={{ marginTop: -2, marginLeft: -5 }}
                          />{" "}
                          Report
                        </div>
                      }
                      width={120}
                      color={"#fff"}
                      height={40}
                      backgroundColor={"#0059C1"}
                    />
                    <AppButton
                      onClick={() => handleChange("blockVisible", true)}
                      title={
                        <div>
                          <ReportIcon
                            width={20}
                            height={15}
                            style={{ marginTop: -2, marginLeft: -5 }}
                          />{" "}
                          Block
                        </div>
                      }
                      width={120}
                      className={'ml-2'}
                      color={"#fff"}
                      height={40}
                      backgroundColor={"#0059C1"}
                    />
                  </Grid>
                )}
                {user?.id == id && (
                  <Grid
                    container
                    item
                    xs={12}
                    sm={2}
                    sx={{
                      justifyContent: { xs: "flex-start", sm: "flex-end" }
                    }}
                  >
                    <AppButton
                      color={"#000"}
                      width={120}
                      height={35}
                      className={"mr-2"}
                      fontWeight={"400"}
                      title={
                        <div
                          className="d-flex align-items-center"
                          style={{ marginLeft: -5 }}
                        >
                          {/* <AddIcon style={{ marginTop: -3 }} />{" "} */}
                          <span className="ml-1">View Full Stats</span>
                        </div>
                      }
                      onClick={() => {
                        navigate(`/player-stat/${id}`)
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </section>
          </>
        )}
        <ReportUser
          visible={reportVisible}
          handleClose={handleCloseReport}
          userId={id}
        />
        <BlockUser
          visible={blockVisible}
          handleClose={handleCloseBlock}
          userId={id}
        />
        <FollowUser
          visible={followVisible}
          _addFollow={_addFollow}
          loadingRemove={loadingRemove}
          _removeFollower={_removeFollower}
          loading={loadingFollow}
          userProfileFollower={userProfileFollower}
          userProfileFollowing={userProfileFollowing}
          currentUserFollower={currentUserFollower}
          currentUserFollowing={currentUserFollowing}
          followVisibleType={followVisibleType}
          handleClose={handleCloseFollow}
        />
        <QRCode visible={showQRCode} handleClose={handleCloseQRCode} />
      </div>
      <Grid container justifyContent={"center"} className="mt-0 mb-4">
        <Grid item xs={10} md={6}>
          <div className="text-black font-24 mt-4 font-500">Activities</div>
          <Divider className="mt-4" />
          {posts?.map((post, index) => (
            <Post
              key={index}
              state={state}
              showComment={showComment}
              handleChange={handleChange}
              post={post}
              getData={getData}
              getUserLoader={getUserLoader}
              videoPlaying={videoPlaying}
              allUsers={allUsers}
              allChannels={allChannels}
              index={index}
            />
          ))}
        </Grid>
      </Grid>
      <Popper
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
        disableScrollLock
        style={{ backgroundColor: "#fff" }}
      >
        {Object.keys(allPlayerSeason || {})?.map(v => {
          return (
            <MenuItem
              key={v + "-seasons"}
              onClick={() => handleSeasonSelect(v)}
              style={{ borderBottom: "1px solid lightgray" }}
            >
              {v}
            </MenuItem>
          )
        })}
      </Popper>
      {
        selectedSport?.sport?.name === "Pickleball" && playerId
          ?
          <PickleballStats
            visible={statsVisible}
            playerId={{ id: userData?.player?.id, userID: userData?.id, name: userData?.name }}
            handleClose={handleCloseStats}
          />
          :
          selectedSport?.sport?.name === "Golf" && playerId
            ?
            <GolfStats
              visible={statsVisible}
              playerId={{ id: userData?.player?.id, userID: userData?.id, name: userData?.name }}
              handleClose={handleCloseStats}
            />
            :
            selectedSport?.sport?.name === "Gymnastics" && playerId
              ?
              <GymnasticsStats
                visible={statsVisible}
                playerId={{ id: userData?.player?.id, userID: userData?.id, name: userData?.name }}
                handleClose={handleCloseStats}
              />
              :
              selectedSport?.sport?.name === "Soccer" && playerId
                ?
                <SoccerStats
                  visible={statsVisible}
                  playerId={{ id: userData?.player?.id, userID: userData?.id, name: userData?.name }}
                  handleClose={handleCloseStats}
                />
                :
                selectedSport?.sport?.name === "Baseball" && playerId
                  ?
                  <BaseBallStats
                    visible={statsVisible}
                    playerId={{ id: userData?.player?.id, userID: userData?.id, name: userData?.name }}
                    handleClose={handleCloseStats}
                  />
                  :
                  selectedSport?.sport?.name === "Volleyball" && playerId
                    ?
                    <VolleyBallStats
                      visible={statsVisible}
                      playerId={{ id: userData?.player?.id, userID: userData?.id, name: userData?.name }}
                      handleClose={handleCloseStats}
                    />
                    :
                    selectedSport?.sport?.name === "Football" && playerId ?
                      <FootBallStats
                        visible={statsVisible}
                        playerId={playerId}
                        handleClose={handleCloseStats}
                      /> :
                      <AddStats
                        visible={statsVisible}
                        playerId={playerData}
                        handleClose={handleCloseStats}
                      />
      }
    </>
  )
}
