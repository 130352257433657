





import React, { useContext, useEffect, useState } from "react"
import { Unstable_NumberInput as BaseNumberInput } from "@mui/base/Unstable_NumberInput"
import { styled } from "@mui/system"
import RemoveIcon from "@mui/icons-material/Remove"
import AddIcon from "@mui/icons-material/Add"
import { AppButton, AppInput, Label, VolleyBallStats } from ".."
import {
  Box,
  Grid,
  Modal,
  Popover,
  Typography,
  TextField,
  Autocomplete as TextAutocomplete,
  colors
} from "@mui/material"
import { COLORS } from "../../constants"
import { ReactComponent as Cross } from "../../assets/svg/Cross.svg"
import { ReactComponent as ExclamationCircle } from "../../assets/svg/ExclamationCircle.svg"
import {
  createOrganizationTeamStat,
  createPlayerTeamStat,
  getOrganizationTeam,
  updatePlayerTeamStat
} from "../../api/auth"
import { useSnackbar } from "notistack"
import { getError } from "../../utils/getError"
import AppContext from "../../Context"
import moment from "moment"
import { QuantityInput } from "../AppInput/NumberInput"

function AddStats({
  organization,
  visible,
  handleClose,
  teamID,
  playerId,
  playerStatOption,
  statData
}) {
  const { enqueueSnackbar } = useSnackbar()
  const [dropDownText, setDropDownText] = useState("")
  const [dateOfGameError, setdateOfGameError] = useState(false)
  const [teamPlayedError, setTeamPlayedError] = useState(false)
  const { organizationData, user } = useContext(AppContext)
  const token = localStorage.getItem("token")
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: organization ? 350 : "80%",
    borderRadius: 6,
    maxHeight: "90%",
    overflow: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4
  }
  const [anchorEl, setAnchorEl] = useState(null)
  const [state, setState] = useState({
    date_of_game: "",
    team_played: "",
    team_played_custom: "",
    result: "",
    points_scored: "",
    assists: 0,
    offensive_rebounds: 0,
    defensive_rebounds: 0,
    charges_taken: 0,
    forced_turnovers: 0,
    steals: 0,
    blocks: 0,
    field_goals_made: 0,
    field_goals_attempted: 0,
    three_point_field_goals_made: 0,
    three_point_field_goals_attempted: 0,
    free_throws_made: 0,
    free_throws_attempted: 0,
    turnovers: 0,
    fouls: 0,
    loading: false,
    teams: []
  })
  const {
    date_of_game,
    team_played,
    team_played_custom,
    result,
    points_scored,
    assists,
    offensive_rebounds,
    defensive_rebounds,
    forced_turnovers,
    charges_taken,
    steals,
    blocks,
    field_goals_made,
    three_point_field_goals_made,
    field_goals_attempted,
    three_point_field_goals_attempted,
    free_throws_made,
    free_throws_attempted,
    loading,
    teams,
    fouls,
    turnovers
  } = state

  const points_scored_calculated =
    Number(field_goals_made || 0) * 2 +
    Number(three_point_field_goals_made || 0) * 3 +
    Number(free_throws_made || 0) * 1

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  useEffect(() => {
    if(visible){
      getData()
    }
  }, [visible])

  useEffect(() => {
    if (statData && Object.keys(statData).length !== 0) {
      handleChange("date_of_game", statData?.date_of_game)
      handleChange("team_played_custom", statData?.team_played_custom)
      handleChange("points_scored_calculated", statData?.points_scored_calculated)
      handleChange("assists", statData?.assists)
      handleChange("offensive_rebounds", statData?.offensive_rebounds)
      handleChange("defensive_rebounds", statData?.defensive_rebounds)
      handleChange("steals", statData?.steals)
      handleChange("blocks", statData?.blocks)
      handleChange("field_goals_made", statData?.field_goals_made)
      handleChange("field_goals_attempted", statData?.field_goals_attempted)
      handleChange("three_point_field_goals_made", statData?.three_point_field_goals_made)
      handleChange("three_point_field_goals_attempted", statData?.three_point_field_goals_attempted)
      handleChange("free_throws_made", statData?.free_throws_made)
      handleChange("free_throws_attempted", statData?.free_throws_attempted)
      handleChange("charges_taken", statData?.charges_taken)
      handleChange("forced_turnovers", statData?.forced_turnovers)
      handleChange("turnovers", statData?.turnovers)
      handleChange("fouls", statData?.fouls)
    } else {
      handleChange("date_of_game", "")
      handleChange("team_played", "")
      handleChange("result", "")
      handleChange("team_played_custom", "")
      handleChange("assists", 0)
      handleChange("offensive_rebounds", 0)
      handleChange("defensive_rebounds", 0)
      handleChange("charges_taken", 0)
      handleChange("forced_turnovers", 0)
      handleChange("steals", 0)
      handleChange("blocks", 0)
      handleChange("points_scored", 0)
      handleChange("field_goals_attempted", 0)
      handleChange("field_goals_made", 0)
      handleChange("three_point_field_goals_made", 0)
      handleChange("three_point_field_goals_attempted", 0)
      handleChange("free_throws_made", 0)
      handleChange("turnovers", 0)
      handleChange("fouls", 0)
      setDropDownText("")
      handleChange("free_throws_attempted", 0)
    }
  }, [statData, visible])

  const getData = async () => {
    try {
      handleChange("loading", true)
      const res = await getOrganizationTeam(``, token)
      handleChange("teams", res?.data?.results)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClosePopup = () => {
    setAnchorEl(null)
  }

  const handleSubmit = async () => {
    try {
      if (date_of_game === "") {
        alert("Please enter date of game")
      }
      if (team_played === "" && team_played_custom === "") {
        alert("Please enter team played")
      } else {
        handleChange("loading", true)
        const assistsValue = assists || 0

        if (organization) {
          const payload = {
            date_of_game,
            team_played,
            result,
            organization: organizationData?.id,
            team: teamID
          }
          await createOrganizationTeamStat(payload, token)
        } else {
          const payload = {
            date_of_game,
            team_played,
            team_played_custom,
            points_scored: points_scored_calculated,
            assists: assistsValue,
            offensive_rebounds,
            defensive_rebounds,
            charges_taken,
            forced_turnovers,
            steals,
            blocks,
            field_goals_made,
            field_goals_attempted,
            three_point_field_goals_made,
            three_point_field_goals_attempted,
            free_throws_made,
            free_throws_attempted,
            turnovers,
            fouls
          }
          if (user?.role === "Parent") {
            payload.player_id = playerId?.id // Add player_id if user's role is parent
          }
          await createPlayerTeamStat(payload, token)
        }
        handleChange("loading", false)
        handleChange("date_of_game", "")
        handleChange("team_played", "")
        handleChange("result", "")
        handleChange("team_played_custom", "")
        handleChange("assists", 0)
        handleChange("offensive_rebounds", 0)
        handleChange("defensive_rebounds", 0)
        handleChange("charges_taken", 0)
        handleChange("forced_turnovers", 0)
        handleChange("steals", 0)
        handleChange("blocks", 0)
        handleChange("points_scored", 0)
        handleChange("field_goals_attempted", 0)
        handleChange("field_goals_made", 0)
        handleChange("three_point_field_goals_made", 0)
        handleChange("three_point_field_goals_attempted", 0)
        handleChange("free_throws_made", 0)
        handleChange("turnovers", 0)
        handleChange("fouls", 0)
        setDropDownText("")
        handleChange("free_throws_attempted", 0)
        handleClose(true)
        enqueueSnackbar(`Stats has been added`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          }
        })
      }
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const handleEdit = async () => {
    try {
      if (date_of_game === "") {
        alert("Please enter date of game")
      }
      if (team_played === "" && team_played_custom === "") {
        alert("Please enter team played")
      } else {
        handleChange("loading", true)
        const assistsValue = assists || 0

        if (organization) {
          const payload = {
            date_of_game,
            team_played,
            result,
            organization: organizationData?.id,
            team: teamID
          }
          await createOrganizationTeamStat(payload, token)
        } else {
          const payload = {
            date_of_game,
            team_played,
            team_played_custom,
            points_scored: points_scored_calculated,
            assists: assistsValue,
            offensive_rebounds,
            defensive_rebounds,
            charges_taken,
            forced_turnovers,
            steals,
            blocks,
            field_goals_made,
            field_goals_attempted,
            three_point_field_goals_made,
            three_point_field_goals_attempted,
            free_throws_made,
            free_throws_attempted,
            turnovers,
            fouls
          }
          if (user?.role === "Parent") {
            payload.player_id = playerId?.id // Add player_id if user's role is parent
          }
          await updatePlayerTeamStat(statData?.id, payload, token)
        }
        handleChange("loading", false)
        handleChange("date_of_game", "")
        handleChange("team_played", "")
        handleChange("result", "")
        handleChange("team_played_custom", "")
        handleChange("assists", 0)
        handleChange("offensive_rebounds", 0)
        handleChange("defensive_rebounds", 0)
        handleChange("charges_taken", 0)
        handleChange("forced_turnovers", 0)
        handleChange("steals", 0)
        handleChange("blocks", 0)
        handleChange("points_scored", 0)
        handleChange("field_goals_attempted", 0)
        handleChange("field_goals_made", 0)
        handleChange("three_point_field_goals_made", 0)
        handleChange("three_point_field_goals_attempted", 0)
        handleChange("free_throws_made", 0)
        handleChange("turnovers", 0)
        handleChange("fouls", 0)
        setDropDownText("")
        handleChange("free_throws_attempted", 0)
        handleClose(true)
        enqueueSnackbar(`Stats has been added`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          }
        })
      }
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  const disabled = organization
    ? !team_played || !date_of_game || !result
    : !date_of_game || !team_played

  const resultOptions = [
    <option value={""}>Select Result</option>,
    <option value={"Win"}>Win</option>,
    <option value={"Loss"}>Loss</option>
  ]
  
  return (
    <Modal
      open={visible}
      onClose={() => {
        setTeamPlayedError(true)
        setdateOfGameError(true)
        handleClose(false)
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ zIndex: 1000 }}
    >
      <Box sx={style} className="postBox">
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          className="mb-3"
        >
          <div className="font-24 font-bold greyColor">Add Stats</div>
          <AppButton
            width={35}
            height={35}
            borderRadius={35}
            className={"min-width-remove"}
            backgroundColor={COLORS.white}
            borderColor={"#D9D9D9"}
            title={<Cross />}
            onClick={() => handleClose(false)}
          />
        </Grid>
        {organization ? (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <AppInput
                max={moment().format("YYYY-MM-DD")}
                label={"Date of game"}
                type={"date"}
                height={40}
                onChange={handleChange}
                value={date_of_game}
                name={"date_of_game"}
              />
            </Grid>
            <Grid item xs={12}>
              <AppInput
                label={"Team Played"}
                height={40}
                onChange={handleChange}
                value={team_played}
                name={"team_played"}
              />
            </Grid>
            <Grid item xs={12}>
              <AppInput
                label={"Result"}
                select
                selectOptions={resultOptions}
                height={40}
                onChange={handleChange}
                value={result}
                name={"result"}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={3}>
            {user?.role == "Parent" && (
              <Grid item container xs={12} md={12} spacing={3}>
                <Grid item xs={12} md={4}>
                  <AppInput
                    label={"Adding stats for"}
                    height={40}
                    // select
                    onChange={handleChange}
                    value={playerId?.name}
                    name={"playerId"}
                  />
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} md={4}>
              <AppInput
                label={"Date of game"}
                max={moment().format("YYYY-MM-DD")}
                type={"date"}
                height={40}
                required={true}
                onChange={handleChange}
                value={date_of_game}
                name={"date_of_game"}
              />
              {dateOfGameError === true ? (
                <Label text={"required"} fontNormal color={COLORS.orange} />
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <Label text={"Team Played"} fontNormal />
              <TextAutocomplete
                freeSolo
                id="free-solo-2-demo"
                disableClearable
                value={
                  team_played_custom === "" ? dropDownText : team_played_custom
                }
                onChange={(event, newValue) => {
                  handleChange("team_played_custom", "")
                  handleChange(
                    "team_played",
                    teams[event?.target?.dataset?.optionIndex]?.id
                  )
                  setDropDownText(newValue)
                }}
                inputValue={
                  team_played_custom === "" ? dropDownText : team_played_custom
                }
                onInputChange={(event, newInputValue) => {
                  handleChange("team_played_custom", newInputValue)
                  handleChange("team_played", "")
                }}
                options={teams?.map(option => option.team_name)}
                // getOptionLabel={(option) => option.team_name}
                label={false}
                renderInput={params => (
                  <TextField
                    {...params}
                    size="small"
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#D9D9D9",
                        borderWidth: 1
                      },
                      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#D9D9D9"
                      }
                      // Add your desired z-index value here
                    }}
                    label=""
                    InputLabelProps={{ shrink: false }}
                    InputProps={{
                      ...params.InputProps,
                      type: "search"
                    }}
                  />
                )}
              />
              {teamPlayedError === true ? (
                <Label text={"required"} fontNormal color={COLORS.orange} />
              ) : (
                ""
              )}
              {/* <AppInput label={'Team Played'} height={40} onChange={handleChange} value={team_played} name={'team_played'} /> */}
            </Grid>
            <Grid item xs={12} md={4}>
              <AppInput
                label={"Points Scored"}
                disabled
                height={40}
                onChange={handleChange}
                value={points_scored_calculated}
                name={"points_scored"}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <QuantityInput
                label={"Assists"}
                onChange={handleChange}
                value={assists}
                name={"assists"}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <QuantityInput
                label={"Offensive Rebounds"}
                onChange={handleChange}
                value={offensive_rebounds}
                name={"offensive_rebounds"}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <QuantityInput
                label={"Defensive Rebounds"}
                onChange={handleChange}
                value={defensive_rebounds}
                name={"defensive_rebounds"}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <QuantityInput
                label={"Steals"}
                onChange={handleChange}
                value={steals}
                name={"steals"}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <QuantityInput
                label={"Blocks"}
                onChange={handleChange}
                value={blocks}
                name={"blocks"}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <QuantityInput
                label={"2P Field Goal Made"}
                onChange={handleChange}
                value={field_goals_made}
                name={"field_goals_made"}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <QuantityInput
                label={"2P Field Goal Misses"}
                onChange={handleChange}
                value={field_goals_attempted}
                name={"field_goals_attempted"}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <QuantityInput
                label={"3 Point Field Goals Made (3P FGM)"}
                onChange={handleChange}
                value={three_point_field_goals_made}
                name={"three_point_field_goals_made"}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <QuantityInput
                label={"3P Field Goal Misses"}
                onChange={handleChange}
                value={three_point_field_goals_attempted}
                name={"three_point_field_goals_attempted"}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <QuantityInput
                label={"Free Throws Made (FTM)"}
                onChange={handleChange}
                value={free_throws_made}
                name={"free_throws_made"}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <QuantityInput
                label={"Free Throw Misses"}
                onChange={handleChange}
                value={free_throws_attempted}
                name={"free_throws_attempted"}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <QuantityInput
                label={"Charges Taken"}
                onChange={handleChange}
                value={charges_taken}
                name={"charges_taken"}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <QuantityInput
                label={"Forced Turnovers "}
                onChange={handleChange}
                value={forced_turnovers}
                name={"forced_turnovers"}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <QuantityInput
                label={"Turnovers"}
                onChange={handleChange}
                value={turnovers}
                name={"turnovers"}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <QuantityInput
                label={"Fouls"}
                onChange={handleChange}
                value={fouls}
                name={"fouls"}
              />
            </Grid>
          </Grid>
        )}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePopup}
          classes={{ paper: "comfirm-paper" }}
          sx={{ width: 350 }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
        >
          <Grid className="p-4">
            <Grid container spacing={3}>
              <Grid item xs={2}>
                <ExclamationCircle className="mt-2" />
              </Grid>
              <Grid item xs={10}>
                <Typography id="modal-modal-description">
                  Are you sure you want to add this stats to your profile. This
                  cannot be changed later.
                </Typography>
              </Grid>
            </Grid>
            <Grid className="mt-3">
              <AppButton
                title={"Yes"}
                height={40}
                onClick={() => {
                  handleClosePopup()
                  handleClose(false)
                }}
                width={"100%"}
                backgroundColor={COLORS.primary}
                className={"mb-2"}
                color={COLORS.white}
              />
              <AppButton
                title={"Cancel"}
                height={40}
                onClick={handleClosePopup}
                width={"100%"}
                backgroundColor={"#fff"}
                className={"mb-2"}
                borderColor={"#D9D9D9"}
              />
            </Grid>
          </Grid>
        </Popover>
        <Grid container justifyContent={"flex-end"}>
          <AppButton
            title={"Save"}
            backgroundColor={COLORS.primary}
            color={COLORS.white}
            width={300}
            height={40}
            className={"mt-5"}
            loading={loading}
            // disabled={disabled}
            onClick={statData && Object.keys(statData).length !== 0 ? handleEdit : handleSubmit}
          />
        </Grid>
      </Box>
    </Modal>
  )
}

export default AddStats
