// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useEffect, useState } from "react"
import { AppButton, AppInput, HomeFooter, HomeHeader } from "../../components"
import { Grid } from "@mui/material"
import { useNavigate, useSearchParams } from "react-router-dom"
import { setForgotPassword } from "../../api/auth"
import AppContext from "../../Context"
import { useContext } from "react"
import eyeIcon from "../../assets/svg/eye.svg"
import eyeOff from "../../assets/svg/eyeOff.svg"
import { COLORS } from "../../constants"

export default function ResetPass({ }) {
  const navigate = useNavigate()
  const { user, setUser } = useContext(AppContext)
  const [searchParams] = useSearchParams()
  const token = searchParams.get("token")
  const email = searchParams.get("email")
  const [state, setState] = useState({
    password_1: "",
    password_2: "",
    loading: false,
    loadingSignup: false,
    isShow1: false,
    isShow2: false
  })

  const { loadingSignup, password_1, password_2, isShow1, isShow2 } = state

  const handleChange = (key, value) => {
    setState(pre => ({
      ...pre,
      [key]: value
    }))
  }

  useEffect(() => {
    if (!token) {
      navigate("/forgot-password")
    }
  }, [])

  const handleSignup = async () => {
    try {
      handleChange("loadingSignup", true)
      const payload = {
        "email": email?.replace(" ", "+"),
        "token": token,
        "password": password_1

      }
      const res = await setForgotPassword(payload)
      alert("Password has been reset")
      handleChange("loadingSignup", false)
      if (window.location.host === "app.thesportsportal.com") {
        window.location.href = "https://thesportsportal.com/"
      } else {
        navigate("/")
      }
    } catch (error) {
      handleChange("loadingSignup", false)
      const errorText = Object.values(error?.response?.data)
      if (errorText.length > 0) {
        alert(`Error: ${errorText[0]}`)
      } else {
        alert(`Error: ${error}`)
      }
    }
  }

  return (
    <div>
      <HomeHeader />
      <section>
        <div className="container divCenter loginContainer">
          <Grid container justifyContent={"center"}>
            <Grid item xs={10} sm={10} md={4} className="p-4">
              <p className="text_black font-500 font-18 mb-2">
                New password
              </p>
              <p className="lightgreyColor mb-3">
                Create a new password to login to your account
              </p>
              <AppInput
                className="mb-4 mt-3"
                value={password_1}
                name={"password_1"}
                onChange={handleChange}
                label={"Create Password"}
                type={isShow1 ? "text" : "password"}
                inputWidthFull
                postfix={
                  <img
                    src={!isShow1 ? eyeIcon : eyeOff}
                    onClick={() => handleChange("isShow1", !isShow1)}
                    width={20}
                    className={"c-pointer"}
                  />
                }
                placeholder={"********"}
              />
              {password_1 && password_1?.length < 8 && (
                <div className="validationmessage">{"Password must have at least 8 characters"}</div>
              )}
              <AppInput
                className="mt-3"
                value={password_2}
                name={"password_2"}
                onChange={handleChange}
                label={"Confirm Password"}
                type={isShow2 ? "text" : "password"}
                inputWidthFull
                postfix={
                  <img
                    src={!isShow2 ? eyeIcon : eyeOff}
                    onClick={() => handleChange("isShow2", !isShow2)}
                    width={20}
                    className={"c-pointer"}
                  />
                }
                placeholder={"********"}
              />
              {
                password_1 && password_2 && password_1 !== password_2 &&
                <div className="errorText">Password doesn't match</div>
              }
              <AppButton
                title={"Reset password"}
                onClick={handleSignup}
                className={'mt-4'}
                loading={loadingSignup}
                disabled={password_1 !== password_2 || password_1?.length < 8 || !password_1 || !password_2}
                backgroundColor={COLORS.primary}
                color={"#fff"}
              />
            </Grid>
          </Grid>
        </div>
      </section>
    </div>
  )
}
