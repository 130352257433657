import React, { useContext, useEffect, useState } from "react"
import { AppButton, AppInput, Label } from ".."
import {
  Box,
  Grid,
  Modal,
  Popover,
  Typography,
  TextField,
  Autocomplete as TextAutocomplete,
  Switch,
  FormControlLabel,
  Pagination
} from "@mui/material"
import { COLORS } from "../../constants"
import { ReactComponent as Cross } from "../../assets/svg/Cross.svg"
import { ReactComponent as ExclamationCircle } from "../../assets/svg/ExclamationCircle.svg"
import {
  createOrganizationTeamStat,
  getOrganizationTeam,
  getUserProfile,
  updateGolfStats,
  addGolfStats
} from "../../api/auth"
import { useSnackbar } from "notistack"
import { getError } from "../../utils/getError"
import AppContext from "../../Context"
import moment from "moment"
import GolfStatsInputs from './GolfStatsInputs';

function GolfStats({
  organization,
  visible,
  handleClose,
  teamID,
  playerId,
  playerStatOption,
  statData
}) {
  const { enqueueSnackbar } = useSnackbar()
  const [dropDownText, setDropDownText] = useState("")
  const [dateOfGameError, setdateOfGameError] = useState(false)
  const [teamPlayedError, setTeamPlayedError] = useState(false)
  const { organizationData, user } = useContext(AppContext)
  const token = localStorage.getItem("token")

  const generateArryHoles = (total) => {
       return Array(total).fill(0).map(() => ({
      par_for_the_hole: 1,
      score_for_the_hole: 1,
      fairway_hit: 0,
      greens_in_regulation: 0,
      putts_taken: 0,
      scrambling_success: 0,
      sand_save_success: 0,
      up_and_downs: 0,
      score: 0
    }))
  }


  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: organization ? 350 : "80%",
    borderRadius: 6,
    maxHeight: "90%",
    overflow: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4
  }
  const [anchorEl, setAnchorEl] = useState(null)
  const [state, setState] = useState({
    date_of_game: "",
    // team_played: "",
    course_played_custom: "",
    rounds: 0,
    average_score: 0,
    birdies: 0,
    loading: false,
    teams: [],
    userProfileData: null,
    holes: generateArryHoles(18)
  })
  const {
    date_of_game,
    // team_played,
    course_played_custom,
    loading,
    teams,
    userProfileData,
    holes
  } = state

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  useEffect(() => {
    if (visible) {
      getData()
      _getProfileData()
    }
  }, [visible])

  const _getProfileData = async () => {
    try {
      handleChange("loadingData", true)
      const token = localStorage.getItem("token")
      const res = await getUserProfile(user?.role === "Parent" ? playerId?.userID : user?.id, token)
      handleChange("userProfileData", res?.data)
      handleChange("loadingData", false)
    } catch (error) {
      handleChange("loadingData", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  useEffect(() => {
    if (statData && Object.keys(statData).length !== 0) {
      handleChange("date_of_game", statData?.date_of_game)
      // getData(`?`)
/*       if (teams?.length && teams?.some((e) => e?.id == statData?.team_played)) {
         setDropDownText(teams?.find((e) => e?.id == statData?.team_played)?.team_name)
       }*/
      //handleChange("team_played", statData?.team_played)
      handleChange("course_played_custom", statData?.course_played_custom)

      handleChange("holes", statData?.holes?.concat(generateArryHoles(18-statData?.holes?.length)))
      setTotalHoles(statData?.holes?.length)
      //handleChange("rounds", statData?.rounds)
      //handleChange("average_score", statData?.average_score)
      //handleChange("birdies", statData?.birdies)
      //handleChange("fairways_hit", statData?.fairways_hit)
      //handleChange("sand_saves", statData?.sand_saves)
      //handleChange("up_and_downs", statData?.up_and_downs)
    } else {
      handleReset()
    }
  }, [statData, visible, teams])

  const getData = async (payload = '') => {
    try {
      handleChange("loading", true)
      const res = await getOrganizationTeam(payload, token)
      handleChange("teams", res?.data?.results)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const handleReset = () => {
    handleChange("date_of_game", "")
    handleChange("course_played_custom", "")
    //handleChange("rounds", 0)
    //handleChange("average_score", 0)
    //handleChange("birdies", 0)
    //handleChange("fairways_hit", 0)
    //handleChange("sand_saves", 0)
    //handleChange("up_and_downs", 0)
    setDropDownText("")
  }
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClosePopup = () => {
    setAnchorEl(null)
  }

  const handleSubmit = async () => {
    try {
      if (date_of_game === "") {
        alert("Please enter date of game")
      }
      if (course_played_custom === "") {
        alert("Please enter Course played")
      } else {
        handleChange("loading", true)
        const payload = {
          date_of_game,
          course_played_custom,
          holes: holes.slice(0, totalHoles) // Only send data for active holes
        }
        if (user?.role === "Parent") {
          payload.player_id = playerId?.id // Add player_id if user's role is parent
        }
        await addGolfStats(payload, token)
        handleChange("loading", false)
        handleReset()
        handleClose(true)
        enqueueSnackbar(`Stats has been added`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          }
        })
      }
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const handleEdit = async () => {
    try {
      if (date_of_game === "") {
        alert("Please enter date of game")
      }
      if (course_played_custom === "") {
        alert("Please enter coursr played")
      } else {
        handleChange("loading", true)
        if (organization) {
          const payload = {
            date_of_game,
            course_played_custom,
            organization: organizationData?.id,
            team: teamID
          }
          await createOrganizationTeamStat(payload, token)
        } else {
          const payload = {
            date_of_game,
            course_played_custom,
            holes: holes.slice(0, totalHoles) // Only send data for active holes

          }
          if (user?.role === "Parent") {
            payload.player_id = playerId?.id // Add player_id if user's role is parent
          }
          await updateGolfStats(statData?.id, payload, token)
        }
        handleChange("loading", false)
        handleReset()
        handleClose(true)
        enqueueSnackbar(`Stats has been added`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          }
        })
      }
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined
  // const positionName = userProfileData?.player?.first_position_play?.name
  const [currentHole, setCurrentHole] = useState(1);
  const [totalHoles, setTotalHoles] = useState(18);
  const [holeCountError, setHoleCountError] = useState("");

  const handleHoleChange = (index, field, value) => {
    const updatedHoles = [...holes];
    updatedHoles[index] = {
      ...updatedHoles[index],
      [field]: value
    };
    setState(prev => ({ ...prev, holes: updatedHoles }));
  }
  
  const handleHoleCountChange = (value) => {
    // Allow empty input during typing/deletion
    if (value === "" || value === null) {
      setHoleCountError("Please enter a valid number");
      setTotalHoles("");  // Still update the field to show empty
      return;
    }
    
    // Validate input is a number
    if (isNaN(value)) {
      setHoleCountError("Please enter a valid number");
      return;
    }
    
    const numValue = Number(value);
    if (numValue < 1 || numValue > 18) {
      setHoleCountError("Holes must be between 1 and 18");
      setTotalHoles(value); // Still update the field to show what user typed
      return;
    }
    
    // Valid input
    setHoleCountError("");
    setTotalHoles(numValue);
    if (currentHole > numValue) setCurrentHole(numValue);
  }

  return (
    <Modal
      open={visible}
      onClose={() => {
        setTeamPlayedError(true)
        setdateOfGameError(true)
        handleClose(false)
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ zIndex: 1000 }}
    >
      <Box sx={style} className="postBox">
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          className="mb-3"
        >
          <div className="font-24 font-bold greyColor">Add Stats</div>
          <AppButton
            width={35}
            height={35}
            borderRadius={35}
            className={"min-width-remove"}
            backgroundColor={COLORS.white}
            borderColor={"#D9D9D9"}
            title={<Cross />}
            onClick={() => handleClose(false)}
          />
        </Grid>

        <Grid container spacing={3}>
          {user?.role == "Parent" && (
            <Grid item container xs={12} md={12} spacing={3}>
              <Grid item xs={12} md={4}>
                <AppInput
                  label={"Adding stats for"}
                  height={40}
                  // select
                  onChange={handleChange}
                  value={playerId?.name}
                  name={"playerId"}
                />
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <AppInput
              label={"Date of game"}
              max={moment().format("YYYY-MM-DD")}
              type={"date"}
              height={40}
              required={true}
              onChange={handleChange}
              value={date_of_game}
              name={"date_of_game"}
            />
            {dateOfGameError === true ? (
              <Label text={"required"} fontNormal color={COLORS.orange} />
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <AppInput
                  label={"Course Played"}
                  height={40}
                  // select
                  onChange={handleChange}
                  value={course_played_custom}
                  name={"course_played_custom"}
                />

            {teamPlayedError === true ? (
              <Label text={"required"} fontNormal color={COLORS.orange} />
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12} md={12}>
            <Label text={"Number of Holes"} fontNormal />
            <AppInput
              type="number"
              min={1}
              max={18}
              height={40}
              value={totalHoles}
              onChange={(key, value) => handleHoleCountChange(value)}
              name="totalHoles"
            />
            {holeCountError && (
              <Label text={holeCountError} fontNormal color={COLORS.orange} />
            )}
          </Grid>

          <GolfStatsInputs
            handleChange={(field, value) => handleHoleChange(currentHole - 1, field, value)}
            par_for_the_hole={holes && holes[currentHole - 1] ? holes[currentHole - 1].par_for_the_hole || 0 : 0}
            score_for_the_hole={holes && holes[currentHole - 1] ? holes[currentHole - 1].score_for_the_hole || 0 : 0}
            score_relative_to_par={holes && holes[currentHole - 1] ? holes[currentHole - 1].score_relative_to_par || 0 : 0}
            fairway_hit={holes && holes[currentHole - 1] ? holes[currentHole - 1].fairway_hit || 0 : 0}
            greens_in_regulation={holes && holes[currentHole - 1] ? holes[currentHole - 1].greens_in_regulation || 0 : 0}
            putts_taken={holes && holes[currentHole - 1] ? holes[currentHole - 1].putts_taken || 0 : 0}
            scrambling_success={holes && holes[currentHole - 1] ? holes[currentHole - 1].scrambling_success || 0 : 0}
            sand_save_success={holes && holes[currentHole - 1] ? holes[currentHole - 1].sand_save_success || 0 : 0}
            up_and_down={holes && holes[currentHole - 1] ? holes[currentHole - 1].up_and_down || 0 : 0}
            holeNumber={currentHole}
          />


          <Grid item xs={12} md={12} container justifyContent="center" alignItems="center">
            <Typography variant="h6" sx={{ mr: 2 }}>
              Hole {currentHole} of {totalHoles}
            </Typography>
            <Pagination
              count={totalHoles}
              page={currentHole}
              onChange={(e, page) => setCurrentHole(page)}
              color="primary"
              size="large"
            />
          </Grid>
{/*           <Grid item xs={12} md={12}>
            <Typography variant="h6" sx={{ mb: 2 }}>Overall Statistics</Typography>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Label text={"Total Fairways Hit"} fontNormal />
                <Typography>{holes.slice(0, totalHoles).filter(h => h.fairways_hit).length}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Label text={"Total Sand Saves"} fontNormal />
                <Typography>{holes.slice(0, totalHoles).filter(h => h.sand_saves).length}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Label text={"Total Up and Downs"} fontNormal />
                <Typography>{holes.slice(0, totalHoles).filter(h => h.up_and_downs).length}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Label text={"Total Score"} fontNormal />
                <Typography>
                  {holes.slice(0, totalHoles).reduce((sum, hole) => sum + (hole.score || 0), 0)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>*/}
        </Grid>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePopup}
          classes={{ paper: "comfirm-paper" }}
          sx={{ width: 350 }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
        >
          <Grid className="p-4">
            <Grid container spacing={3}>
              <Grid item xs={2}>
                <ExclamationCircle className="mt-2" />
              </Grid>
              <Grid item xs={10}>
                <Typography id="modal-modal-description">
                  Are you sure you want to add this stats to your profile. This
                  cannot be changed later.
                </Typography>
              </Grid>
            </Grid>
            <Grid className="mt-3">
              <AppButton
                title={"Yes"}
                height={40}
                onClick={() => {
                  handleClosePopup()
                  handleClose(false)
                }}
                width={"100%"}
                backgroundColor={COLORS.primary}
                className={"mb-2"}
                color={COLORS.white}
              />
              <AppButton
                title={"Cancel"}
                height={40}
                onClick={handleClosePopup}
                width={"100%"}
                backgroundColor={"#fff"}
                className={"mb-2"}
                borderColor={"#D9D9D9"}
              />
            </Grid>
          </Grid>
        </Popover>
        <Grid container justifyContent={"flex-end"}>
          <AppButton
            title={"Save"}
            backgroundColor={COLORS.primary}
            color={COLORS.white}
            width={300}
            height={40}
            className={"mt-5"}
            loading={loading}
            // disabled={disabled}
            onClick={statData && Object.keys(statData).length !== 0 ? handleEdit : handleSubmit}
          />
        </Grid>
      </Box>
    </Modal>
  )
}

export default GolfStats
