import * as React from "react"
import { Container } from "@mui/material"
import { AdminLayout, Loader, UserTable } from "../../components"
import { useContext } from "react"
import AppContext from "../../Context"
import { useState } from "react"
import { banUser, deleteUser, getAllUsers, unBanUser } from "../../api/admin"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { getError } from "../../utils/getError"
import { useSnackbar } from "notistack"
import { ReactComponent as SearchGrey } from "../../assets/svg/searchGrey.svg"

function AdminUsersContent() {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const {} = useContext(AppContext)
  const [state, setState] = useState({
    loading: false,
    limit: 10,
    page: 0,
    count: 0,
    users: [],
    loadingDelete: false
  })
  const [filteredList, setFilteredList] = useState()
  const { users, limit, page, count, loading, loadingDelete } = state
  const token = localStorage.getItem("admintoken")

  const handleChange = (name, value) => {
    setState(pre => ({ ...pre, [name]: value }))
  }

  useEffect(() => {
    if (!token) {
      navigate("/webadmin/login")
      return
    }
    getData(true)
  }, [])

  const getData = (resetPage, paged) => {
    const payload = `?limit=${limit}&offset=${resetPage ? 0 : paged}`
    _getAllUsers(payload, resetPage)
  }

  const clickNext = newPage => {
    getData(false, newPage * 10)
    handleChange("page", newPage)
  }

  const _getAllUsers = async (payload, resetPage) => {
    try {
      handleChange("users", [])
      handleChange("loading", true)
      const res = await getAllUsers(payload, token)
      handleChange("users", res?.data?.results)
      handleChange("count", res?.data?.count)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const _deleteUser = async (id, handleCloseDelete) => {
    try {
      handleChange("loadingDelete", true)
      await deleteUser(id, token)
      getData(false, page * 10)
      handleChange("loadingDelete", false)
      handleCloseDelete()
      enqueueSnackbar("User is Deleted", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    } catch (error) {
      handleChange("loadingDelete", false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }
  const _banUser = async (id, handleCloseBan) => {
    try {
      handleChange("loadingDelete", true)
      await banUser(id, token)
      getData(false, page * 10)
      handleChange("loadingDelete", false)
      enqueueSnackbar("User has been ban successfully", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    } catch (error) {
      handleChange("loadingDelete", false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }
  const _unBanUser = async id => {
    try {
      handleChange("loadingDelete", true)
      await unBanUser(id, token)
      getData(false, page * 10)
      handleChange("loadingDelete", false)
      enqueueSnackbar("User has been Unban successfully", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    } catch (error) {
      handleChange("loadingDelete", false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }
  const filtered = value => {
    if (value) {
      const filtered = users?.filter(
        e =>
          e?.name?.toLowerCase()?.includes(value?.toLowerCase()) ||
          e?.username?.toLowerCase()?.includes(value?.toLowerCase())
      )
      setFilteredList(filtered)
    } else {
      setFilteredList(null)
    }
  }
  return (
    <div>
      <AdminLayout>
        {loading ? (
          <Loader />
        ) : (
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <h5 className="mb-4">All User Lists</h5>
            <div className="searchInputHeaderBox mb-3">
              <input
                className="searchInput"
                onChange={e => filtered(e.target.value)}
                placeholder="Search..."
              />
              <div className="saerchIconHeaderDiv">
                <SearchGrey />
              </div>
            </div>
            <UserTable
              users={filteredList || users}
              count={count}
              page={page}
              loadingDelete={loadingDelete}
              clickNext={clickNext}
              _deleteUser={_deleteUser}
              _banUser={_banUser}
              _unBanUser={_unBanUser}
              loading={loading}
            />
          </Container>
        )}
      </AdminLayout>
    </div>
  )
}

export default function AdminUsers() {
  return <AdminUsersContent />
}
