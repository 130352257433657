const BASE_URL = process.env.REACT_APP_BASE_URL
const STAGING_BASE_URL = process.env.REACT_APP_STAGING_BASE_URL

const getApiHost = () => {
  const hostname = window.location.hostname;

  if (hostname === 'app.thesportsportal.com') {
    return 'https://app.thesportsportal.com'; // Production
  }

  if (hostname === 'amateur-sports-insi-42-staging.botics.co') {
    return 'https://amateur-sports-insi-42-staging.botics.co'; // Staging
  }

  if (hostname === 'localhost') {
    return 'http://localhost:8000'; // Local
  }

  return 'https://app.thesportsportal.com'; // Default fallback (prod)
};

export const defaultConfig = {
  API_URL: getApiHost()
}

export const App = {
  config: defaultConfig
}

window.env = window.env || defaultConfig
App.config = { ...window.env }

export const API_URL = () => App.config.API_URL