import * as React from "react"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"

export const MAIN = "/"
export const CONTACTUS = "/contact-us"
export const ABOUTUS = "/about-us"
export const TERMSCONDITIONS = "/terms"
export const PRIVACYPOLICY = "/privacy"
export const RVDETAILS = "/rv/:id"
export const ADMINLOGIN = "/webadmin/login"
export const ADMINUSERS = "/webadmin/users"
export const ADMINPLAYERPROFILE = "/webadmin/users/player/:id"
export const ADMINPARENTPROFILE = "/webadmin/users/parent/:id"
export const ADMINORGANIZATIONPROFILE = "/webadmin/users/organization/:id"
export const LOGIN = "/login"
export const SIGNUP = "/signup"
export const OTPAUTH = "/2fa-verfition"
export const LOCATIONACCESS = "/location-access"
export const FORGOTPASSWORD = "/forgot-password"
export const CHANGEPASSWORD = "/settings/change-password"
export const RESETPASSWORD = "/new-password"
export const DASHBOARD = "/dashboard"
export const OTHERPROFILE = "/profile/:id"
export const PLAYERPROFILE = "/player-profile/:id"
export const PLAYERPROFILEFORM = "/player-profile/edit"
export const PLAYERPROFILEFORMADD = "/player-profile/add"
export const TEAMS = "/teams"
export const GAMESCHEDULE = "/game-schedule/:id"
export const ADDTEAM = "/teams/add"
export const EDITTEAM = "/teams/edit/:id"
export const DETAILPOST = "/post/:id"
export const APPCALENDAR = "/calendar"
export const BOOKMARK = "/favorites"
export const PARENTPROFILE = "/parent-profile/:id"
export const COACHPROFILE = "/coach-profile/:id"
export const COACHPROFILEFORM = "/coach-profile/:id/edit"
export const COACHPROFILEFORMADD = "/coach-profile/:id/add"
export const PARENTPROFILEFORM = "/parent-profile/:id/edit"
export const PARENTPROFILEFORMADD = "/parent-profile/:id/add"
export const ORGANIZATIONPROFILE = "/organization-profile/:id"
export const ORGANIZATIONPROFILEFORM = "/organization-profile/:id/edit"
export const ORGANIZATIONPROFILEFORMADD = "/organization-profile/:id/add"
export const SEARCH = "/search"
export const SETTINGS = "/settings"
export const ROSTER = "/roster/:team/:organization"
export const ADDPLAYER = "/add-player/:id"
export const PLAYERSTATS = "/player-stat/:id"
export const ADDPOTENTIALPLAYER = "/add-player/:type"
export const POTENTIALPLAYERS = "/potential-players/:organization/:user"
export const INVITATIONACCEPT = "/accept-invitation/:id"
export const INVITATIONREJECT = "/reject-invitation/:id"
export const NOTIFICATIONS = "/notifications"
export const LINKED_PLAYERS = "/linked-player/:id"
export const MESSAGES = "/messages"
export const CHAT_MESSAGE = "/messages/:id"
export const LINK_ACTION = "/:actionType"
export const LINKING_REQUEST = "/linking-request"
export const ADMINFEEDBACKUSERS = "/webadmin/feedbacks"
export const ADMINREPORTEDUSERS = "/webadmin/reporteduser"

export const SIDEBAR = (
  <React.Fragment>
    <ListItemButton>
      <ListItemText primary="Dashboard" />
    </ListItemButton>
    <ListItemButton>
      <ListItemText primary="Users" />
    </ListItemButton>
    <ListItemButton>
      <ListItemText primary="Zip Codes" />
    </ListItemButton>
    <ListItemButton>
      <ListItemText primary="Requests" />
    </ListItemButton>
    <ListItemButton>
      <ListItemText primary="Feedback" />
    </ListItemButton>
  </React.Fragment>
)
